import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import EmployeeAttendance from "./components/EmployeeAttendance";
import DeviceList from "./components/DeviceList";
import PresentStatus from "./components/PresentStatus";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import { IconButton, styled } from "@mui/material";
import AttendanceRecord from "./components/AttendanceRecord";
import AttendanceStatus from "./components/AttendanceStatus";
import PieChart from "./components/PieChart";
import PieChartBottom from "./components/PieChartBottom";
import ActionButton from "@/components/actions/ActionButton";
import { TitleTypography } from "./components/commonCmp";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "@/store/slices/Breadcrumbs";

const CustomGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: "#ffffff",
}));

const Item = styled(Grid)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#ffffff",
  height: "100%",
  borderRadius: "10px"
}));

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rdyShow, setRdyShow] = React.useState(false);
  const [pageSize, setPageSize] = useState<number>(25);
  const [page, setPage] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(0);
  const [refreshChart, setRefreshChart] = useState<number>(0);

  const refreshChartData = () => {
    setRefreshChart(refreshChart + 1);
  };

  useEffect(() => {
    dispatch<any>(
      setBreadcrumbs<any>([{
        path: '',
        label: t('common.Dashboard')
      }])
    );
  }, []);

  return (
    <Grid>
      <Grid
        sx={{
          display: "flex",
          flexWrap: "wrap",
          paddingRight: "14px",
          // height: "calc(50vh - 40px)",
          minHeight: "440px",
        }}
      >
        <Grid
          sx={{
            flexGrow: "1",
            minWidth: "500px",
            width: "65%",
            padding: "6px 6px 6px 0px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffffff",
            backgroundClip: "content-box",
            minHeight: "440px",
            borderRadius: "20px !important"
          }}
        >
          <Grid
            sx={{
              height: "40px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 8px",
              marginLeft: "5px",
            }}
          >
            <TitleTypography>{t("common.Attendance")}</TitleTypography>

            <Tooltip title={t("common.refresh")}>
              <div>
                <ActionButton
                  icon="icon-ic_refresh"
                  handleClick={refreshChartData}
                ></ActionButton>
              </div>
            </Tooltip>
          </Grid>
          <Grid
            sx={{
              flexGrow: "1",
            }}
          >
            <Item
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
              }}
            >
              <PieChart
                refreshChart={refreshChart}
                category={"day"}
                title={t("common.Today")}
              ></PieChart>
              <PieChart
                refreshChart={refreshChart}
                category={"week"}
                title={t("common.This Week")}
              ></PieChart>
              <PieChart
                refreshChart={refreshChart}
                category={"month"}
                title={t("common.This Month")}
              ></PieChart>
            </Item>
          </Grid>
          <Grid
            sx={{
              height: "60px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PieChartBottom></PieChartBottom>
          </Grid>
        </Grid>
        <Grid
          sx={{
            minWidth: "300px",
            flexGrow: "1",
            width: "30%",
            padding: "6px",
            boxSizing: "border-box",
            minHeight: "440px",
            height: "calc(50vh - 20px)",
          }}
        >
          <Item>
            <DeviceList />
          </Item>
        </Grid>
      </Grid>

      <Grid
        sx={{
          display: "flex",
          flexWrap: "wrap",
          paddingRight: "14px",
          height: "calc(50vh - 20px)",
          minHeight: "440px",
          marginBottom: '14px'
        }}
      >
        <Grid
          sx={{
            flexGrow: "1",
            minWidth: "400px",
            width: "50%",
            padding: "6px 6px 6px 0px",
            boxSizing: "border-box",
            minHeight: "440px",
          }}
        >
          <Item>
            <AttendanceRecord
              pageSize={pageSize}
              setPageSize={setPageSize}
              page={page}
              setPage={setPage}
              refresh={refresh}
              setRefresh={setRefresh}
            ></AttendanceRecord>
          </Item>
        </Grid>
        <Grid
          sx={{
            minWidth: "400px",
            flexGrow: "1",
            width: "50%",
            padding: "6px",
            boxSizing: "border-box",
            minHeight: "440px",
            height: "calc(50vh - 20px)",
          }}
        >
          <Item>
            <AttendanceStatus
              pageSize={pageSize}
              setPageSize={setPageSize}
              page={page}
              setPage={setPage}
              refresh={refresh}
              setRefresh={setRefresh}
            ></AttendanceStatus>
          </Item>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Dashboard;
