import React, { useContext, useRef, useState } from "react";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import CustomDataGrid, { DataGridRef } from "@/components/data-grid/CustomDataGrid";
import { BaseEndpoints } from "@/services/base/endpoints";
import { useNavigate } from "react-router-dom";
import WebURL from "@/urls";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";


const BeatTask: React.FC = () => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: 'id', headerName: "id", minWidth: 60},
    { field: 'display_name', headerName: "display_name", minWidth: 450 },
    { field: 'enabled', headerName: "enabled", minWidth: 120},
    { field: 'display_crontab', headerName: "display_crontab", minWidth: 300},
    { field: 'last_run_at', headerName: "last_run_at", minWidth: 180},
    { field: 'total_run_count', headerName: "total_run_count", minWidth: 120},
  ]
  return (
    <>
      {/* <CustomBreadcrumbs record={[
        { label: 'BeatTask' },
      ]} /> */}
      <CustomDataGrid
        uri={BaseEndpoints.BEAT_URI}
        ref={dataGridRef}
        columns={columns}
        checkboxSelection={false}
      />
    </>
  )
}
export default BeatTask
