import React, {ForwardedRef} from "react";
import {FormControl, TextField, TextFieldProps} from "@mui/material";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";

type TextFieldTooltipProps = TextFieldProps & {
  tooltip?:string
}

const NGTextField = (props: TextFieldTooltipProps, ref: ForwardedRef<any>) => {
  const {label, ...other} = props;
  return (
    <FormControl fullWidth={props.fullWidth}>
      <NGInputLabel label={label} required={props.required} tooltip={props.tooltip}/>
      <TextField
        ref={ref}
        margin={"normal"}
        variant={"outlined"}
        sx={{marginTop: '24px'}}
        {...other}
      />
    </FormControl>
  )
}
export default React.forwardRef(NGTextField)
