import {GridColDef} from "@mui/x-data-grid";
import {
  Box,
  Grid,
  Breadcrumbs,
  Typography,
  Badge,
  IconButton,
  Tooltip,
} from "@mui/material";
import {Refresh} from "@mui/icons-material";
import TablePagination from '@mui/material/TablePagination';
import {ATTEndpoints} from "../../../services/att/endpoints";
import React, {useEffect, useState, Dispatch, SetStateAction} from "react";
import {DataGrid, DataGridProps} from "@mui/x-data-grid";
import GenericService from "@/services/GenericService";
import {useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import CircularProgress from "@mui/material/CircularProgress";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import ActionButton from "@/components/actions/ActionButton";
import {TitleTypography} from './commonCmp'

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

type GridData = {
  data: any;
  total: number;
  num_pages: number;
};
type FilterType = {
  date_range: [any, any];
};
const customDataGridStyle = {
  "paddingRight": '24px',
  "paddingBottom": '24px',
  ".MuiTablePagination-spacer": {display: "none"},
  ".MuiTablePagination-actions": {
    position: "absolute",
    right: "0px",
    backgroundColor: "#FFF",
    '& .MuiIconButton-root': {
      padding: '4px',
      margin: '0px 4px',
      border: '1px solid #DEE0E3',
      'border-radius': '6px',
    }
  },
  ".MuiTablePagination-displayedRows": {position: "absolute", right: "110px"},
  ".MuiTablePagination-select": {
    backgroundColor: "#F0F1F4",
    borderRadius: "4px!important",
  },
  ".MuiTablePagination-toolbar": {paddingLeft: "0px"},
  ".MuiIconButton-root.Mui-disabled": {
    backgroundColor: "#F0F1F4",
    borderRadius: "0px",
  },
  // ".MuiTablePagination-selectLabel": {display: 'none'},
  // ".MuiTablePagination-input": {display: 'none'},
};
const AttendanceStatus: React.FC<any> = (props) => {
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [gridData, setGridData] = useState<GridData>({
    data: [],
    total: 0,
    num_pages: 0,
  });
  const [params, setParams] = useState<FilterType>({
    date_range: [new Date(), new Date()],
  });

  const {refresh, setRefresh} = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [pageSize, setPageSize] = React.useState(25);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPageSize(parseInt(event.target.value))
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const fetchGridData = () => {
    setLoading(true);
    const {date_range} = params;
    date_range.forEach((element, index) => {
      params.date_range[index] = new Date(element).toISOString().slice(0, 10);
    });
    GenericService.list(ATTEndpoints.ATT_EMPLOYEE_PRESENT_STATUS_URI, {
      current: page + 1,
      pageSize: pageSize,
      ...params,
    })
      .then((response) => {
        setGridData(response?.data);
      })
      .catch((error) => {
        if (error.response?.status < 500) {
          // do something when status is 4xx.
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGridData();
  }, [page, pageSize]);

  const refreshGridData = () => {
    fetchGridData();
    // setRefresh(refresh + 1);
  };

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "50px",
          lineHeight: '50px',
          marginLeft: "5px",
        }}
      >
        <TitleTypography>
          {t("common.Attendance Status")}
        </TitleTypography>

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box style={{
            display: "inline-block", width: "6px", height: "6px", borderRadius: "50%",
            backgroundColor: "#F65555"
          }}></Box>
          <Typography
            sx={{
              fontSize: "12px !important",
              color: "#999999 !important",
              marginLeft: "10px !important",
            }}
          >
            {t("common.dashboard tips1")}
          </Typography>
          <Box style={{
            display: "inline-block", width: "6px", height: "6px", borderRadius: "50%",
            backgroundColor: "#2BA9DE", marginLeft: "10px"
          }}></Box>
          <Typography
            sx={{
              fontSize: "12px !important",
              color: "#999999 !important",
              marginLeft: "10px  !important",
            }}
          >
            {t("common.dashboard tips2")}
          </Typography>

          <Tooltip title={t("common.refresh")}>
            <div>
              <ActionButton
                icon="icon-ic_refresh"
                handleClick={refreshGridData}
              ></ActionButton>
            </div>
          </Tooltip>
        </Grid>
      </Grid>

      <CircularProgress
        sx={{
          position: "relative",
          left: "50%",
          top: "50%",
          marginTop: "-50px",
          marginLeft: "25px",
          display: isLoading ? "block" : "none",
        }}
      />

      {!isLoading && (
        <Grid sx={{display: 'flex', flexDirection: 'column', flexGrow: 1, height: '86%'}}>
          <Grid
            sx={{
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            <Grid
              sx={{
                display: isLoading ? "none" : "flex",
                flexWrap: "wrap",
                overflowY: "auto",
              }}
            >
              {gridData?.data.map((item: any, index: any) => (
                <Item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    width: "33.333%",
                    padding: "6px",
                    minWidth: "200px",
                    maxWidth: "400px",
                    boxSizing: "border-box"
                  }}
                  key={index}
                  elevation={0}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "#ffffff",
                      border: "1px solid #F2F5F8",
                      padding: "8px 10px",
                      borderRadius: "6px",
                    }}
                  >
                    <Grid
                      sx={{
                        height: "42px",
                        width: "42px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        border: `1px solid ${
                          item.att_status === "Absent" ? "#F65555" : "#2BA9DE"
                        }`,
                        boxShadow: `0px 0px 4px ${
                          item.att_status === "Absent" ? "#F65555" : "#2BA9DE"
                        }`,
                      }}
                    >
                      <AvatarIcon
                        diameter={18}
                        name={item.first_name ? item.first_name : "None"}
                        profilePhoto={item?.profilePhotoURL}
                        style={{padding: "10px"}}
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{
                        ml: 1,
                      }}
                    >
                      <Tooltip title={item.first_name ? item.first_name : ""}>
                        <Typography
                          sx={{
                            textOverflow: "ellipsis",
                            color: "#3D3D3D",
                            fontSize: "14px !important",
                            wordBreak: "break-all",
                            whiteSpace: "nowrap",
                            textAlign: "left",
                          }}
                        >
                          {item.first_name}
                        </Typography>
                      </Tooltip>
                      <Tooltip
                        title={item.employee_code ? item.employee_code : ""}
                      >
                        <Typography
                          sx={{
                            textOverflow: "ellipsis",
                            wordBreak: "break-all",
                            whiteSpace: "nowrap",
                            marginTop: "6px !important",
                            fontSize: "12px !important",
                            color: "#919DA1 !important",
                            textAlign: "left",
                          }}
                        >
                          {item.employee_code}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Item>
              ))}
              {gridData?.total == 0 &&
                <Box sx={{
                  mt: 1,
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: 'column',
                  paddingTop: "20%",
                  paddingLeft: "50%"
                }}>
                  <Box>
                    {t("common.No Rows")}
                  </Box>
                </Box>
              }
            </Grid>
          </Grid>
          <Grid sx={{height: '52px'}}>
            <TablePagination
              component="div"
              count={gridData.total}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={customDataGridStyle}
              labelRowsPerPage={t("common.datagrid.paginate")}
            />
          </Grid>
        </Grid>
      )}

    </Grid>
  );
};
export default AttendanceStatus;
