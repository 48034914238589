import React from "react";
import {Box, Breadcrumbs, Grid, Typography} from "@mui/material";
import {AccessTime} from "@mui/icons-material";
import SubMenuItem from "../../../components/menu/SubMenuItem";
import WebURL from "../../../urls";
import { useTranslation } from "react-i18next";

const TimecardHome: React.FC = () => {
  const { t } = useTranslation();
  const items = [
    {name: "punchRecord", title: t("common.Clocking Records"), icon: AccessTime, path: WebURL.ATT_TIMECARD_PUNCH_RECORD},
    {name: "timecard", title: t("common.Timecard"), icon: AccessTime, path: WebURL.ATT_TIMECARD_TIMECARD},
  ]
  return (
    <Box>
      <Grid  className={"toolbar-container"}>
        <Breadcrumbs sx={{height: "32px", p: '8px'}}>
          <Typography>{t("common.Timecard")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid>
        <Grid container={true} rowSpacing={2} sx={{margin: "0"}}>
          {
            items.map((item) => <SubMenuItem key={item.name} {...item} />)
          }
        </Grid>
      </Grid>
    </Box>
  )
}
export default TimecardHome
