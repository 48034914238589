import React from "react";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import NGPrivacyPolicy from "@/components/ngteco-form/NGPrivacyPolicy"
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";


const PrivacyPolicyView: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.AUTH_USER_ABOUT, label: t("common.About") },
        { label: t("common.Privacy Policy") },
      ]} />
      <NGPrivacyPolicy/>
    </>
  )
}

export default PrivacyPolicyView;
