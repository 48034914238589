import React, {Dispatch, SetStateAction, useState} from "react";
import MainLayout from "@/pages/auth/Login/components/MainLayout";
import {ReactComponent as LayoutImage} from "@/assets/images/UserLogin.svg"
import {
  Box, Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {object, TypeOf, z} from "zod";
import WebURL from "@/urls";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useSnackbar} from "notistack";
import UserService from "@/services/auth/UserService";
import {LoadingButton} from "@mui/lab";
import CustomLink from "@/pages/auth/Login/components/CustomLink";

const signUpSchema = object({
  agreement: z.boolean(),
  privacy: z.boolean(),
})
type signUpInput = TypeOf<typeof signUpSchema>;

type PolicyProps = {
  setPolicy: Dispatch<SetStateAction<string>>,
  setUserAgreement: Dispatch<SetStateAction<boolean>>
}

const SignUpUserAgreement: React.FC<PolicyProps> = (props) => {
  const native = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const {register, handleSubmit, formState: {errors}} = useForm<signUpInput>({
    resolver: zodResolver(signUpSchema)
  })
  const {enqueueSnackbar} = useSnackbar()
  const onSignUp = async (data: any) => {
    if(!data.agreement || !data.privacy){
      enqueueSnackbar(t("organ.Please read user agreement and privacy policy before sign up"), {
        variant: "warning",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      })
      return
    }
    try {
      setLoading(true)
      const user = await UserService.signUp(data);
      enqueueSnackbar("Success", {
        variant: "success",
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      })
      native(WebURL.AUTH_USER_LOGIN);
    } finally {
      setLoading(false)
    }
  }
  return (
      <Grid container={true} rowSpacing={2} component={"form"} noValidate sx={{ width: '30vw' }} onSubmit={handleSubmit(onSignUp)}>
        <Grid item xs={12} sx={{display: "inline-flex", flexDirection: "column", marginLeft: -1}}>
          <Typography variant="h5">{t("signUp.User agreement")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ color: "gray"}}>{t("signUp.user agreement text1")}</Typography>
          <Typography sx={{ color: "gray", wordBreak:"break-end"}}>{t("signUp.user agreement text2")}</Typography>
          <Typography sx={{ color: "gray"}}>{t("signUp.user agreement text3")}</Typography>
          <Typography sx={{ color: "gray"}}>{t("signUp.user agreement text4")}</Typography>
          <Typography sx={{ color: "gray"}}>{t("signUp.user agreement text5")}</Typography>
        </Grid>
        <Grid item xs={12} sx={{
            display: "inline-flex",
            justifyContent: "center",
            marginTop: "20px"
          }}>
          <Button variant="contained"
                  disableElevation
                  fullWidth={true}
                  sx={{textTransform: "none" }}
                  onClick={() => {props.setPolicy("main"); props.setUserAgreement(true)}}>
            {t("signUp.Read and agree")}
          </Button>
        </Grid>
        <Grid item xs={12} sx={{
            display: "inline-flex",
            justifyContent: "center"
          }}>
          <Button variant="outlined"
                  disableElevation
                  fullWidth={true}
                  sx={{textTransform: "none" }}
                  onClick={() => {props.setPolicy("main"); props.setUserAgreement(false)}}>
            {t("signUp.Not agree and quit")}
          </Button>
        </Grid>
      </Grid>
  )
}
export default SignUpUserAgreement
