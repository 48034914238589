import request from '@/utils/Request';
import qs from 'qs';
import {LocationEndpoints} from "@/services/location/endpoints";

const list = async (params: Record<string, any>) => {
  const response = await request.authorizedAxios().get(LocationEndpoints.SITE_URI, {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response?.data;
}

const update = async (id: string, params: Record<string, any>) => {
  const response = await request.authorizedAxios().put(`${LocationEndpoints.SITE_URI}${id}/`, params);
  return response?.data;
}

const SiteService = {
  list,
  update
}

export default SiteService;
