import DeviceService from "@/services/dms/DeviceService";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Drawer,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { object, string, TypeOf, z } from "zod";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {
  CancelButton,
  SavingButton,
} from "@/components/data-grid/CustomButton";
import SiteService from "@/services/location/SiteService";
import { useDispatch, useSelector } from "react-redux";
import { getOrganSites } from "@/store/slices/Site";
import { ngteco_timezones } from "@/components/resource/timezone";
import {
  CustomTypography,
  ColorlibStepIconRoot,
  BgBox,
} from "../commonCompents";

const customDrawerStyle = {
  "& .MuiDrawer-paper": { width: "30vw" },
};

export interface DeviceProp {
  deviceId: any;
}

const DeviceRegister = (props: DeviceProp) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [device, setDevice] = useState<{ deviceId: string; sn: string }>();
  const checkDeviceSchema = object({
    sn: z.string().min(1, t("common.This field is required!")),
  });
  type checkInput = TypeOf<typeof checkDeviceSchema>;
  const checkForm = useForm<checkInput>({
    resolver: zodResolver(checkDeviceSchema),
  });
  const theme = useTheme();
  const deviceSchema = object({
    site: z.object(
      {
        id: z.string(),
        name: z.string(),
        address: z.object({
          timeZone: z.string().nullish(),
        }),
      },
      {
        required_error: t("common.This field is required!"),
        invalid_type_error: t("common.This field is required!"),
      }
    ),
    // zone: z.object({
    //   id: z.string(),
    //   name: z.string()
    // }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    timezone: z.union([
      z.object(
        {
          value: z.string(),
          code: z.string(),
          group: z.string(),
          name: z.string(),
        },
        {
          required_error: t("common.This field is required!"),
          invalid_type_error: t("common.This field is required!"),
        }
      ),
      z.string({ required_error: t("common.This field is required!") }),
    ]),
  });
  type deviceType = TypeOf<typeof deviceSchema>;

  const deviceForm = useForm<deviceType>({
    resolver: zodResolver(deviceSchema),
  });
  const sites: { id: string; name: string; address: Record<string, any> }[] =
    useSelector((state: any) => state.site.sites);
  // const zones: {id: string, name: string}[] = useSelector((state: any) => state.zone.zones);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getOrganSites());
  }, []);
  const timezoneList = ngteco_timezones();
  const [timeZone, setTimeZone] = useState<string>();
  const [timezoneEmpty, setTimezoneEmpty] = useState<boolean>(false);
  const [getDevice, setGetDevice] = useState<string>("1");
  return (
    <>
      <Stack sx={{ marginRight: "20px" }} spacing={1}>
        <BgBox>
          <Typography>{t("dms.tip04")}</Typography>
          <CustomTypography>{t("dms.tip05")}</CustomTypography>
          <CustomTypography>{t("dms.tip06")}</CustomTypography>
          <CustomTypography>{t("dms.tip07")}</CustomTypography>
          <CustomTypography>{t("dms.tip08")}</CustomTypography>
          <CustomTypography>{t("dms.tip09")}</CustomTypography>
        </BgBox>

        <Box component={"form"}>
          <Grid container>
            <Typography
              sx={{
                width: "800px",
                height: "50px",
                color: "#0b2200",
                position: "relative",
              }}
            >
              <h3>{t("common.Add A New Device")}</h3>
            </Typography>

            <Grid xs={6} item sx={{ display: "inline-flex" }}>
              <Grid>
                <Typography
                  sx={{
                    fontSize: "14px !important",
                    color: "#666666 !important ",
                    marginBottom:'8px'
                  }}
                >
                  {t("dms.Enter device SN")}
                </Typography>
                <TextField
                  required
                  autoFocus
                  variant="outlined"
                  size={"small"}
                  sx={{ minWidth: "300px" }}
                  fullWidth
                  error={!!checkForm.formState.errors["sn"]}
                  helperText={checkForm.formState.errors["sn"]?.message || ""}
                  {...checkForm.register("sn")}
                />
              </Grid>
              <Grid>
                <LoadingButton
                  loading={loading}
                  disableElevation
                  sx={{
                    width: "10vw",
                    textTransform: "capitalize",
                    display: "flex",
                    height:'40px !important',
                    marginTop: "30px",
                    marginLeft: "5px",
                  }}
                  variant="contained"
                  onClick={checkForm.handleSubmit(async () => {
                    setLoading(true);
                    const isValid = await checkForm.trigger();
                    try {
                      if (isValid) {
                        const { sn } = checkForm.getValues();
                        const res = await DeviceService.deviceVerify(sn);
                        if (res.data.deviceId) {
                          setDevice({ deviceId: res.data.deviceId, sn });
                          props.deviceId(res.data.deviceId);
                          setVisible(true);
                        }
                      }
                    } catch (e) {
                      console.log(e);
                    } finally {
                      setLoading(false);
                    }
                  })}
                >
                  {t("common.verify sn")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      {/*<DeviceBinding visible={visible} device={device} onClose={() => setVisible(false)}/>*/}
      <Drawer
        sx={customDrawerStyle}
        anchor="right"
        open={visible}
        onClose={() => setVisible(false)}
      >

        <Grid
          sx={{ marginTop: "3vh", fontSize: "1.6rem", textAlign: "center" }}
        >
          {t("dms.tip01")}
        </Grid>
        <Stack spacing={2} sx={{ margin: "0 1vw" }}>

          <Grid>
            <Stack direction="row" spacing={2}>
              <Avatar sx={{ width: 50, height: 50 }}>
                <AdUnitsIcon sx={{ fontSize: 30 }} color="action" />
              </Avatar>
              <Box sx={{ fontSize: 16, display: "flex", alignItems: "center" }}>
                <Box sx={{ color: "gray" }}>
                  {t("dms.Serial Number")}: {device?.sn}
                </Box>
              </Box>
            </Stack>
            <Box>
              <Typography gutterBottom>
                {t("dms.tip02")}
                <br />
              </Typography>
              <Typography gutterBottom color={theme.palette.primary.main}>
                {t("dms.tip03")}
              </Typography>
            </Box>
            <Box component={"form"}>
              <Stack sx={{ lineHeight: "5vh" }}>
                <ControlAutoComplete
                  label={t("dms.Bind Site")}
                  name={"site"}
                  form={deviceForm}
                  required={true}
                  options={sites}
                  onChange={(value) => {
                    const timezone = value?.address.timeZone;
                    if (timezone) {
                      deviceForm.setValue(
                        "timezone",
                        timezoneList.find((item) => item.value === timezone)
                          ?.name || `UTC${timezone}`
                      );
                      setTimezoneEmpty(false);
                    } else {
                      setTimezoneEmpty(true);
                    }
                  }}
                />
                {timezoneEmpty ? (
                  <ControlAutoComplete
                    label={t("dms.Site Timezone")}
                    name={"timezone"}
                    form={deviceForm}
                    options={timezoneList}
                    required={true}
                    groupBy={(option) => option.group}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={({ value }) => {
                      setTimeZone(value);
                    }}
                  />
                ) : (
                  <ControlledTextFiled
                    label={t("dms.Site Timezone")}
                    fullWidth
                    name={"timezone"}
                    hookForm={deviceForm}
                    InputProps={{
                      readOnly: true,
                      // disabled: true,
                    }}
                  />
                )}
              </Stack>
            </Box>
          </Grid>
        </Stack>
        <Grid sx={{ position: "fixed", bottom: "5vh" }}>
          <Stack direction="row" spacing={1}>
            <Box>
              <CancelButton
                sx={{
                  width: "13vw",
                  marginLeft: "1.5vw",
                  textTransform: "none",
                }}
                onClick={() => {
                  deviceForm.reset({});
                  setVisible(false);
                }}
              />
            </Box>
            <Box>
              <SavingButton
                loading={loading}
                sx={{ width: "13vw", textTransform: "none" }}
                onClick={async () => {
                  setLoading(true);
                  try {
                    const isValid = await deviceForm.trigger();
                    if (isValid) {
                      const values = deviceForm.getValues();
                      // set registration device default
                      const origin = {
                        ...device,
                        siteId: values.site.id,
                        registrationDevice: 1,
                      };
                      const site_res = timezoneEmpty
                        ? await SiteService.update(values.site.id, {
                          address: {
                            timeZone,
                            city: "default",
                            addressLine1: "default",
                            siteId: values.site.id,
                          },
                        })
                        : {};
                      const device_res = await DeviceService.deviceBinding(
                        origin
                      );
                      const res_data = device_res?.data;
                      setGetDevice(res_data.data?.deviceId.toString());
                      setVisible(false);
                    }
                  } catch (e) {
                    console.log("error=", e);
                  } finally {
                    setLoading(false);
                  }
                }}
              />
            </Box>
          </Stack>
        </Grid>
      </Drawer>
    </>
  );
};

export default DeviceRegister;
