import React from "react";
import NGTextField from "@/components/ngteco-form/NGTextField";
import {TextFieldProps} from "@mui/material";
import {Controller, UseFormReturn} from "react-hook-form";


type ControlledTextFiledProps = Omit<TextFieldProps, 'name'> & {
  hookForm: UseFormReturn<any>;
  name: string;
  tooltip?: string
}

const ControlledTextFiled: React.FC<ControlledTextFiledProps> = (props) => {
  const {hookForm, name, ...textFieldProps} = props
  return (
    <Controller
      control={hookForm.control}
      render={({field, fieldState}) => {
        return (
          <NGTextField
            {...textFieldProps}
            {...field}
            error={!!fieldState.error}
            helperText={fieldState.error?.message || ""}
          />
        )
      }}
      name={name}
    />
  )
}
export default ControlledTextFiled
