import DeviceService from "@/services/dms/DeviceService";
import EmployeeService from "@/services/hr/EmployeeService";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import { Dispatch, MutableRefObject, SetStateAction, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

interface NoticeProps {
  setStep: Dispatch<SetStateAction<string>>,
  setData: Dispatch<SetStateAction<any>>
}

const Notice: React.FC<NoticeProps> = (props) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const checkAsset = async () => {
    setLoading(true);
    try {
      const employee_res = await EmployeeService.list({current: 1, pageSize: 1});
      const device_res = await DeviceService.list({current: 1, pageSize: 1});
      const device = device_res.data.total, person = employee_res.data.total;
      console.log(device, person);
      if (!(device === 0 && person === 0)) {
        props.setData({device, person});
        props.setStep("info");
      } else {
        props.setStep("confirm");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box>
        <Grid sx={{fontSize: '20px', padding: '16px 0'}}>
          {t("organ.destroy.noticeTip01")}
        </Grid>
        <Grid sx={{lineHeight: '24px'}}>
          {t("organ.destroy.noticeTip02")}
        </Grid>
      </Box>
      <Box sx={{ position: 'absolute', bottom: '20px', width: '500px'}}>
        <LoadingButton
          sx={{ width: '100%', padding: "10px", textTransform: "capitalize"}}
          onClick={checkAsset}
          variant="contained"
          loading={loading}
        >
          {t("organ.destroy.Check Asset")}
        </LoadingButton>
      </Box>
    </>
  )
}

export default Notice;
