import EmployeeService from "@/services/hr/EmployeeService"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


const initialState = {
  employees: [],
}

export const getOrganEmployee = createAsyncThunk('employee/byOrgan', async () => {
  const {data} = await EmployeeService.list({current: 1, pageSize: 100});
  return data;
})

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganEmployee.fulfilled, (state, action) => {
      return {
        employees: action.payload.data
      }
    })
  }
});

export default employeeSlice.reducer;
