import {parse, format} from "date-fns";

const time2datetime = (time: string) => {
  return parse(time, "HH:mm", new Date());
}
const datetime2timeString = (datetime: Date|null) => {
  if(!datetime){
    return ""
  }
  return format(datetime, "HH:mm").toString();
}
const datetime2string = (datetime: Date|null, stringFormat='yyyy-MM-dd HH:mm:ss') => {
  if(!datetime){
    return ""
  }
  return format(datetime, stringFormat).toString();
}

function timeFn(First_time: Date, Last_time: Date|null,) {//di作为一个变量传进来
    //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
    if (Last_time == null){
      Last_time = new Date()
    }
    if (First_time > Last_time){
      Last_time.setDate(Last_time.getDate() + 1);
    }
    const dateDiff = Last_time.getTime() - First_time.getTime();//时间差的毫秒数
    const minuteDiff = Math.floor(dateDiff / (60 * 1000));//计算出相差天数
    const dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
    const leave1=dateDiff%(24*3600*1000)    //计算天数后剩余的毫秒数
    const hours=Math.floor(leave1/(3600*1000))//计算出小时数
    //计算相差分钟数
    const leave2=leave1%(3600*1000)    //计算小时数后剩余的毫秒数
    const minutes=Math.floor(leave2/(60*1000))//计算相差分钟数
    //计算相差秒数
    const leave3=leave2%(60*1000)      //计算分钟数后剩余的毫秒数
    const seconds=Math.round(leave3/1000)
    console.log(" 相差 "+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒")
    console.log(dateDiff+"时间差的毫秒数",dayDiff+"计算出相差天数",leave1+"计算天数后剩余的毫秒数"
        ,hours+"计算出小时数",minutes+"计算相差分钟数",seconds+"计算相差秒数");
    return minuteDiff
}


const Util = {
  time2datetime,
  datetime2timeString,
  datetime2string,
  timeFn,
}
export default Util;
