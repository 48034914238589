import { Grid, Breadcrumbs, Typography, Box } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import WebURL from "@/urls";
import BackGo from "@/components/back-go/BackGo";

const customDataGridStyle = {
  width: "1000px",
  paddingTop: "10px",
  '& .MuiDataGrid-columnHeaders': { backgroundColor: '#F0F1F4!important', color: '#304669!important' },
  '.MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
  '.MuiDataGrid-cell': { borderBottom: '0px', color: '#304669' },
  '.MuiDataGrid-cell:focus-within': { outline: 'none!important', color: '#2BA9DE!important' },
  '.MuiDataGrid-columnSeparator': { display: 'none !important' },
  '.MuiSvgIcon-root': { backgroundColor: 'transparent' },
  '.MuiDataGrid-root': { padding: '8px', borderRadius: '4px' },
  '.MuiDataGrid-row': { borderBottom: '1px solid #E7EBF0', maxHeight: '48px!important', minHeight: '48px!important' },
  '.MuiCheckbox-root': { color: '#D1D1D1' },
  '.MuiDataGrid-cellContent': {
    color: '#304669!important',
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    fontSize: '14px'
  },
  '.MuiDataGrid-virtualScrollerRenderZone': { backgroundColor: '#FFFF', width: "100%" },
  '.MuiDataGrid-footerContainer': { display: 'block', width: '100%', border: '0px' },
  '.MuiTablePagination-spacer': { display: 'none' },
  '.MuiTablePagination-actions': { position: 'absolute', right: '0px', backgroundColor: '#FFF' },
  '.MuiTablePagination-displayedRows': { position: 'absolute', right: '90px' },
  '.MuiDataGrid-virtualScroller': { overflow: 'auto !important' },
  '.MuiTablePagination-select': { backgroundColor: '#F0F1F4', borderRadius: '4px!important' },
  '.MuiTablePagination-toolbar': { paddingLeft: '0px' },
  '.MuiIconButton-root.Mui-disabled': { backgroundColor: '#F0F1F4', borderRadius: '0px' },
  // '.MuiIconButton-root': {borderRadius: '0px'},
  '.Mui-checked': { color: 'rgb(54, 201, 109)!important' },
  '.MuiDataGrid-virtualScrollerContent': { height: "400px !important" }
};





const InfoCollectionFormView: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.app.user);

  const columns: GridColDef[] = [
    { field: 'info_mame', headerName: t("common.Info Name"), type: "string", minWidth: 200 },
    { field: 'purpose_of_usage', headerName: t("common.Purpose of Usage"), type: "string", minWidth: 200 },
    { field: 'scenes_of_usage', headerName: t("common.Scenes of Usage"), type: "string", minWidth: 300 },
    { field: 'information', headerName: t("common.Information"), type: "string", minWidth: 200 },
  ]

  const rows = [
    { id: 1, info_mame: 'Person Name', purpose_of_usage: 'Authentication',
    scenes_of_usage: 'Complete User information', information: user?.firstName + ' ' + user?.lastName},
    { id: 2, info_mame: 'Email Address', purpose_of_usage: 'Provide account service',
    scenes_of_usage: 'Complete information,account...', information: user?.email},
    { id: 3, info_mame: 'Email login days', purpose_of_usage: 'Quick login',
    scenes_of_usage: 'Email login', information: "/"},
  ];

  return (
    <Box sx={{width: 'calc(100% - 16px)', marginBottom: '24px', boxSizing: 'border-box', height: 'calc(100% - 100px)'}}>
      <CustomBreadcrumbs record={[
        { path: WebURL.AUTH_USER_ABOUT, label: t("common.About") },
        { label: t("common.Info Collection Form") },
      ]} />
      <BackGo title={t("common.Info Collection Form")}></BackGo>
      <Box sx={{ padding: "24px", backgroundColor: '#ffffff', marginTop: '12px', height: 'calc(100% - 100px)' }}>
        <DataGrid
          columns={columns}
          hideFooterPagination={true}
          rows={rows}
          autoHeight={true}
          hideFooterSelectedRowCount={true}
          disableColumnMenu={true}
          style={{ border: 'none'}}
          components={{}}
          componentsProps={{
            pagination: {
              labelRowsPerPage: t("common.datagrid.paginate"),
            }
          }}
          sx={customDataGridStyle}
        />
      </Box>
    </Box>
  )
}

export default InfoCollectionFormView;
