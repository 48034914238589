import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Autocomplete, Box, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import MainLayout from "../MainLayout";
import { ApartmentOutlined } from "@mui/icons-material";
import CompanyService from "@/services/auth/CompanyService";
import UserService from "@/services/auth/UserService";
import { useDispatch, useSelector } from "react-redux";
import { obtainCompanyInfo } from "@/store/slices/App";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";
import { ReactComponent as LayoutImage } from "@/assets/images/UserLogin.svg"
import LogoView from "@/pages/auth/Login/components/LogoView";
import NGTextField from "@/components/ngteco-form/NGTextField";
import CustomLink from "@/pages/auth/Login/components/CustomLink";
import { getUserOrgans } from "@/store/slices/Organization";
import { enqueueSnackbar } from "notistack";
import Dialog from "@/components/dialog";
import SnackbarUtil from "@/utils/SnackbarUtil";
import CommonUtils from "@/utils/CommonUtils";
import { LoadingButton } from "@mui/lab";


const OrganizationSelection: React.FC = () => {
  const native = useNavigate();
  const dispatch = useDispatch();
  const organs: { id: string, name: string }[] = useSelector((state: any) => state.organ.organs)
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<string>("");

  const onOrganSelected = async () => {
    if (!selectedCompany) {
      SnackbarUtil.error(t("organ.Please select an organization"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
      return
    }
    setLoading(true)
    try {
      const { status, data } = await CompanyService.selectCompany({ company_id: selectedCompany });
      if (status < 400) {
        localStorage.setItem('accessToken', data.access);
        localStorage.setItem('refreshToken', data.refresh);
        dispatch<any>(obtainCompanyInfo())
        native(WebURL.DASHBOARD)
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    dispatch<any>(getUserOrgans())
  }, [])

  const handleDeleteAccount = () => {

    Dialog.confirm({
      title: t("common.Are you sure?"),
      content: t('organ.You want to delete account permanently'),
      onConfirm: async () => {
        // setLoading(true);
        const { data } = await CompanyService.companyList({ current: 1, pageSize: 50, toggleKeyToURL: true });
        if (data.total > 0) {
          // setLoading(false);
          Dialog.ok({
            content: t('common.The account still has organization. Before deleting the account, you must manually remove organization after loging firstly.'),
          })
        } else {
          UserService.deleteUser().then(res => {
            SnackbarUtil.success(t("common.success"), { anchorOrigin: { vertical: 'top', horizontal: 'center' } })
            CommonUtils.setCookie("autoUserInfo", "", 0);
            native(WebURL.AUTH_USER_LOGIN)
          })
        }
      },
    })
  }

  return (
    <MainLayout image={<LayoutImage />}>
      <Grid sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px solid #CAD0D9",
        marginTop: "9vh",
        height: "76vh",
        minHeight: "530px",
        width: '34vw',
        borderRadius: "12px",
        position: "relative",
        backgroundColor: "#ffffff"
      }}>
        <LogoView />
        <Grid component={"form"} sx={{ width: '28vw', }}>
          <Stack>
            <Typography variant="h6">{t("organ.Select organization")}</Typography>
            <Box sx={{ padding: '16px 0' }}>
              <Typography>{t("organ.selectCreateTip")}</Typography>
              <Typography>{t("organ.selectSwitchTip")}</Typography>
            </Box>
            <Autocomplete
              fullWidth
              options={organs}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => {
                return (
                  <NGTextField
                    {...params}
                    required
                    label={t("organ.Organization Name")}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <InputAdornment position={"start"}><ApartmentOutlined /></InputAdornment>
                    }}
                  />
                )
              }}
              onChange={(event, value: AUTH.Company | null) => {
                setSelectedCompany(value?.id || "");
              }}
            />
            <Grid item xs={12} sx={{marginTop: "30px"}}>
              <LoadingButton
                loading={loading}
                fullWidth={true}
                variant={"contained"}
                sx={{textTransform: "capitalize", backgroundColor: "#2BA9DE" }}
                onClick={onOrganSelected}
              >
                {t("organ.Enter")}
              </LoadingButton>
            </Grid>
            <Stack spacing={1} sx={{ margin: '10px 0' }}>

              <Grid sx={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}>
                <CustomLink sx={{color: '#2BA9DE'}}
                  label={t("login.Back to login")}
                  onClick={() => native(WebURL.AUTH_USER_LOGIN)}
                />
                {/*<Box sx={{ display: "inline-flex" }}>*/}
                {/*  <Typography sx={{ fontSize: "14px" }}>{t("organ.Don't have an organization?")}</Typography>*/}
                {/*  <CustomLink*/}
                {/*    label={t("organ.Create organization")}*/}
                {/*    onClick={() => native(WebURL.AUTH_USER_COMPANY_CREATION)}*/}
                {/*  />*/}
                {/*</Box>*/}
              </Grid>
              <Grid sx={{
                display: "inline-flex",
                justifyContent: "center",
                paddingBottom: '24px',
                bottom: 0,
                position: "absolute",
                textAlign: 'center',
                width: '28vw',
              }}>
                <CustomLink sx={{color: '#2BA9DE'}}
                  label={t("login.Delete account permanently")}
                  onClick={() => {
                    handleDeleteAccount()
                  }}
                />
              </Grid>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </MainLayout>
  )
}
export default OrganizationSelection
