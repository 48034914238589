import React from "react";
import {ButtonProps, Button, styled} from "@mui/material";
import {LoadingButton, LoadingButtonProps} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

// const cancel = (props: ButtonProps) => {
//   return {
//     variant: 'outlined' as const,
//     children: props?.children || 'Cancel',
//     ...props
//   }
// }

export const CustomButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      sx={{width: '10vw', textTransform: "none"}}
      {...props}
    >
      {props.children}
    </Button>
  )
}

const CustomLoadingButton: React.FC<LoadingButtonProps> = (props) => {
  return (
    <LoadingButton
      sx={{width: '10vw', textTransform: "none"}}
      {...props}
    >
      {props.children}
    </LoadingButton>
  )
}

export const CancelButton: React.FC<ButtonProps> = (props) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  return(
    <CustomButton
      children={t("common.cancel")}
      variant={"outlined"}
      onClick={() => navigate(-1)}
      {...props}
    />
  )
}

export const SaveButton: React.FC<ButtonProps> = (props) => {
  const {t} = useTranslation();
  return(
    <CustomButton
      children={t("common.confirm")}
      variant={"contained"}
      {...props}
    />
  )
}

export const SavingButton: React.FC<LoadingButtonProps> = (props) => {
  const {t} = useTranslation();
  return(
    <CustomLoadingButton
      children={t("common.confirm")}
      variant={"contained"}
      {...props}
    />
  )
}
