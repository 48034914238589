import request from "../../utils/Request";
import {CMSEndpoints} from "./endpoints";

const update = async (id: string, data: any) => {
  const response = await request.authorizedAxios().put(`${CMSEndpoints.CMS_CREDENTIAL_URI}${id}/`, data);
  return response;
}

const updateUserRole = async (id: string, data: { [key: string]: any }) => {
  const response = await request.authorizedAxios().post(`${CMSEndpoints.CMS_CREDENTIAL_URI}${id}/update_user_role/`, data);
  return response;
}

const deletePersonCredentials = async (id: string, config?: { [key: string]: any }) => {
  const res = await request.authorizedAxios().delete(`${CMSEndpoints.CMS_CREDENTIAL_URI}${id}/empty_person_credentials/`, config);
  return res.data;
};

const deleteCredsForDevAndCloud = async (id: string, config?: { [key: string]: any }) => {
  const res = await request.authorizedAxios().delete(`${CMSEndpoints.CMS_CREDENTIAL_URI}${id}/delete_person_cred/`, config);
  return res.data;
};

const setPersonPassword = async (data: { [key: string]: any }) => {
  const response = await request.authorizedAxios().post(`${CMSEndpoints.CMS_CREDENTIAL_URI}set_password/`, data);
  return response;
}

const CredentialService = {
  update,
  updateUserRole,
  setPersonPassword,
  deletePersonCredentials,
  deleteCredsForDevAndCloud
}
export default CredentialService
