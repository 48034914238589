import { styled } from "@mui/material/styles";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import img_quick_start_bg from "@/assets/quick_start/img_quick_start_bg.png";
export const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "14px !important",
  color: "#666666",
  margin: "10px 0px !important",
}));

export const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: ownerState.active ? 40 : 30,
  height: ownerState.active ? 40 : 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundColor: "#2BA9DE",
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundColor: "#2BA9DE",
      },
    },
  ],
}));

export const BgBox = (props: any) => {
  const { children, ...other } = props;
  return (
    <Grid
      sx={{
        padding: "10px 20px",
        backgroundImage: "url(" + img_quick_start_bg + ")",
        ...other.sx,
      }}
      {...other}
    >
      {children}
    </Grid>
  );
};
