
export default function MuiSelect(theme:any) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
         padding:'3px 6px'
        },
      },
    },
  };
}
