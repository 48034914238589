import request from "../../utils/Request";
import {AuthEndpoints} from "./endpoints";
import header from "@/parts/Header";

const login = async (credentials: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(AuthEndpoints.AUTH_USER_LOGIN, credentials)
  return response?.data;
}

const signUp = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(AuthEndpoints.AUTH_USER_SIGN_UP, data)
  return response?.data;
}

const forgotPwd = async (data: Record<string, any>) => {
  const response = await request.unauthorizedAxios().post(AuthEndpoints.AUTH_USER_FORGOT_PWD, data)
  return response?.data;
}

const userInfo = async () => {
  const response = await request.authorizedAxios().get(AuthEndpoints.AUTH_USER_INFO_URI);
  return response?.data;
}

const deleteUser = async () => {
  const response = await request.authorizedAxios().delete(AuthEndpoints.AUTH_USER_DELETE_URI);
  return response?.data;
}

const updateUserInfo = async (data: Record<string, any>) => {
  const response = await request.authorizedAxiosForm().put(AuthEndpoints.AUTH_UPDATE_USER_INFO_URI, data);
  return response?.data;
}

const userList = async () => {
  return request.authorizedAxios().get(AuthEndpoints.AUTH_USER_URI);
}

const resetPassword = async (data: Record<string, any>) => {
  return request.authorizedAxios().put(AuthEndpoints.AUTH_USER_RESET_PASSWORD, data);
}

const supportFeedback = async (data: Record<string, any>) => {
  return request.authorizedAxios().post(AuthEndpoints.AUTH_USER_SUPPORT_FEEDBACK, data, {headers:{'Content-type': 'multipart/form-data'}});
}

const firstLogin = async (data: Record<string, any>) => {
  if (data['method'] == 'post') {
    return request.authorizedAxios().post(AuthEndpoints.AUTH_USER_FIRST_LOGIN, data);
  } else {
    return request.authorizedAxios().get(AuthEndpoints.AUTH_USER_FIRST_LOGIN);
  }
}

const firstLoginByTimesheet = async (data: Record<string, any>) => {
  if (data['method'] == 'post') {
    return request.authorizedAxios().post(AuthEndpoints.AUTH_USER_FIRST_LOGIN_TIMESHEET, data);
  } else {
    return request.authorizedAxios().get(AuthEndpoints.AUTH_USER_FIRST_LOGIN_TIMESHEET);
  }
}

const UserService = {
  login,
  deleteUser,
  userInfo,
  updateUserInfo,
  userList,
  signUp,
  forgotPwd,
  resetPassword,
  supportFeedback,
  firstLogin,
  firstLoginByTimesheet,
};
export default UserService
