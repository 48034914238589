import React from 'react';
import {useNavigate} from 'react-router-dom';
import {FormControlLabel, FormGroup, Stack, Tooltip} from '@mui/material';
import CustomDataGrid from '@/components/data-grid/CustomDataGrid';
import {ATTEndpoints} from "../../../../../services/att/endpoints";
import CustomRowButton from '@/components/data-grid/CustomRowButton';
import {RemoveRedEyeOutlined} from '@mui/icons-material';
import {GridColDef, GridColumnHeaderParams} from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import GenericService from '@/services/GenericService';
import WebURL from '@/urls';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Box} from "@mui/material";
import { useTranslation } from "react-i18next";


const CollectionRuleList: React.FC = () => {
  const { t } = useTranslation();
	const navigate = useNavigate();
	const columns: GridColDef[] = [
		{field: 'employeeCode', headerName: t("common.Person ID"), flex: 0.2},
		{field: 'firstName', headerName: t("common.First name"), flex: 0.2},
		{field: 'lastName', headerName: t("common.Last name"), flex: 0.2},
		{field: 'clocking_type', headerName: t("common.Clocking type"), flex: 0.6, renderCell: cell => {
      // initialize all clocking type and fill all types
      const clocking_type = {device_verification: false, ...cell.row.clocking_type}
      return (
        <FormControl component='fieldset'>
          <FormGroup row>
            {Object.keys(clocking_type).map((v, i)=>{
              return (
                <FormControlLabel key={i}
                  control={
                    <Checkbox
                      defaultChecked={clocking_type[v] || false}
                      onChange= {event => {
                        const data = {employee_id: cell.row.id, clocking_type}
                        data['clocking_type'][v] = event.target.checked;
                        GenericService.create(ATTEndpoints.ATT_COLLECTION_RULE, data);
                      }}
                    />
                  }
                  label="Device Verification"
                  labelPlacement='end'
                />
              )
            })}
          </FormGroup>
        </FormControl>
      )
    }, renderHeader: (params: GridColumnHeaderParams) => (
      <Box>
        {'Clocking Type'}
        <Box display={'inline'}>
          <Tooltip title="clocking type means person can use which method clocking" placement="top" arrow>
            <HelpOutlineIcon
              sx={{verticalAlign: 'middle', marginLeft: '20px'}}
            />
          </Tooltip>
        </Box>
      </Box>
    )},
		{
			field: 'actions',
			sortable: false,
			headerName: t("common.Action"),
			headerAlign: "center",
			renderCell: cell => (
        <Stack>
          <CustomRowButton insideIcon={RemoveRedEyeOutlined} buttonProps={{
            key: 'views',
            onClick: () => navigate(`${WebURL.ATT_CLOCKING_COLLECTION_RULE}/${cell.row.id}`)
          }}/>
        </Stack>
      )
		},
	]
	return (
		<CustomDataGrid
			uri={ATTEndpoints.ATT_COLLECTION_RULE}
			columns={columns}
			toolbar={{
				title: t("common.Clocking collection rule"),
				breadcrumbs: [
					{breadcrumbName: t("common.Rule"), path: WebURL.ATT_RULE_HOME},
					{breadcrumbName: t("common.Clocking collection rule"), path: WebURL.ATT_CLOCKING_COLLECTION_RULE},
				]
			}}
		/>
	)
}

export default CollectionRuleList;
