import request from "../utils/Request";
import qs from 'qs';

/**
 * Obtain Object list
 * @param endpoint API Uri
 * @param params {current: Page number, pageSize: page Size, ...}
 */
const listProxy = async (endpoint: string, params: Record<string, any>) => {
  const response = await request.authorizedAxios().get(endpoint,
    {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response?.data;
}

/**
 * Create Object
 * @param endpoint API Uri
 * @param data Object Model
 */
const createProxy = async (endpoint: string, data: Record<string, any>) => {
  const response = await request.authorizedAxios().post(endpoint, data);
  return response?.data;
}

/**
 * Update Object
 * @param endpoint API Uri
 * @param key Object ID
 * @param data Object Model
 */
const updateProxy = async (endpoint: string, key: string, data: Record<string, any>) => {
  const response = await request.authorizedAxios().put(`${endpoint}${key}/`, data);
  return response?.data;
}

/**
 * Obtain Object
 * @param endpoint API Uri
 * @param key Object ID
 */
const retrieveProxy = async (endpoint: string, key: string) => {
  const response = await request.authorizedAxios().get(`${endpoint}${key}/`);
  return response?.data;
}

/**
 * Delete Object
 * @param endpoint API Uri
 * @param key Object ID
 */
const destroyProxy = async (endpoint: string, key: string) => {
  const response = await request.authorizedAxios().delete(`${endpoint}${key}/`);
  return response?.data;
}

/**
 * Service Entrance
 */
const GenericService = {
  list: listProxy,
  create: createProxy,
  retrieve: retrieveProxy,
  update: updateProxy,
  destroy: destroyProxy,
}
export default GenericService
