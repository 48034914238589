import React, {useEffect, useImperativeHandle, useState, forwardRef} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {object, TypeOf, z} from "zod";
import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {
  Grid,
  Stack,
  Drawer,
  Box,
  Button,
  MenuItem,
  Select,
  Typography, Avatar
} from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {zodResolver} from "@hookform/resolvers/zod";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import Uploady, {useBatchAddListener} from "@rpldy/uploady";
import UploadPreview from "@rpldy/upload-preview";
import {asUploadButton} from "@rpldy/upload-button";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {ReactComponent as DefaultOrganization} from '@/assets/images/DefaultOrganization.svg';
import UserService from "@/services/auth/UserService";
import {obtainUserInfo} from "@/store/slices/App";
import SnackbarUtil from "@/utils/SnackbarUtil";

const ProfileEdit = (props: any, ref: any) => {
  const {t} = useTranslation();
  const userProfileValidate = object({
    firstName: z.string().min(1, t("common.This field is required!")),
    lastName: z.string().min(1, t("common.This field is required!")),
    email: z.string().min(1, t("common.This field is required!"))
  })

  type UserProfileForm = TypeOf<typeof userProfileValidate>;

  const profileForm = useForm<UserProfileForm>({
    resolver: zodResolver(userProfileValidate)
  });
  const {
    register,
    setValue,
    handleSubmit,
    formState: {errors},
  } = profileForm;
  const [editState, setEditState] = useState<boolean>(true);
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const [imageFile, setImageFile] = useState<any>();
  const name_text = t("organ.name text tip")
  const code_text = t("organ.code text tip")
  const [loading, setLoading] = useState<boolean>(false);
  const [profilePhoto, setProfilePhoto] = useState<string>("");

  const CustomUploadButton = asUploadButton(forwardRef(
    (props: any, ref: any) => {
      useBatchAddListener((batch) => {
        console.log(batch.items[0].file)
        if (batch.items[0].file.size > 3000000) {
          enqueueSnackbar(t("common.Photos size up to maximum 3 MB"), {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }
          });
        }
        setImageFile(batch.items[0].file)
        setProfilePhoto(imageFile)
      });
      return <Button component="label" variant="contained"
                     sx={{width: '90%', textTransform: "none"}} {...props} />
    }
  ));

  const [open, setOpen] = useState(false)
  const onClose = () => {
    setImageFile(undefined);
    profileForm.reset()
    setEditState(false);
    setOpen(false)
  }
  useImperativeHandle(ref, () => ({
    showDetail: () => {
      setEditState(true);
      setOpen(true)
    }
  }));

  const title = t("common.Editor") + ' ' + t("common.Account Profile")

  useEffect(() => {
    UserService.userInfo().then(res => {
      setProfilePhoto(res.data.imagePreSignedURL)
      setImageFile(undefined);
      setValue("firstName", res.data.firstName);
      setValue("lastName", res.data.lastName);
      setValue("email", res.data.email);
    });
  }, [editState])

  const onSubmit = async (formData: any) => {
    setLoading(true);
    if (imageFile) {
      formData['profilePhoto'] = imageFile
    }
    console.log("formData--->", formData);
    await UserService.updateUserInfo(formData).then((response) => {
      setEditState(false)
      dispatch<any>(obtainUserInfo())
      SnackbarUtil.success('User updated successfully.')
      console.log("????", response)
      if (props.reloadData) {
        props.reloadData()
      }
      onClose()
    }).catch((error) => {
      if (error.response?.status < 500) {
        // do something when status is 4xx.
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const MyPreview = (props: any) => {
    return (
      <Card sx={{maxWidth: 345}}>
        <CardMedia
          component="img"
          height="200"
          image={props.url}
          alt="Profile Logo"
        />
      </Card>
    )
  };

  const ImageView = (props: any) => {
    return (
      <Card sx={{maxWidth: 345}}>
        <CardMedia
          component="img"
          height={props.height}
          image={profilePhoto}
          alt="Profile photo"
        />
      </Card>
    )
  };


  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Grid sx={{
        width: '452px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
      }}>
        <Grid sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          padding: '24px',
          borderBottom: '1px solid #DEE0E3',
        }}>
          <Grid>{title}</Grid>
          <Grid>
            <i style={{cursor: "pointer"}} className="icon iconfont icon-ic_close" onClick={onClose}></i>
          </Grid>
        </Grid>
        <Grid sx={{
          padding: '24px 48px 24px 24px',
        }}>
          <Grid sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #AEAFB0',
            width: '100%',
            height: '300px',
            lineHeight: '50px',
            minWidth: '200px',
            marginBottom: '12px'
          }}>
            <Uploady>
              <Box sx={{height: '200px'}}>
                {
                  imageFile ?
                    <UploadPreview
                      PreviewComponent={MyPreview}
                    />
                    :
                    profilePhoto ? <ImageView height="200"/> : <Avatar
                      sx={{width: "150px", height: "150px", marginTop: "20px"}}
                      // src={user?.imagePreSignedURL ? user.imagePreSignedURL : "None"}
                    />
                }
              </Box>
              <Box>
                <Typography sx={{color: "#999999"}}>{t("common.Photos size up to maximum 3 MB")}</Typography>
              </Box>
              <CustomUploadButton>{t("organ.Add photos")}</CustomUploadButton>
            </Uploady>
          </Grid>
          <ControlledTextFiled
            hookForm={profileForm}
            name={'firstName'}
            label={t("signUp.First Name")}
            fullWidth
            disabled={!editState}
            required
          />
          <ControlledTextFiled
            hookForm={profileForm}
            name={'lastName'}
            label={t("signUp.Last Name")}
            fullWidth
            disabled={!editState}
            required
          />
          <ControlledTextFiled
            hookForm={profileForm}
            name={'email'}
            label={t("signUp.Enter the email")}
            fullWidth
            disabled={!editState}
            required
          />
        </Grid>
        <Grid sx={{borderTop: '1px solid #DEE0E3', position: 'absolute', bottom: '12px', left: '0', width: '100%'}}>
          <Grid style={{paddingTop: '12px', paddingLeft: '24px'}}>
            <Stack spacing={3} direction={"row"} justifyContent="start">
              <CancelButton onClick={onClose}/>
              <SavingButton loading={loading} onClick={profileForm.handleSubmit(onSubmit)}/>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}
export default forwardRef(ProfileEdit);
