import React from "react";
import {IconButton, ButtonProps, Tooltip, SvgIcon, Typography, Box} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type CustomRowButtonProps = {
  insideIcon?: SvgIconComponent;
  buttonProps?: ButtonProps;
  insideSvgIcon?: any;
  actionName?: string;
  font?: string
}

function titleUpper(str: any) {
  if (typeof (str) === 'undefined') return ''
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L: any) => L.toUpperCase());
}

const CustomRowButton: React.FC<CustomRowButtonProps> = (props) => {
  const {t} = useTranslation();
  const translatedTitle=props.buttonProps?.key === undefined ? "" : t("common." + props.buttonProps?.key)
  return (
    <Tooltip title={translatedTitle}>
      <IconButton sx={{"&:hover": {backgroundColor: "transparent"}}}
                  {...props.buttonProps}
      >
        {props.insideIcon && <props.insideIcon color={"action"}/>}
        {props.insideSvgIcon && <SvgIcon component={props.insideSvgIcon} sx={{marginTop: '7px'}}/>}
        {props.font && <i className={'icon iconfont ' + props.font} style={{marginRight: '8px'}}></i>}
        {props.actionName && <Typography sx={{fontSize: '14px'}}>{props.actionName}</Typography>}
      </IconButton>
    </Tooltip>
  )
}
export default CustomRowButton
