import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import CompanyService from "@/services/auth/CompanyService";

const initialState = {
  organs: [],
  organ: {}
}

export const getUserOrgans = createAsyncThunk('organ/byUser', async () => {
  const {data} = await CompanyService.companyList({current: 1, pageSize: 80, toggleKeyToURL: true});
  return data;
})

const organSlice = createSlice({
  name: 'organ',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserOrgans.fulfilled, (state, action) => {
      return {
        ...state,
        organs: action.payload.data
      }
    })
  }
});
export default organSlice.reducer
