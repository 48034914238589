import {
  Box,
  Grid,
  Breadcrumbs,
  Typography,
  Drawer,
  InputAdornment,
  IconButton,
} from "@mui/material";
import TextField, {
  TextFieldProps as MuiTextFieldPropsType,
} from "@mui/material/TextField";
import { GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SaveButton } from "@/components/data-grid/CustomButton";
import CustomDataGrid from "@/components/data-grid/CustomDataGrid";
import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ATTEndpoints } from "@/services/att/endpoints";

export type TimesheetPickerProps = {
  // multi?: boolean;
  renderInput?: (props: MuiTextFieldPropsType) => React.ReactElement;
} & MuiTextFieldPropsType;

const customDataGridStyle = {
  boxShadow: 'none !important',
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F0F1F4!important",
    color: "#304669!important",
  },
  ".MuiDataGrid-columnHeader:focus-within": { outline: "none" },
  ".MuiDataGrid-cell": { borderBottom: "0px", color: "#304669" },
  ".MuiDataGrid-cell:focus-within": {
    outline: "none!important",
    color: "#2BA9DE!important",
  },
  ".MuiDataGrid-columnSeparator": { display: "none !important" },
  ".MuiSvgIcon-root": { backgroundColor: "transparent" },
  ".MuiDataGrid-root": { padding: "8px", borderRadius: "4px" },
  ".MuiDataGrid-row": {
    borderBottom: "1px solid #E7EBF0",
    maxHeight: "43px!important",
    minHeight: "43px!important",
  },
  ".MuiCheckbox-root": { color: "#D1D1D1" },
  ".MuiDataGrid-cellContent": {
    color: "#304669!important",
    fontFamily: "Roboto",
    letterSpacing: "0.4px",
    fontSize: "14px",
  },
  ".MuiDataGrid-virtualScrollerRenderZone": { backgroundColor: "#FFFF" },
  ".MuiDataGrid-footerContainer": {
    display: "block",
    width: "100%",
    border: "0px",
  },
  ".MuiTablePagination-spacer": { display: "none" },
  ".MuiTablePagination-actions": {
    position: "absolute",
    right: "0px",
    backgroundColor: "#FFF",
  },
  ".MuiTablePagination-displayedRows": { position: "absolute", right: "90px" },
  ".MuiDataGrid-virtualScroller": { overflow: "auto !important" },
  ".MuiTablePagination-select": {
    backgroundColor: "#F0F1F4",
    borderRadius: "4px!important",
  },
  ".MuiTablePagination-toolbar": { paddingLeft: "0px" },
  ".MuiIconButton-root.Mui-disabled": {
    backgroundColor: "#F0F1F4",
    borderRadius: "0px",
  },
  // '.MuiIconButton-root': {borderRadius: '0px'},
  ".Mui-checked": { color: "rgb(54, 201, 109)!important" },
  ".MuiDataGrid-virtualScrollerContent": {
    // height: "calc(100vh - 400px) !important",
  },
};

function TimesheetPicker(props: TimesheetPickerProps) {
  const { t } = useTranslation();
  const [gridData, setGridData] = useState([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [selectionData, setSelectionData] = useState<Record<string, any>>([]);
  const [open, setOpen] = React.useState(false);
  const { renderInput, value, onChange, ...textFieldProps } = props;
  const multi = false;
  const valueObj = value as Record<string, any>;
  const valueObjs = value as Record<string, any>[];
  let displayValue = "";
  if (multi) {
    const emps = valueObjs.map((emp: any) => `${emp?.name}`);
    displayValue = emps.join(",");
  } else {
    displayValue = valueObj ? `${valueObj?.name}` : "";
  }
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: t("common.Timesheet Name"),
      minWidth: 170,
      flex: 0.3,
    },
    { field: "period", headerName: t("common.Unit"), minWidth: 90, flex: 0.1 },
    { field: "cycle", headerName: t("common.Cycle"), minWidth: 90, flex: 0.1 },
    {
      field: "description",
      headerName: t("common.Description"),
      type: "string",
      minWidth: 150,
      flex: 0.5,
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (value: any) => {
    setOpen(false);
    if (onChange) {
      if (multi) {
        onChange(value);
      } else {
        onChange(value[0]);
      }
    }
  };

  const handleDataGridSave = (value: any) => {
    if (value) {
      setGridData(value);
    }
  };

  return (
    <>
      {props.renderInput ? (
        <props.renderInput
          onClick={() => setOpen(true)}
          value={displayValue}
          {...textFieldProps}
        />
      ) : (
        <TextField
          onClick={() => setOpen(true)}
          sx={{ width: "100%" }}
          value={displayValue}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton sx={{ marginRight: "-10px" }}>
                  <KeyboardArrowRightIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...textFieldProps}
        />
      )}
      <Drawer anchor={"right"} open={open} onClose={handleClose}>
        <Grid
          sx={{
            minWidth: "500px",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            position: "relative",
            boxSizing: 'border-box'
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "56px",
              padding: "0px 24px",
              borderBottom: "1px solid #F2F5F8",
            }}
          >
            <Grid>{t("common.Select timesheet")}</Grid>
            <Grid>
              <i
                style={{ cursor: "pointer" }}
                className="icon iconfont icon-ic_close"
                onClick={handleClose}
              ></i>
            </Grid>
          </Grid>
          <Box sx={{ padding: "0 0 24px 8px", height: 'calc(100% - 130px)'}}>
            <CustomDataGrid
              height="calc(100vh - 100px)"
              tableStyle={{
                boxShadow: 'none !important',
                paddingRight: '0'
              }}
              uri={ATTEndpoints.ATT_TIMESHEET_URI}
              columns={columns}
              checkboxSelection={multi}
              onDataChange={handleDataGridSave}
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
                setSelectionData(
                  ids.map((id) => gridData.find((row: any) => row.id === id))
                );
              }}
              selectionModel={selectionModel}
              toolbar={{
                title: "",
                filter: false,
                refresh: false,
              }}
            />
          </Box>
          <Grid
            sx={{
              borderTop: "1px solid #F2F5F8",
              width: "100%",
              height: '70px'
            }}
          >
            <Grid style={{ paddingTop: "12px", paddingLeft: "24px" }}>
              <SaveButton
                children={t("common.confirm")}
                sx={{ width: "160px", textTransform: "none" }}
                onClick={() => {
                  handleSave(selectionData);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}

export default TimesheetPicker;
