import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { ReactComponent as DeviceBound } from "@/assets/device/DeviceBound.svg";
import { ReactComponent as DeviceNetwork } from "@/assets/device/DeviceNetwork.svg";
import { ReactComponent as DevicePower } from "@/assets/device/DevicePower.svg";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

type DeviceGuideProps = {
  setStep: Dispatch<SetStateAction<string>>;
};

const StepDesc: React.FC<{
  title: string;
  description: string;
  subTitle: string;
  picture: React.ReactNode;
}> = (props) => {
  return (
    <Grid
      sx={{
        height: "240px",
        boxSizing: "border-box",
        borderRadius: "3px",
        marginTop: "24px",
        padding: "24px",
        minWidth: "500px",
        border: "1px solid #DEE0E3",
      }}
    >
      <Grid
        sx={{
          height: "32px",
          fontSize: "32px",
          fontWeight: "bold",
          color: "#2BA9DE",
        }}
      >
        {props.title}
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            maxWidth: "320px",
            fontSize: "14px",
            lineHeight: "20px",
            color: " #666666",
          }}
        >
          {props.description}
        </Grid>
        <Grid
          sx={{
            marginLeft: "55px",
          }}
        >
          {props.picture}
        </Grid>
      </Grid>
      <Grid
        sx={{
          fontSize: "14px",
          color: "#3D3D3D",
          fontWeight: "bold",
        }}
      >
        {props.subTitle}
      </Grid>
      {/* <Box fontSize={"18px"} height={"64px"}>
        <Typography>{props.title}</Typography>
      </Box>
      <Box fontSize={"16px"} color={"gray"}>
        <Typography>{props.description}</Typography>
      </Box>
      <Box textAlign={"center"}>{props.picture}</Box> */}
    </Grid>
  );
};

const DeviceConnGuide: React.FC<DeviceGuideProps> = (props) => {
  const { t } = useTranslation();
  const [isContinue, setContinue] = useState<boolean>(false);
  return (
    <Grid
      sx={{
        flexGrow: 1,
        position: "relative",
      }}
    >
      <Grid
        sx={{
          marginBottom: "66px",
        }}
      >
        <StepDesc
          title={t("dms.step1Title")}
          subTitle={t("dms.step1SubTitle")}
          description={t("dms.step1Desc")}
          picture={<DevicePower />}
        />

        <StepDesc
          title={t("dms.step2Title")}
          subTitle={t("dms.step2SubTitle")}
          description={t("dms.step2Desc")}
          picture={<DeviceNetwork />}
        />

        <StepDesc
          title={t("dms.step3Title")}
          subTitle={t("dms.step3SubTitle")}
          description={t("dms.step3Desc")}
          picture={<DeviceBound />}
        />

        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) => {
                setContinue(event.target.checked);
              }}
            />
          }
          label={t("dms.I have got this done")}
        />
      </Grid>

      <Grid
        sx={{
          height: "66px",
          position: "absolute",
          bottom: "0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <LoadingButton
          variant="contained"
          disabled={!isContinue}
          sx={{ width: "15vw", height:'42px', textTransform: "capitalize" }}
          onClick={() => {
            props.setStep("register");
          }}
        >
          {t("common.continue")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default DeviceConnGuide;
