import request from "../../../utils/Request";
import { ATTEndpoints } from "../endpoints";
import qs from "qs";

const list = async (params?: Record<string, any>) => {
  const response = await request.authorizedAxios().get(ATTEndpoints.ATT_PUNCH_RECORD_URI, {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response?.data;
}

const addPunchRecord = async (data: any) => {
  const response = await request.authorizedAxios().post(ATTEndpoints.ATT_PUNCH_RECORD_ADD_URI, data)
  return response?.data;
};


const PunchRecordService = {
  list,
  addPunchRecord,
}

export default PunchRecordService;
