import request from "../../utils/Request";
import {HREndpoints} from "./endpoints";

const destroy = async (id: string) => {
  const response = await request.authorizedAxios().delete(`${HREndpoints.HR_RESIGNATNION_URI}${id}/`);
  return response;
}

const resume = async (id: string, data?: any) => {
  const response = await request.authorizedAxios().put(`${HREndpoints.HR_RESIGNATNION_URI}${id}/resume/`, data);
  return response;
}

const ResignationService = {
  destroy,
  resume,
}
export default ResignationService;