import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Header from "../Header";
import SideBar from "../SideBar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getInitialState } from "../../store/slices/App";
import jwtDecode from "jwt-decode";
import WebURL from "../../urls";

type MainLayoutProps = {
  children?: React.ReactNode;
};
type TokenProps = {
  exp: number;
  companyId?: string;
};

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    const jwtToken = localStorage.getItem("accessToken");
    if (jwtToken) {
      const decodedToken = jwtDecode<TokenProps>(jwtToken);
      console.log("decodedToken >>>", decodedToken);
      const curStamp = Date.now() / 1000;
      if (curStamp > decodedToken.exp) {
        //Token expired
        console.log("decodedToken >>> expired");
        navigate(WebURL.AUTH_USER_LOGIN);
      }
      if (!decodedToken.companyId) {
        navigate(WebURL.AUTH_USER_LOGIN);
      }
    } else {
      navigate(WebURL.AUTH_USER_LOGIN);
    }
  }, []);
  return (
    <Grid style={{ backgroundColor: "#f7f6fd", width: "100%", height: "100%" }}>
      <Grid
        style={{
          height: '100vh',
          display: "flex",
          flexDirection: "row",
          background: "#f7f6fd",
        }}
      >
        <SideBar />
        <Grid
          container
          style={{
            height: "100%",
            overflow: "auto",
            marginLeft:'16px',
            display: "block",
          }}
        >
          <Header />
          <Outlet />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default MainLayout;
