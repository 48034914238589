import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  Typography,
} from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { KeyboardArrowRight, Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LanguageMenuItem from "@/parts/Header/components/LanguageMenuItem";
import SupportMenuItem from "@/parts/Header/components/SupportMenuItem";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import { createTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Languages, LanguagesTitle } from "@/utils/Languages";
import i18n from "@/config/i18n";

type MenuItemProps = {
  hidePopup?: () => void;
};

const ChangeLanguage: React.FC = () => {
  const user = useSelector((state: any) => state.app.user);
  const currentLng = i18n.language;
  console.log({ user });
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: "popover",
    popupId: "ChangeLanguage",
  });
  return (
    <Box>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          height: "32",
          backgroundColor: "#ffffff",
          borderRadius: "21px",
          padding:'0px 8px'
        }}
        {...bindTrigger(popupState)}
      >
        <Grid
          sx={{
            width: "20px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        >
          <i className="icon iconfont icon-ic_language"></i>
        </Grid>

        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "14px !important"
          }}
          padding={"4px"}
        >
          {LanguagesTitle[currentLng]}
        </Typography>

        <ArrowDropDownIcon
          sx={{
            fontSize: { xs: "14px", lg: "28px" },
            margin: { xs: "2px", lg: "2" },
          }}
        ></ArrowDropDownIcon>
      </Grid>
      <Menu
        sx={{ mt: "45px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        {...bindMenu(popupState)}
      >
        {Languages.map((item: any) => {
          return (
            <MenuItem
              onClick={async () => {
                localStorage.setItem("i18nextLng", item.code);
                await i18n.changeLanguage(item.code);
                popupState.close()
              }}
            >
              <ListItemText>{item.title}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
export default ChangeLanguage;
