import {configureStore} from "@reduxjs/toolkit";
import appReducer from "./slices/App";
import organReducer from "./slices/Organization";
import siteReducer from "./slices/Site";
import zoneReducer from "./slices/Zone";
import deptSlice from "./slices/Department"
import employeeSlice from "./slices/Employee"
import deviceSlice from "./slices/Device"
import breadcrumbs from "./slices/Breadcrumbs";

const reducer = {
  app: appReducer,
  organ: organReducer,
  site: siteReducer,
  zone: zoneReducer,
  dept: deptSlice,
  emp: employeeSlice,
  device: deviceSlice,
  breadcrumbs:breadcrumbs
}

const store = configureStore({
  reducer,
  devTools: true
});
export default store;
