import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Grid,
  MenuItem,
  Tooltip,
} from "@mui/material";
import CompanyProfile from "./components/CompanyProfile";
import UserProfile from "./components/UserProfile";
import ProductLogo from "./components/ProductLogo";
import { getInitialState } from "../../store/slices/App";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Dialog from "@/components/dialog";
import { useTranslation } from "react-i18next";
import Announcement from "./components/Announcement";
import HelpInfo from "@/parts/Header/components/HelpInfo";
import ChangeLanguage from "./components/ChangeLanguage";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

type MenuItemProps = {
  hidePopup?: () => void;
};

const LogoutMenuItem: React.FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onLogout = () => {
    Dialog.confirm({
      title: t("common.Logout"),
      content: t("common.Are you sure to logout"),
      onConfirm: () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        navigate("/user/login");
      },
    });
  };
  return (
    <Tooltip title={t("common.Logout")}>
      <Grid
        sx={{
          margin: "0px 10px",
          backgroundColor: "#fff",
          padding: "5px 15px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "30px",
        }}
        onClick={onLogout}
      >
        <i
          style={{
            fontSize: "14px",
          }}
          className="icon iconfont icon-ic_logout"
        ></i>
      </Grid>
    </Tooltip>
  );
};

const Header: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getInitialState());
  }, []);

  const breadcrumbsList = useSelector(
    (state: any) => state.breadcrumbs.breadcrumbs
  );

  return (
    <AppBar sx={{ height: "70px" }} position={"static"}>
      <Container
        maxWidth="xl"
        style={{
          height: "100%",
          padding: "0px",
          margin: "0px",
          minWidth: "100%",
        }}
      >
        <Toolbar sx={{ height: "100%" }} disableGutters>
          <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbsList?.map((item: any) => {
              if (item.path) {
                return (
                  <Link sx={{
                    fontSize:'16px'
                  }} underline="hover" color="inherit" href={item.path}>
                    {item.label}
                  </Link>
                );
              } else {
                return (
                  <Typography sx={{
                    color: "text.primary",
                    fontSize:'18px'
                   }}>
                    {item.label}
                  </Typography>
                );
              }
            })}
          </Breadcrumbs>

          <Box sx={{ flexGrow: 1 }} />
          <Box display="flex">
            <Announcement />
          </Box>

          <Box display="flex">
            <HelpInfo />
          </Box>

          <Box
            sx={{
              margin: "0px 10px",
            }}
          >
            <ChangeLanguage></ChangeLanguage>
          </Box>

          <Box display="flex">
            <UserProfile />
            <LogoutMenuItem />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
