import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
interface ActionProps {
  handleClick?: () => void;
  icon?: string;
  title?: string;
  children?: any;
  iconC?: any;
}

const ActionButton = (props: ActionProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    props.handleClick && props?.handleClick();
  };
  return (
    <Grid
      sx={{
        cursor: "pointer",
        marginLeft: "8px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{
          cursor: "pointer",
          marginLeft: "8px",
          display: "flex",
          alignItems: "center",
          color:'#00000099'
        }}
        onClick={handleClick}
      >
        <i className={"icon iconfont " + props.icon}></i>
        {props.iconC}
        <span
          style={{
            fontSize: "14px",
            color: "#75828A",
            marginLeft: "4px",
            cursor: "pointer",
          }}
        >
          {props.title}
        </span>
      </Grid>
    </Grid>
  );
};
export default ActionButton;
