import DeviceService from "@/services/dms/DeviceService";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { object, TypeOf, z } from "zod";
import DeviceBinding from "./DeviceBinding";
import { styled } from '@mui/material/styles';

type DeviceRegisterProps = {
  setStep: Dispatch<SetStateAction<string>>
  setDeviceInfo:(info:any) => void;
}


const TypographyCustom = styled(Typography)(({theme}) => ({
  marginBottom: '16px !important',
  fontSize: '14px !important',
  color:'#666666'
}));

const DeviceRegister: React.FC<DeviceRegisterProps> = (props) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [device, setDevice] = useState<{deviceId?: string, sn?: string}>();
  const checkDeviceSchema = object({
    sn: z.string().min(1, t("common.This field is required!"))
  })
  type checkInput = TypeOf<typeof checkDeviceSchema>;
  const checkForm = useForm<checkInput>({
    resolver: zodResolver(checkDeviceSchema)
  });

  const {onChange,...orther } =  checkForm.register("sn")
  return (
    <>
      <Stack sx={{ marginRight: "20px" }} spacing={1}>

        <Box>
          <Typography sx={{
          marginTop: '20px !important',
          fontSize:'16px  !important',
          fontWeight: 'bold  !important'
        }}>{t("dms.tip04")}</Typography>
        </Box>

        <Box >
          <Typography  sx={{
          fontSize:'14px  !important',
          fontWeight: '400  !important',
          color:'#333333'
        }}>{t("dms.tip05")}</Typography>
        </Box>

        <Box sx={{
          maxWidth:'520px'
        }} fontSize={"14px"} lineHeight={"30px"} color={"gray"}>
          <Stack>
            <TypographyCustom >{t("dms.tip06")}</TypographyCustom>
            <TypographyCustom >{t("dms.tip07")}</TypographyCustom>
            <TypographyCustom >{t("dms.tip08")}</TypographyCustom>
            <TypographyCustom >{t("dms.tip09")}</TypographyCustom>
          </Stack>
        </Box>


        <Box component={"form"}>
          <Grid container>
            <Typography sx={{
               color:'#333333'
            }}>{t("dms.Enter device SN")}</Typography>
            <Grid xs={12} item style={{ display: "flex",marginTop:'8px'}}>
            <TextField
                required
                autoFocus
                variant="outlined"
                size={"small"}
                fullWidth
                error={!!checkForm.formState.errors['sn']}
                helperText={checkForm.formState.errors['sn']?.message || ""}
                {...orther}
                onChange={(e) => {
                  checkForm.setValue('sn',e.target.value)
                  setDevice({});
                }}
              />

              <LoadingButton

                loading={loading}
                disableElevation
                sx={{
                  width: '10vw',
                  marginLeft: '12px',
                  backgroundColor:'#2BA9DE',
                  textTransform: "capitalize"
                }}
                variant="contained"
                onClick={ checkForm.handleSubmit(async () => {
                  setLoading(true)
                  const isValid = await checkForm.trigger();
                  try {
                    if (isValid) {
                      const { sn } = checkForm.getValues();
                      const res = await DeviceService.deviceVerify(sn);
                      if (res.data.deviceId) {
                        setDevice({deviceId: res.data.deviceId, sn});
                      }
                    }
                  } catch (e) {
                    console.log(e);
                  } finally {
                    setLoading(false);
                  }
                })
              }
              >
                {t('common.verify sn')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Stack>



      <Grid
        sx={{
          height: "66px",
          position: "absolute",
          bottom: "0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <LoadingButton
          variant="contained"
          disabled={!device?.deviceId}
          sx={{ width: "15vw", height:'42px', textTransform: "capitalize" }}
          onClick={() => {
            props.setStep("DeviceBinding");
            props.setDeviceInfo(device)
          }}
        >
          {t("common.continue")}
        </LoadingButton>
      </Grid>


      {/* <DeviceBinding visible={visible} device={device} onClose={() => setVisible(false)} /> */}


    </>
  )
}

export default DeviceRegister;
