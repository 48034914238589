import React from "react";
import { Avatar } from "@mui/material";



type AvatarProps = {
    name: string,
    diameter?: number,
    profilePhoto?: string,
    style?: any,
}

const checkRequest = (value: string) => {
    const regHttp = /^((https|http)?:\/\/)[^\s]+/;
    return regHttp.test(value)
}

const ColorByName = (name: string) => {
    let hash = 0;
    for (let i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

const AvatarIcon = (props: AvatarProps) => {
    const fontSize = `${((props?.diameter || 40) / 40) * 1.25}rem`;
    return (
        props?.profilePhoto ?
        <Avatar
            src={checkRequest(props.profilePhoto) ?
                props.profilePhoto
                : `data:image/jpeg;base64,${props.profilePhoto}`}
            sx={{
                width: props?.diameter,
                height: props?.diameter
            }}
        />
        :
        <Avatar
            sx={{
                bgcolor: ColorByName(props.name || '-'),
                width: props?.diameter,
                height: props?.diameter,
                fontSize,
            }}
            children={`${props.name ? props.name.toUpperCase()[0]: " "}`}
            {...props}
        />
    )
}

export default AvatarIcon;
