import React from "react";
import Box from "@mui/material/Box";
import {KeyboardArrowDown} from "@mui/icons-material";
import {IconButton, Menu, MenuItem, Typography, ListItemText} from "@mui/material";
import {bindMenu, bindTrigger, usePopupState} from "material-ui-popup-state/hooks";
import {Grid} from "@material-ui/core";
import i18n from "@/config/i18n";
import _ from 'lodash'
import { Languages, LanguagesTitle } from "@/utils/Languages";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const LanguageSelect: React.FC = () => {
  const popupState = usePopupState({variant: "popover", popupId: "language"});
  const languages = Languages
  const language = _.find(languages, (item) => { return item.code === i18n.language})?.title
  const currentLng = i18n.language;
  return (
    <Box sx={{display: "flex", justifyContent: 'end', padding: '16px 16px 0 0'}}>
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          height: "32",
          backgroundColor: "#ffffff",
          borderRadius: "21px",
          padding: '0px 8px',
          marginLeft: "auto"
        }}
        {...bindTrigger(popupState)}
      >
        <Grid
          style={{
            width: "20px",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        >
          <i className="icon iconfont icon-ic_language"></i>
        </Grid>

        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "14px !important"
          }}
          padding={"4px"}
        >
          {LanguagesTitle[currentLng]}
        </Typography>

        <ArrowDropDownIcon
          sx={{
            fontSize: { xs: "14px", lg: "28px" },
            margin: { xs: "2px", lg: "2" },
          }}
        ></ArrowDropDownIcon>
      </Grid>
      <Menu
        sx={{ mt: '45px' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted={true}
        {...bindMenu(popupState)}
      >
        {languages.map((item) => (
          <MenuItem key={item.code} onClick={async () => {
            localStorage.setItem("i18nextLng", item.code);
            await i18n.changeLanguage(item.code);
            popupState.close()
          }}>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
export default LanguageSelect
