import request from "../../../utils/Request";
import {ATTEndpoints} from "../endpoints";

const addSchedule = async (data: any) => {
  const response = await request.authorizedAxios().post(ATTEndpoints.ATT_SCHEDULE_URI, data)
  return response?.data;
};

const retrieve = async (id: string) => {
  const response = await request.authorizedAxios().get(`${ATTEndpoints.ATT_SCHEDULE_URI}${id}/`);
  return response?.data;
}

const ScheduleService = {
  addSchedule,
  retrieve,
}
export default ScheduleService
