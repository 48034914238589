import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {ReactComponent as NoRows} from '@/assets/icons/noRows.svg';



const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  paddingTop: "5px",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

export default function CustomNoRowsGrid() {
  const { t } = useTranslation();
  return (
    <StyledGridOverlay>
      <NoRows></NoRows>
      <Box sx={{
        mt: 1,
        color: "#aeafb0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:'column'
      }}>
        <Box>
           {t("common.No Rows")}
        </Box>
      </Box>
    </StyledGridOverlay>
  );
}
