import React from "react";
import {TextField} from "@mui/material";
import {Controller, UseFormReturn} from "react-hook-form";
import {InputProps as StandardInputProps} from "@mui/material/Input/Input";

type ControlTextFieldType = {
  label: string;
  name: string;
  form: UseFormReturn<any>;
  required?: boolean;
  InputProps?: Partial<StandardInputProps>
}

const ControlTextField: React.FC<ControlTextFieldType> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.form.control}
      render={({field, fieldState}) => (
        <TextField
          fullWidth
          required={props.required}
          label={props.label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message || " "}
          {...field}
          InputLabelProps={{
            shrink: !!field.value
          }}
          InputProps={{
            ...props.InputProps
          }}
        />)}
    />
  )
}
export default ControlTextField
