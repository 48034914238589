import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  Grid,
  Breadcrumbs,
  Typography,
  CardMedia,
  Card,
} from "@mui/material";
import DefaultOrganizationSquare from "@/assets/images/DefaultOrganizationSquare.svg";
import { useEffect, useState } from "react";
import CompanyService from "@/services/auth/CompanyService";
import { useNavigate } from "react-router-dom";
import GenericService from "@/services/GenericService";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { AuthEndpoints } from "@/services/auth/endpoints";
import { useDispatch } from "react-redux";
import { obtainCompanyInfo } from "@/store/slices/App";
import WebURL from "@/urls";
import SnackbarUtil from "@/utils/SnackbarUtil";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ContentCut from "@mui/icons-material/ContentCut";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ContentPaste from "@mui/icons-material/ContentPaste";
import Cloud from "@mui/icons-material/Cloud";
import Avatar from "@mui/material/Avatar";
type GridData = {
  data: any;
  total: number;
};

const OrganSwitch: React.FC<any> = (props: any) => {
  const data = props?.gridData?.data || [];
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<string>("");
  const dispatch = useDispatch();
  const native = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    console.log(123);
  };

  const onConfirmSelected = () => {
    if (selectedItem) {
      CompanyService.selectCompany({ company_id: selectedItem })
        .then((response) => {
          localStorage.setItem("accessToken", response?.data.access);
          localStorage.setItem("refreshToken", response?.data.refresh);
          dispatch<any>(obtainCompanyInfo());
          native(WebURL.DASHBOARD);
        })
        .catch((error) => {
          if (error.response?.status < 500) {
            // do something when status is 4xx.
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      SnackbarUtil.error(t("common.No organization selected."), {
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  const handleClickItem = (item: any) => {
    props.hideCmpMenu()
    CompanyService.selectCompany({ company_id: item.id })
    .then((response) => {
      localStorage.setItem("accessToken", response?.data.access);
      localStorage.setItem("refreshToken", response?.data.refresh);
      dispatch<any>(obtainCompanyInfo());
      window.location.reload()
    })
    .catch((error) => {
      if (error.response?.status < 500) {
        // do something when status is 4xx.
      }
    })
    .finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      {data?.map((item: any) => {
        return (
          <MenuItem onClick={() => {
            handleClickItem(item)
          }}>
            <ListItemIcon>
              {item.company_logo ? (
                <Avatar alt="Avatar" src={item.company_logo} />
              ) : (
                <Avatar alt="Avatar" src={DefaultOrganizationSquare} />
              )}
            </ListItemIcon>

            <Grid sx={{
              marginLeft: '15px',
              marginRight:'10px'
            }}>
              <Typography sx={{
                fontSize: '14px !important',
                fontWeight: 'bold !important',
                color:'#3D3D3D !important'
              }}>{item.name}</Typography>
              <Typography
               sx={{
                fontSize: '14px !important',
                color:'color: #999999; !important'
                }}
              >{item.code}</Typography>
            </Grid>
          </MenuItem>
        );
      })}
    </>
  );
};
export default OrganSwitch;
