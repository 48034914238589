import * as React from 'react'
import {Alert, Box, DrawerProps, Grid, IconButton, TextField, Tooltip, Typography, SvgIcon} from '@mui/material'
import CustomDrawer from '@/components/drawer/CustomDrawer'
import {LoadingButton} from "@mui/lab";
import {DatePicker} from "@mui/x-date-pickers";
import {useState} from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SnackbarUtil from "@/utils/SnackbarUtil";
import {Drawer} from "@mui/material";
import {useTranslation} from "react-i18next";
import DeviceService from "@/services/dms/DeviceService";
import {useSnackbar} from "notistack";
import {ReactComponent as IconUploadTransactions} from '@/assets/icons/IconUploadTransactions.svg';

type DevicePersonDrawerProps = DrawerProps & {
  IDs?: any,
}

const TimeSelectDrawer: React.FC<DevicePersonDrawerProps> = ({IDs, ...props}) => {
  const today = new Date()
  const [start_date, setStartDate] = useState<Date>(new Date(today.getFullYear(), today.getMonth(), 1));
  const [end_date, setEndDate] = useState<Date>(new Date());
  const [visible, setVisible] = React.useState(false);
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const {enqueueSnackbar} = useSnackbar()

  const UploadTransactions = async () => {
    setLoading(true)
    await DeviceService.uploadTransactions({
      "start_date": new Date(start_date).toISOString().slice(0, 10),
      "end_date": new Date(end_date).toISOString().slice(0, 10)
    }).then((result) => {
      if (result.status === 205) {
        enqueueSnackbar(t("common.Records being pulled"), {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        })
      } else if (result.status < 400) {
        enqueueSnackbar(t("common.Transactions uploaded successfully"), {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
      }
      setVisible(false)
      setLoading(false)
    })
  };

  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (IDs === undefined) {
          setVisible(open);
        } else {
          if (IDs.length === 0) {
            SnackbarUtil.error(t("att.schedule.Select person first"), {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            })
            return;
          }
          setVisible(open);
        }
      };
  return (
    <div>
      <React.Fragment>
        <Grid onClick={toggleDrawer(true)} sx={{marginRight: '-15px'}}>
          <Tooltip title={t("dms.Upload Transactions")}>
            {/* <IconButton sx={{ borderRadius: '6px', backgroundColor: "rgba(0, 0, 0, 0.04)" }}>
            <CloudUploadIcon/>
          </IconButton> */}
            <IconButton sx={{"&:hover": {backgroundColor: "transparent"}}}>
              <SvgIcon component={IconUploadTransactions}
                       sx={{marginTop: '5px', cursor: 'pointer', color: 'rgb(117, 130, 138)'}}/>
              <span style={{
                fontSize: '14px',
                color: 'rgb(117, 130, 138)',
                cursor: "pointer",
                marginLeft: '-4px',
                marginTop: '-2px'
              }}>{t("dms.Upload Transactions")}</span>
            </IconButton>
          </Tooltip>
        </Grid>
        <Drawer
          anchor={'right'}
          open={visible}
          onClose={toggleDrawer(false)}
        >
          <Box sx={{height: '100%', width: '600px', position: 'relative', boxSizing: 'border-box'}}>
            <Grid sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '56px',
              padding: '0px 24px',
              borderBottom: '1px solid #F2F5F8',
            }}>
              <Grid><Typography sx={{fontSize: '20px'}}>{t("dms.Upload Transactions")}</Typography></Grid>
              <Grid>
                <i style={{cursor: "pointer"}} className="icon iconfont icon-ic_close"
                   onClick={toggleDrawer(false)}></i>
              </Grid>
            </Grid>
            <Box sx={{padding: '24px', width: '100%', boxSizing: 'border-box'}}>
              <Box sx={{display: 'flex'}}>
                <Grid>
                  <Typography sx={{'p': '0 0 12px'}}>{t("dms.uploadTransactionConfirm")}</Typography>
                </Grid>
              </Box>
              <Grid container sx={{marginBottom: '8px'}}>
                <Grid item>
                  <Box sx={{marginBottom: '5px'}}>
                    {t("common.From")}
                  </Box>
                  <DatePicker
                    label=""
                    onChange={
                      (newValue: any) => {
                        if (newValue === null) {
                          SnackbarUtil.error(t("common.Please Select Start Date"), {
                            anchorOrigin: {
                              vertical: 'top',
                              horizontal: 'center'
                            }
                          })
                          setStartDate(new Date())
                          return;
                        } else {
                          setStartDate(newValue)
                        }
                      }
                    }
                    value={start_date}
                    disableFuture
                    inputFormat={"dd-MM-yyyy"}
                    renderInput={(params: any) => <TextField sx={{width: '420px'}} {...params} />}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{marginBottom: '8px'}}>
                <Grid item>
                  <Box sx={{marginBottom: '5px'}}>
                    {t("common.To")}
                  </Box>
                  <DatePicker
                    label=""
                    onChange={(newValue: any) => {
                      if (newValue === null) {
                        SnackbarUtil.error(t("common.Please Select End Date"), {
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                          }
                        })
                        setEndDate(new Date())
                        return;
                      } else {
                        setEndDate(newValue)
                      }
                    }}
                    value={end_date}
                    inputFormat={"dd-MM-yyyy"}
                    disableFuture
                    renderInput={(params: any) => <TextField sx={{width: '420px'}} {...params} />}
                  />
                </Grid>
              </Grid>
              <Grid container sx={{marginTop: '12px'}}>
                <Grid lg={12} item
                      sx={{display: "flex", justifyContent: "center", flexDirection: 'row', width: '100%'}}>
                  <i className="icon iconfont icon-ic_about" style={{marginRight: '6px', marginTop: '3px'}}></i>
                  <Typography sx={{'p': '0 0 12px', color: "#999999"}}>{t("dms.uploadTransactionTip")}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%',}}>
              <Box style={{paddingTop: '12px', width: '160px', paddingLeft: '24px'}}>
                <LoadingButton
                  onClick={() => {
                    if (start_date.toString() == "Invalid Date" || end_date.toString() == "Invalid Date") {
                      SnackbarUtil.error(t("common.Invalid Date input"), {
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'center'
                        }
                      })
                    } else if (start_date > end_date) {
                      SnackbarUtil.error(t("common.Invalid Date time Range"), {
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'center'
                        }
                      })
                    } else if (start_date > new Date() || end_date > new Date()) {
                      SnackbarUtil.error(t("common.Please Don't Select Future Time"), {
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'center'
                        }
                      })
                    } else {
                      UploadTransactions()
                    }
                  }}
                  loading={isLoading}
                  sx={{width: "100%", padding: "10px", textTransform: "capitalize"}}
                  variant="contained">
                  {t("common.confirm")}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>)
}
export default TimeSelectDrawer
