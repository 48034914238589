import {Box, Grid, Link, MenuItem, Select, Stack, Typography} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {useTranslation} from "react-i18next";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import StepLabel from "@mui/material/StepLabel";
import {HREndpoints} from "@/services/hr/endpoints";
import WebURL from "@/urls";
import CustomDataGrid, {
  DataGridRef,
} from "@/components/data-grid/CustomDataGrid";
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {DMSEndpoints} from "@/services/dms/endpoints";
import {ATTEndpoints} from "@/services/att/endpoints";
import {object, promise, string, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {ngteco_timezones} from "@/components/resource/timezone";
import GenericService from "@/services/GenericService";
import {LocationEndpoints} from "@/services/location/endpoints";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import DeviceRegister from "@/pages/quick/components/device/DeviceRegister";
import Sync2Device from "@/pages/quick/components/device/Sync2Device";
import EmpRemove from "@/pages/quick/components/device/RemoveDeviceEmp";
import DepartmentDelete from "@/pages/quick/components/department/DepartmentDelete";
import EmpDelete from "@/pages/quick/components/employee/EmployeeDelete";
import TimeSheetAdd from "@/pages/quick/components/timesheet/TimeSheetAdd";
import ScheduleAdd from "@/pages/quick/components/schedule/ScheduleAdd";
import {getOrganSites} from "@/store/slices/Site";
import {DeviceListButtonDrawer} from "@/pages/att/Schedule/components/drawers/DeviceListDrawer";
import ScheduleDelete from "@/pages/quick/components/schedule/ScheduleDelete";
import TimesheetDelete from "@/pages/quick/components/timesheet/TimesheetDelete";
import TimesheetEdit from "@/pages/quick/components/timesheet/TimesheetEdit";
import ScheduleEdit from "@/pages/quick/components/schedule/ScheduleEdit";
import {getOrganDevice} from "@/store/slices/Device";
import {StepIconProps} from "@mui/material/StepIcon";
import CheckIcon from "@mui/icons-material/Check";
import img_quick_start_bg from "@/assets/quick_start/img_quick_start_bg.png";
import {
  CustomTypography,
  ColorlibStepIconRoot,
  BgBox,
} from "./components/commonCompents";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import DepartmentView from "@/pages/hr/Department/components/DepartmentView";
import {ReactComponent as IconEdit} from "@/assets/icons/IconEdit.svg";
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import EmployeeView from "@/pages/hr/Employee/components/EmployeeView";
import EmployeeNew from "@/pages/hr/Employee/components/EmployeeNew";

const QuickStart: React.FC = () => {
  const {t} = useTranslation();
  // bd12a5cb47ff48c89886327edfc082ca   8098231240010
  const steps = [
    t("common.Site"),
    // t("common.Zone"),
    t("common.Device Management"),
    t("common.Add Employee"),
    t("common.Sync Employee"),
    t("common.Timesheet"),
    t("common.Shift Schedule"),
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [deviceId, setDeviceId] = React.useState<string>("");
  const [country, setCountry] = React.useState<string>("USA");
  const [getDeviceId, setGetDeviceId] = React.useState<string>("");
  const [saving, setSaving] = React.useState<boolean>(false);
  const [gridData, setGridData] = useState([]);
  const [selectionDeptModel, setSelectionDeptModel] =
    useState<GridSelectionModel>([]);
  const [selectionEmpModel, setSelectionEmpModel] =
    useState<GridSelectionModel>([]);
  const [selectionDevEmpModel, setSelectionDevEmpModel] =
    useState<GridSelectionModel>([]);
  const [selectionRemoveEmpModel, setSelectionRemoveEmpModel] =
    useState<GridSelectionModel>([]);
  const [selectionScheduleModel, setSelectionScheduleModel] =
    useState<GridSelectionModel>([]);
  const [selectionTimesheetModel, setSelectionTimesheetModel] =
    useState<GridSelectionModel>([]);
  const departmentViewRef = useRef<any>({})
  const dataDeptGridRef = useRef<DataGridRef>();
  const dataEmpGridRef = useRef<DataGridRef>();
  const dataDeviceEmpGridRef = useRef<DataGridRef>();
  const dataTimesheetGridRef = useRef<DataGridRef>();
  const dataScheduleGridRef = useRef<DataGridRef>();
  const employeeViewRef = useRef<any>({})
  const employeeDelRef = useRef<any>({})
  const employeeNewRef = useRef<any>({})
  const deptDelRef = useRef<any>({})
  const deviceEmpSyncRef = useRef<any>({})
  const deviceEmpDelRef = useRef<any>({})
  const timesheetRef = useRef<any>({})
  const scheduleRef = useRef<any>({})
  const reloadDeptTable = () => {
    dataDeptGridRef.current?.refresh();
  }
  const reloadEmpTable = () => {
    dataEmpGridRef.current?.refresh();
  }
  const reloadDeviceEmpTable = () => {
    dataDeviceEmpGridRef.current?.refresh();
  }
  const reloadTimesheetTable = () => {
    dataTimesheetGridRef.current?.refresh();
  }
  const reloadScheduleTable = () => {
    dataScheduleGridRef.current?.refresh();
  }
  const timezoneList = ngteco_timezones();
  const siteSchema = object({
    site_name: z
      .string()
      .min(1)
      .max(
        30,
        t("common.The length of field cannot exceed digits", {number: 30})
      ),
    time_zone: z.object(
      {
        value: z.string(),
        name: string(),
      },
      {
        required_error: t("common.This field is required!"),
        invalid_type_error: t("common.This field is required!"),
      }
    ),
    country: z.string().min(1, t("common.This field is required!")),
  });
  type siteInput = TypeOf<typeof siteSchema>;
  const siteForm = useForm<siteInput>({
    resolver: zodResolver(siteSchema),
    defaultValues: {
      site_name: "",
    },
  });
  const dispatch = useDispatch();

  const department_columns: GridColDef[] = [
    {field: "name", headerName: t("common.Department Name"), minWidth: 150},
    {field: "code", headerName: t("common.Department Code"), minWidth: 150},
    {
      field: "actions",
      headerName: t("common.action"),
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      renderCell: (e) => {
        return (
          <Stack direction={"row"} sx={{marginLeft: "-5px"}}>
            <Grid sx={{marginRight: "20px", display: "flex"}}>
              <CustomRowButton
                insideSvgIcon={IconEdit}
                actionName={t("common.edit")}
                buttonProps={{
                  key: "edit",
                  onClick: () => {
                    departmentViewRef?.current?.showDetail(e.id)
                    // navigate(WebURL.HR_DEPARTMENT_VIEW, {state: {id: e.id}});
                  }
                }}
              />
            </Grid>
          </Stack>
        );
      },
    },
  ];
  const emp_columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: t("common.Person Name"),
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <Box style={{padding: "0 8px"}}>
              {params.row.firstName} {params.row.lastName}
            </Box>
          </Grid>
        );
      },
    },
    {
      field: "employeeCode",
      headerName: t("common.Person ID"),
      type: "string",
      minWidth: 150,
    },
    {
      field: "department_name",
      headerName: t("common.Department"),
      type: "string",
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: t("common.action"),
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      renderCell: (e) => {
        return (
          <Stack direction={"row"} sx={{marginLeft: "-5px"}}>
            <Grid>
              <CustomRowButton insideSvgIcon={IconEdit} actionName={t("common.edit")} buttonProps={{
                key: "edit",
                onClick: () => {
                  employeeViewRef?.current?.showDetail(e.row.id)
                  // navigate(WebURL.HR_EMPLOYEE_VIEW, {state: {id: e.row.id}});
                }
              }}/>
            </Grid>
          </Stack>
        );
      },
    },
  ];
  const person_in_device_columns: GridColDef[] = [
    {
      field: "personCode",
      headerName: t("hr.employee.Person ID"),
      type: "string",
      minWidth: 180,
      flex: 0.2,
    },
    {
      field: "full_name",
      headerName: t("hr.employee.Person Name"),
      minWidth: 300,
      flex: 0.3,
      renderCell: (e: GridRenderCellParams) => (
        <Grid style={{display: "flex", alignItems: "center"}}>
          <Box style={{padding: "0 2px"}}>
            {e.row.firstName} {e.row.lastName}
          </Box>
        </Grid>
      ),
    },
  ];
  const timesheet_columns: GridColDef[] = [
    {field: "name", headerName: t("common.Timesheet Name"), minWidth: 200},
    {field: "period", headerName: t("common.Unit"), minWidth: 180},
    {field: "cycle", headerName: t("common.Cycle"), minWidth: 180},
    {
      field: "description",
      headerName: t("common.Description"),
      type: "string",
      minWidth: 100,
    },
    {
      field: "actions",
      headerName: t("common.action"),
      headerAlign: "left",
      align: "left",
      minWidth: 100,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <Grid sx={{marginLeft: "-5px"}}>
              <TimesheetEdit timesheetId={e.id.toString()}/>
            </Grid>
          </Stack>
        );
      },
    },
  ];
  const schedule_columns: GridColDef[] = [
    {field: "employee_name", headerName: t("common.Name"), minWidth: 150},
    {
      field: "employee_code",
      headerName: t("hr.employee.Person ID"),
      minWidth: 100,
    },
    {
      field: "department_name",
      headerName: t("hr.department.Department"),
      minWidth: 100,
    },
    {
      field: "timesheet_name",
      headerName: t("att.schedule.Timesheet"),
      minWidth: 100,
    },
    {
      field: "assigned_start",
      headerName: t("att.schedule.Start Date"),
      type: "date",
      minWidth: 80,
    },
    {
      field: "assigned_end",
      headerName: t("att.schedule.End Date"),
      type: "date",
      minWidth: 80,
    },
    {
      field: "actions",
      headerName: t("common.action"),
      headerAlign: "left",
      align: "left",
      minWidth: 50,
      renderCell: (e) => {
        return (
          <Stack direction={"row"}>
            <Grid sx={{marginLeft: "-5px"}}>
              <ScheduleEdit scheduleId={e.id.toString()}/>
            </Grid>
          </Stack>
        );
      },
    },
  ];
  const scheduleValidate = object({
    device_list: z.array(z.record(z.string(), z.string().nullable()), {}),
  });
  type scheduleInput = TypeOf<typeof scheduleValidate>;
  const scheduleForm = useForm<scheduleInput>({
    resolver: zodResolver(scheduleValidate),
  });
  const deviceValidate = object({
    sn: z
      .string({required_error: t("common.This field is required!")})
      .min(1, t("common.This field is required!"))
      .max(
        30,
        t("common.The length of field cannot exceed digits", {number: 30})
      ),
  });
  type deviceInput = TypeOf<typeof deviceValidate>;
  const select_device: { id: string; sn: string }[] = useSelector(
    (state: any) => {
      return state.device.devices;
    }
  );
  const deviceForm = useForm<deviceInput>({
    resolver: zodResolver(deviceValidate),
  });
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleSkip = () => {
    let newSkipped = skipped;
    if (activeStep === 0) {
      dispatch<any>(getOrganSites());
    }
    if (activeStep === 2) {
      dispatch<any>(getOrganDevice());
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === 1) {
      if (!getDeviceId) {
        SnackbarUtil.error(t("common.device sn error"), {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
        return false;
      }
    }
    if (activeStep === 2) {
      dispatch<any>(getOrganDevice());
    }
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleNextOne = () => {
    let newSkipped = skipped;
    const values = siteForm.getValues();
    if (!values.site_name || values.time_zone == undefined) {
      SnackbarUtil.error(t("common.quickly setting site value error"), {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      return false;
    }
    if (values.site_name.length > 30) {
      SnackbarUtil.error(
        t("common.The length of field cannot exceed digits", {number: 30}),
        {anchorOrigin: {vertical: "top", horizontal: "center"}}
      );
      return;
    }
    try {
      const newSite = {
        name: values.site_name,
        address: {
          timeZone: values.time_zone.value,
          city: "default",
          addressLine1: "default",
          country: country,
        },
      };
      GenericService.create(LocationEndpoints.SITE_URI, newSite).then(
        (response) => {
          if (response?.status >= 400) {
            SnackbarUtil.error(response?.message, {
              anchorOrigin: {vertical: "top", horizontal: "center"},
            });
          } else {
            SnackbarUtil.success(t("common.Site added successfully."), {
              anchorOrigin: {vertical: "top", horizontal: "center"},
            });
            if (isStepSkipped(activeStep)) {
              newSkipped = new Set(newSkipped.values());
              newSkipped.delete(activeStep);
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
          }
        }
      );
    } catch (e) {
      console.log("error===", e);
    } finally {
      setSaving(false);
      dispatch<any>(getOrganSites());
    }
    dispatch<any>(getOrganSites());
  };

  const handleBack = () => {
    if (activeStep == 2) {
      setGetDeviceId("");
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setGetDeviceId("");
  };

  const handleDataGridSave = (value: any) => {
    if (value) {
      setGridData(value);
    }
  };

  function ColorlibStepIcon(props: StepIconProps) {
    const {active, completed, className} = props;
    return (
      <Grid
        sx={{
          width: "48px",
          height: "48px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          sx={{
            width: active ? "48px" : "38px",
            height: active ? "48px" : "38px",
            borderRadius: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor:
              active || completed
                ? "rgba(43, 169, 222, 0.1)"
                : "rgba(143, 156, 163, 0.2)",
          }}
        >
          <ColorlibStepIconRoot
            ownerState={{completed, active}}
            className={className}
          >
            <Grid
              sx={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#ffffff",
              }}
            >
              {completed ? <CheckIcon/> : props.icon}
            </Grid>
          </ColorlibStepIconRoot>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid sx={{
      position: 'relative',

    }}>
      <CustomBreadcrumbs record={[{label: t("common.Quick Start")}]}/>
      <Grid
        sx={{
          backgroundColor: "#ffffff",
          minHeight: "calc(100vh - 130px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginRight: "24px",
          paddingBottom: "60px",
          position: "relative",
          marginBottom: "24px"
        }}
      >
        <Grid
          sx={{
            padding: "10px 14px 6px 14px",
          }}
        >
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step
                  key={label}
                  {...stepProps}
                  sx={{
                    ".MuiStepConnector-root": {
                      top: "24px",
                      height: "2px",
                      background: "#E9EBED",
                    },
                  }}
                >
                  <StepLabel
                    sx={{
                      ".MuiStepLabel-label": {
                        marginTop: "6px !important",
                        color: "#ABB5BA",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      },
                      ".Mui-active": {
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#2BA9DE !important",
                      },
                      ".Mui-completed": {
                        color: "#ABB5BA !important",
                      },
                    }}
                    StepIconComponent={ColorlibStepIcon}
                    {...labelProps}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Grid
            sx={{
              height: "10px",
            }}
          ></Grid>

          {activeStep === steps.length ? (
            <React.Fragment>
              <BgBox>
                <Typography sx={{mt: 2, mb: 1}}>
                  {t("common.all steps completed")}
                </Typography>
              </BgBox>

              <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                <Box sx={{flex: "1 1 auto"}}/>
                <Button onClick={handleReset}>{t("common.Return to the first page")}</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{mt: 2, mb: 1}}>Step {activeStep + 1}</Typography> */}

              {activeStep === 0 && (
                <Grid xs={12}>
                  <Grid
                    sx={{
                      padding: "20px",
                      backgroundImage: "url(" + img_quick_start_bg + ")",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px !important",
                        fontWeight: "bold !important",
                      }}
                    >
                      {t("common.Create A New Site")}
                    </Typography>
                    <CustomTypography>{t("common.Guide")}</CustomTypography>
                    <CustomTypography>
                      {t("common.quickly setting site one")}
                    </CustomTypography>
                    <CustomTypography>
                      {t("common.quickly setting site two")}
                    </CustomTypography>
                    <CustomTypography>
                      {t("common.quickly setting site three")}
                    </CustomTypography>
                  </Grid>

                  <Grid>
                    <h3>Site information:</h3>
                    <Grid sx={{maxWidth: "400px"}}>
                      <ControlledTextFiled
                        fullWidth
                        label={t("common.Site Name")}
                        name={"site_name"}
                        required={true}
                        hookForm={siteForm}
                      />
                      <ControlAutoComplete
                        label={t("common.Site Time Zone")}
                        name={"time_zone"}
                        required={true}
                        form={siteForm}
                        options={timezoneList}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                      />
                      <NGInputLabel label={t("signUp.Choose your country")} required/>
                      <Select
                        id="country-select"
                        fullWidth
                        value={country}
                        onChange={(event) => {
                          setCountry(event.target.value)
                        }}
                      >
                        <MenuItem value={"USA"}>{t("common.America")}</MenuItem>
                        <MenuItem value={"JPN"}>{t("common.Japan")}</MenuItem>
                        <MenuItem value={"GER"}>{t("common.Germany")}</MenuItem>
                        <MenuItem value={"FRA"}>{t("common.France")}</MenuItem>
                        <MenuItem value={"SPA"}>{t("common.Spain")}</MenuItem>
                        <MenuItem value={"UK"}>{t("common.United Kingdom")}</MenuItem>
                        <MenuItem value={"ITA"}>{t("common.Italy")}</MenuItem>
                        <MenuItem value={"CAN"}>{t("common.Canada")}</MenuItem>
                        <MenuItem value={"MEX"}>{t("common.Mexico")}</MenuItem>
                        <MenuItem value={"MAL"}>{t("common.Malaysia")}</MenuItem>
                        <MenuItem value={"THA"}>{t("common.Thailand")}</MenuItem>
                        <MenuItem value={"PHI"}>{t("common.Philippines")}</MenuItem>
                      </Select>
                    </Grid>
                    <Link
                      underline={"none"}
                      onClick={handleSkip}
                      sx={{
                        cursor: "pointer",
                        padding: "0 8px",

                      }}
                    >
                      <Typography sx={{
                        fontSize: '12px !important'
                      }}>
                        {t("common.Already have a site? Skip this step")}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid item sx={{display: "inline-flex"}} xs={12}>
                  <Grid xs={12}>
                    <Typography>
                      <DeviceRegister deviceId={setGetDeviceId}/>
                    </Typography>

                    <Link
                      underline={"none"}
                      onClick={handleSkip}
                      sx={{
                        cursor: "pointer",
                        padding: "0 8px",
                      }}
                    >
                      <Typography sx={{
                        fontSize: '12px !important'
                      }}>
                        {t(
                          "common.No need to add a new device? Skip this step"
                        )}
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              )}

              {activeStep === 2 && (
                <Grid>
                  <BgBox>
                    <Typography>{t("common.Add new employees and department")}</Typography>
                    <CustomTypography
                      sx={{
                        marginBottom: "0px  !important",
                      }}
                    >
                      {t("common.quickly setting device one")}
                    </CustomTypography>
                  </BgBox>

                  <Grid
                    sx={{
                      mt: 2,
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Grid
                      sx={{
                        mr: 2,
                        flexGrow: 1,
                        maxWidth: "600px !important",
                      }}
                    >
                      <Grid
                        sx={{
                          mb: 1,
                        }}
                      >
                        {t("common.Department")}
                      </Grid>
                      <CustomDataGrid
                        containerStyle={{
                          padding: "0px",
                        }}
                        tableStyle={{
                          padding: "0px",
                        }}
                        rowHeight={40}
                        headerHeight={40}
                        height="calc(100vh - 410px)"
                        ref={dataDeptGridRef}
                        boxShadow={false}
                        uri={HREndpoints.HR_DEPARTMENT_URI}
                        columns={department_columns}
                        checkboxSelection={true}
                        onDataChange={handleDataGridSave}
                        onSelectionModelChange={(ids) => {
                          setSelectionDeptModel(ids);
                          deptDelRef?.current?.showDelete(ids)
                        }}
                        selectionModel={selectionDeptModel}
                        toolbar={{
                          title: t("common.Department"),
                          search: true,
                          searchTips: t(
                            "common.Search by Department Name, Department Code"
                          ),
                          actions: [{
                            key: 'add', iconFont: 'icon-ic_add', helpTxt: t("common.add"), callback: async () => {
                              departmentViewRef?.current?.showDetail()
                              // navigate(WebURL.HR_DEPARTMENT_NEW);
                            }
                          }],
                          extraComponentOnRight: (
                            <Grid
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {/*<DepartmentAdd />*/}
                              <DepartmentDelete ref={deptDelRef} reloadTable={reloadDeptTable}/>
                              <DepartmentView ref={departmentViewRef} reloadTable={reloadDeptTable}></DepartmentView>
                            </Grid>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        ml: 2,
                        flexGrow: 1,
                      }}
                    >
                      <Grid
                        sx={{
                          mb: 1,
                        }}
                      >
                        {t("common.Person")}
                      </Grid>
                      <CustomDataGrid
                        height="calc(100vh - 410px)"
                        containerStyle={{
                          padding: "0px",
                        }}
                        tableStyle={{
                          padding: "0px",
                        }}
                        boxShadow={false}
                        rowHeight={40}
                        headerHeight={40}
                        uri={HREndpoints.HR_EMPLOYEE_URI}
                        columns={emp_columns}
                        ref={dataEmpGridRef}
                        checkboxSelection={true}
                        onDataChange={handleDataGridSave}
                        onSelectionModelChange={(ids) => {
                          setSelectionEmpModel(ids);
                          employeeDelRef?.current?.showDelete(ids)
                        }}
                        selectionModel={selectionEmpModel}
                        toolbar={{
                          title: t("common.Person"),
                          search: true,
                          searchTips: t("common.Search by Person First Name"),
                          actions: [{
                            key: 'add', iconFont: 'icon-ic_add', helpTxt: t("common.add"), callback: async () => {
                              employeeNewRef?.current?.showAdd()
                              // navigate(WebURL.HR_EMPLOYEE_NEW);
                            }
                          }],
                          extraComponentOnRight: (
                            <Grid
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <EmpDelete ref={employeeDelRef} reloadTable={reloadEmpTable}/>
                              <EmployeeView ref={employeeViewRef} reloadTable={reloadEmpTable}></EmployeeView>
                              <EmployeeNew ref={employeeNewRef} reloadTable={reloadEmpTable}></EmployeeNew>
                            </Grid>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Link
                    underline={"none"}
                    onClick={handleSkip}
                    sx={{
                      cursor: "pointer",
                      fontSize: "12px !important",
                      padding: "0px",
                    }}
                  >
                    {t("common.No need to add employee? Skip this step")}
                  </Link>
                </Grid>
              )}

              {activeStep === 3 && (
                <Grid>
                  <BgBox
                    style={{
                      paddingBottom: "6px",
                      paddingTop: "6px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px !important",
                      }}
                    >
                      {t("common.Add Employees to Your Device")}
                    </Typography>
                    <CustomTypography>
                      {t("common.quickly setting device two")}
                    </CustomTypography>
                    <CustomTypography>
                      {t("common.quickly setting device three")}
                    </CustomTypography>
                  </BgBox>

                  <Typography
                    sx={{
                      marginTop: "12px !important",
                      marginBottom: "8px !important",
                      fontSize: "14px !important",
                      color: "#666666",
                    }}
                  >
                    {t("common.quickly setting device four")}
                  </Typography>
                  <Grid
                    sx={{
                      maxWidth: "300px",
                      maxHeight: "50px",
                      ".MuiOutlinedInput-root": {padding: "1px"},
                    }}
                  >
                    <ControlAutoComplete
                      name={"sn"}
                      form={deviceForm}
                      label={""}
                      options={select_device}
                      multi={false}
                      onChange={(event) => {
                        scheduleForm.setValue("device_list", event);
                        setDeviceId(event?.id);
                      }}
                    />
                  </Grid>
                  {deviceId && (
                    <Grid sx={{display: "flex", mt: 2}} xs={12}>
                      <Grid
                        sx={{
                          marginRight: "10px",
                          flexGrow: 1,
                        }}
                      >
                        <CustomDataGrid
                          height="calc(100vh - 410px)"
                          boxShadow={false}
                          containerStyle={{
                            padding: "0px",
                          }}
                          tableStyle={{
                            padding: "0px",
                          }}
                          rowHeight={40}
                          headerHeight={40}
                          uri={HREndpoints.HR_EMPLOYEE_URI}
                          columns={emp_columns}
                          ref={dataDeviceEmpGridRef}
                          checkboxSelection={true}
                          onDataChange={handleDataGridSave}
                          onSelectionModelChange={(ids) => {
                            setSelectionDevEmpModel(ids);
                            deviceEmpSyncRef?.current?.showDelete({ids: ids, device_id: deviceId})
                          }}
                          selectionModel={selectionDevEmpModel}
                          toolbar={{
                            title: "",
                            search: true,
                            searchTips: t("common.Search by Person First Name"),
                            breadcrumbs: [
                              {
                                breadcrumbName: t("common.Choose Person"),
                                path: WebURL.HR_EMPLOYEE,
                              },
                            ],
                            extraComponentOnRight: (
                              <Grid>
                                <Sync2Device ref={deviceEmpSyncRef} reloadTable={reloadEmpTable}
                                />
                                <EmployeeView ref={employeeViewRef} reloadTable={reloadDeviceEmpTable}></EmployeeView>
                              </Grid>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid
                        sx={{
                          marginLeft: "20px",
                          flexGrow: 1,
                        }}
                      >
                        <CustomDataGrid
                          boxShadow={false}
                          containerStyle={{
                            padding: "0px",
                          }}
                          tableStyle={{
                            padding: "0px",
                          }}
                          rowHeight={40}
                          headerHeight={40}
                          height="calc(100vh - 410px)"
                          uri={`${DMSEndpoints.DMS_DEVICE_PERSON_URI}${
                            deviceId?.toString() || ""
                          }/person_list/`}
                          getRowId={(row) => row.personId}
                          checkboxSelection={true}
                          ref={dataEmpGridRef}
                          columns={person_in_device_columns}
                          onDataChange={handleDataGridSave}
                          onSelectionModelChange={(ids) => {
                            setSelectionRemoveEmpModel(ids);
                            deviceEmpDelRef?.current?.showDelete({ids: ids, device_id: deviceId})
                          }}
                          selectionModel={selectionRemoveEmpModel}
                          columnVisibilityModel={{
                            deviceId: false,
                          }}
                          experimentalFeatures={{newEditingApi: true}}
                          toolbar={{
                            title: "",
                            searchTips: t("common.Search by Person ID"),
                            search: true,
                            breadcrumbs: [
                              {
                                breadcrumbName: t(
                                  "common.Person in this device"
                                ),
                                path: "",
                              },
                            ],
                            extraComponentOnRight: (
                              <EmpRemove ref={deviceEmpDelRef} reloadTable={reloadEmpTable}/>
                            ),
                            filter: false,
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Link
                    underline={"none"}
                    onClick={handleSkip}
                    sx={{
                      cursor: "pointer",
                      padding: "6 5px",
                      fontSize: "12px",
                    }}
                  >
                    {t(
                      "common.No need to add employee to your device? Skip this step"
                    )}
                  </Link>
                </Grid>
              )}

              {activeStep === 4 && (
                <Grid>
                  <BgBox
                    style={{
                      paddingBottom: "6px",
                      paddingTop: "6px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px !important",
                      }}
                    >
                      {t("common.quickly setting timesheet one")}
                    </Typography>
                    <CustomTypography>{t("common.Guide")}</CustomTypography>
                    <CustomTypography>
                      {t("common.quickly setting timesheet two")}
                    </CustomTypography>
                    <CustomTypography>
                      {t("common.quickly setting timesheet three")}
                    </CustomTypography>
                  </BgBox>

                  <Grid
                    sx={{
                      mt: 2,
                    }}
                  >
                    <CustomDataGrid
                      height="calc(100vh - 460px)"
                      boxShadow={false}
                      containerStyle={{
                        padding: "0px",
                      }}
                      tableStyle={{
                        padding: "0px",
                      }}
                      rowHeight={40}
                      headerHeight={40}
                      uri={ATTEndpoints.ATT_TIMESHEET_URI}
                      columns={timesheet_columns}
                      ref={dataTimesheetGridRef}
                      checkboxSelection={true}
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectionTimesheetModel(newSelectionModel);
                        timesheetRef?.current?.showDelete(newSelectionModel)
                      }}
                      selectionModel={selectionTimesheetModel}
                      toolbar={{
                        title: "",
                        search: true,
                        searchTips: t("common.Search by Timesheet Name"),
                        breadcrumbs: [
                          {
                            breadcrumbName: t("common.TimeSheet"),
                            path: WebURL.ATT_TIMESHEET,
                          },
                        ],
                        extraComponentOnRight: (
                          <Grid
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <TimeSheetAdd ref={timesheetRef} reloadTable={reloadTimesheetTable}/>
                            <TimesheetDelete ref={timesheetRef} reloadTable={reloadTimesheetTable}/>
                          </Grid>
                        ),
                      }}
                    />
                  </Grid>

                  <Link
                    underline={"none"}
                    onClick={handleSkip}
                    sx={{
                      cursor: "pointer",
                      padding: "0 8px",
                    }}
                  >
                    <Typography
                      sx={{
                        cursor: "pointer",
                        padding: "6 5px",
                        fontSize: "12px !important",
                      }}
                    >
                      {t(
                        "common.No need to create a new timesheet? Skip this step"
                      )}
                    </Typography>
                  </Link>
                </Grid>
              )}

              {activeStep === 5 && (
                <Grid>
                  <BgBox
                    style={{
                      paddingBottom: "6px",
                      paddingTop: "6px",
                    }}
                  >
                    <Typography>
                      {t("common.quickly setting schedule one")}
                    </Typography>
                    <CustomTypography>{t("common.Guide")}</CustomTypography>
                    <CustomTypography>
                      {t("common.quickly setting schedule two")}
                    </CustomTypography>
                  </BgBox>

                  <Grid
                    sx={{
                      marginTop: "16px",
                    }}
                  >
                    <CustomDataGrid
                      height="calc(100vh - 410px)"
                      boxShadow={false}
                      containerStyle={{
                        padding: "0px",
                      }}
                      tableStyle={{
                        padding: "0px",
                      }}
                      rowHeight={40}
                      headerHeight={40}
                      uri={ATTEndpoints.ATT_SCHEDULE_URI}
                      columns={schedule_columns}
                      ref={dataScheduleGridRef}
                      checkboxSelection={true}
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectionScheduleModel(newSelectionModel);
                        scheduleRef?.current?.showDelete(newSelectionModel)
                      }}
                      selectionModel={selectionScheduleModel}
                      toolbar={{
                        title: "",
                        search: true,
                        searchTips: t(
                          "common.Search by Person ID, Person Name, Timesheet Name"
                        ),
                        extraComponentOnRight: (
                          <Grid
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ScheduleAdd ref={scheduleRef} reloadTable={reloadScheduleTable}/>

                            <DeviceListButtonDrawer
                              IDs={selectionScheduleModel}
                            />
                            <ScheduleDelete ref={scheduleRef} reloadTable={reloadScheduleTable}/>
                          </Grid>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          )}
        </Grid>
      </Grid>

      <Grid
        sx={{
          borderTop: "1px solid #F2F5F8",
          padding: "10px",
          display: "flex",
          justifyContent: "flex-start",
          position: "absolute",
          bottom: "0px",
          width: "calc(100% - 24px)",
          marginRight: "24px",
          boxSizing: 'border-box',
          backgroundColor: "#ffffff"
        }}
      >
        <Box sx={{display: "flex"}}>
          {activeStep !== 0 && (
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                mr: 1,
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 300,
                background: " #2BA9DE",
                "&:hover": {
                  background: " #2BA9DE",
                },
                textTransform: "none"
              }}
            >
              {t("common.Back")}
            </Button>
          )}
          {activeStep === 0 && (
            <Button
              onClick={handleNextOne}
              sx={{
                mr: 1,
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 300,
                background: " #2BA9DE",
                "&:hover": {
                  background: " #2BA9DE",
                },
                textTransform: "none"
              }}
            >
              {activeStep === steps.length - 1 ? t("common.Finish") : t("common.Next")}
            </Button>
          )}
          {[1, 2, 3, 4, 5].includes(activeStep) && (
            <Button
              onClick={handleNext}
              sx={{
                mr: 1,
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: 300,
                background: " #2BA9DE",
                "&:hover": {
                  background: " #2BA9DE",
                },
                textTransform: "none"
              }}
            >
              {activeStep === steps.length - 1 ? t("common.Finish") : t("common.Next")}
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
export default QuickStart;
