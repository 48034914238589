import { Controller, UseFormReturn } from "react-hook-form";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { TextField } from "@mui/material";


type ControlTextFieldReadOnlyType = {
  label: string;
  name: string;
  form: UseFormReturn<any>;
  required?: boolean;
  InputProps?: Partial<StandardInputProps>;
}

const ControlTextFieldReadOnly: React.FC<ControlTextFieldReadOnlyType> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.form.control}
      render={({field, fieldState}) => (
        <TextField 
          fullWidth
          required={props.required}
          label={props.label}
          error={!!fieldState.error}
          helperText={fieldState.error?.message || " "}
          {...field}
          InputLabelProps={{
            shrink: !!field.value
          }}
          InputProps={{
            readOnly: true,
            disabled: true,
            ...props.InputProps
          }}
        />
      )}
    />
  )
}

export default ControlTextFieldReadOnly;