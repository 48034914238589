import { Dispatch, createContext, useContext, useReducer } from "react";


interface Data {
  data: Record<string, any> | Array<any>,
  total: number,
}

interface TasksState {
  page: number,
  pageSize: number,
  refresh: number,
  searchKeyword: string,
}

interface actionType {
  type: string,
  pageSize: number | 20,
  page: number | 0,
  refresh: number | 0,
  searchKeyword: string | "",
}

const initialDataGrid = {
  page: 0,
  pageSize: 20,
  refresh: 0,
  searchKeyword: "",
}

export const DataGridContext = createContext<TasksState>({} as TasksState)
export const DataGridDispatchContext = createContext<Dispatch<any>>((s: any) => {console.log()})

export function DataGridProvider({ children }: any) {
  const [task, dispatch] = useReducer(dataGridReducer, initialDataGrid)

  return (
    <DataGridContext.Provider value={task}>
      <DataGridDispatchContext.Provider value={dispatch}>
        {children}
      </DataGridDispatchContext.Provider>
    </DataGridContext.Provider>
  )
}

function dataGridReducer(tasks: TasksState, action: any) {
  switch (action.type) {
    case "switching_pageSize": {
      // 切换pageSize大小
      return {
        ...tasks,
        pageSize: action.pageSize,
      }
    }
    case "turning_page": {
      // 翻页
      return {
        ...tasks,
        page: action.page,
      }
    }
    case "refreshed": {
      // 刷新页面
      return {
        ...tasks,
        page: 0,
        refresh: tasks.refresh + 1,
      }
    }
    case "searched": {
      // 查询
      return {
        ...tasks,
        page: 0,
        searchKeyword: action.searchKeyword,
      }
    }
    case "cleared": {
      // 清空
      return {
        ...tasks,
        page: 0,
        searchKeyword: "",
      }
    }
    default: {
      throw Error("Unknown action: " + action.type)
    }
  }
}
