import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breadcrumbs: [],
};

const breadcrumbsSlice = createSlice({
  name: "Breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbs(state, params: any) {
      state.breadcrumbs = params.payload
    },
  },
});
export const { setBreadcrumbs } = breadcrumbsSlice.actions
export default breadcrumbsSlice.reducer;
