import React from "react";
import moment from "moment/moment";
import {GridColDef} from "@mui/x-data-grid";
import {ATTEndpoints} from "../../../../services/att/endpoints";
import WebURL from "../../../../urls";
import CustomDataGrid from "../../../../components/data-grid/CustomDataGrid";
import { useTranslation } from "react-i18next";


const MonthlyReport: React.FC = () => {
  const { t } = useTranslation();
  const monthFields: any[] = [];
  const startMoment = moment().startOf('month');
  const endMoment = moment().endOf('month');
  const columns: GridColDef[] = [
    {headerName: t("common.Person ID"), field: 'employee_code', width: 120},
    {headerName: t("common.Person Name"), field: 'employee_name', width: 160},
    {headerName: t("common.Department"), field: 'department_name', width: 120},
    {headerName: t("common.Position"), field: 'designation_name', width: 120},
  ]
  for (let i = 0; i < endMoment.date(); i++) {
    const curMoment = moment().startOf('month').add(i, 'day')
    columns.push({
      headerName: curMoment.date().toString(),
      field: curMoment.format("YYYYMMDD"),
      flex: 0.1
    })
  }
  return (
    <CustomDataGrid
      uri={ATTEndpoints.ATT_REPORT_MONTHLY_REPORT}
      columns={columns}
      checkboxSelection={false}
      getRowId={(row) => {
        return Math.random()
      }}
      toolbar={{
        title: t("common.First In Last Out"),
        breadcrumbs: [
          {breadcrumbName: t("common.Report"), path: WebURL.ATT_REPORT_HOME},
          {breadcrumbName: t("common.Monthly Report"), path: WebURL.ATT_REPORT_MONTHLY_REPORT},
        ]
      }}
    />
  )
}
export default MonthlyReport;
