import { Close } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Drawer, Grid, Paper, Stack, Typography, styled, IconButton } from "@mui/material";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Confirm from "./Confirm";
import Info from "./Info";
import Notice from "./Notice";


interface OrganDeleteProps {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  ImageView: JSX.Element
}

const IconCloseCustom = styled(IconButton)`
  &:hover {
    color: gray;
  }
`;


const OrganDelete: React.FC<OrganDeleteProps> = (props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<"notice" | "info" | "confirm" | string>("notice");
  const [data, setData] = useState<{ device: number, person: number }>({ device: 0, person: 0 });
  const company = useSelector((state: any) => state.app.company);
  return (
    <Drawer
      anchor={"right"}
      open={props.open}
    >
      <Box sx={{ padding: '10px', height: '100%', width: '500px', color: 'gray' }}>
        <Box>
          <Grid>
            <Box onClick={() => { props.setOpen(false); setStep("notice"); }} sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconCloseCustom>
                <Close />
              </IconCloseCustom>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Grid>
                <Typography sx={{ fontSize: '28px' }}>{t("organ.destroy.Delete this organization")}</Typography>
              </Grid>
            </Box>
          </Grid>
        </Box>
        <Paper sx={{ height: '88px' }} elevation={3}>
          <Grid container sx={{ padding: '8px' }}>
            <Grid lg={2} item sx={{ display: 'flex', justifyContent: 'center' }}>
              {props.ImageView}
            </Grid>
            <Grid lg={10} item sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', fontSize: '19px', color: 'gray' }}>
              <Grid>
                <Grid>
                  <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '400px', wordBreak: 'break-all', whiteSpace: 'nowrap' }}>
                    {t("organ.Organization Name")}: {company?.name}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '400px', wordBreak: 'break-all', whiteSpace: 'nowrap' }}>
                    {t("organ.Organization Code")}: {company?.code}
                  </Typography>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {step === "notice"
          ? <Notice setStep={setStep} setData={setData} />
          : step === "info"
            ? <Info setStep={setStep} data={data} setOpen={props.setOpen} />
            : <Confirm setStep={setStep} company={company} />
        }
      </Box>
    </Drawer>
  )
}

export default OrganDelete;
