import request from '@/utils/Request';
import qs from 'qs';
import {LocationEndpoints} from "@/services/location/endpoints";

const list = async (params: Record<string, any>) => {
  const response = await request.authorizedAxios().get(LocationEndpoints.ZONE_URI, {params, paramsSerializer: params => qs.stringify(params, {indices: false})});
  return response?.data;
}

const ZoneService = {
  list
}

export default ZoneService;
