import React from "react";
import { useNavigate, useParams } from "react-router-dom"
import {Grid, Stack, Button, Paper, Select, MenuItem} from "@mui/material";
import GenericService from "@/services/GenericService";
import { LocationEndpoints } from "@/services/location/endpoints"
import CustomTooltip from "@/components/breadcrumbs/CustomToolTip"
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import { useForm } from "react-hook-form";
import { object, string, TypeOf, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { ngteco_timezones } from "@/components/resource/timezone";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { CancelButton, SavingButton } from "@/components/data-grid/CustomButton";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";

const AddSite: React.FC = () => {
  const { t } = useTranslation();
  const siteValidate = object({
    name: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!"))
      .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    timezone: z.object({
      value: z.string(),
      name: string()
    }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
    country: z.string().min(1, t("common.This field is required!")),
  })
  type siteInput = TypeOf<typeof siteValidate>;

  const navigate = useNavigate();
  const helpInfo = t("location.site.Add Site Tip");
  const [saving, setSaving] = React.useState<boolean>(false)
  const timezoneList = ngteco_timezones()

  const siteForm = useForm<siteInput>({
    resolver: zodResolver(siteValidate),
    defaultValues: {
      name: '',
    }
  });
  const { register, handleSubmit, formState: { errors } } = siteForm

  const onSave = async () => {
    setSaving(true)
    try {
      const values = siteForm.getValues()
      const newSite = {
        name: values.name,
        address: {
          timeZone: values.timezone.value,
          city: "default",
          addressLine1: "default",
          country: values.country,
        }
      }
      const response = await GenericService.create(LocationEndpoints.SITE_URI, newSite)
      if (response?.status < 400)
        SnackbarUtil.success(t('common.Site added successfully.'))
      else
        SnackbarUtil.error(response?.message)
      navigate(-1)
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.LOCATION_SITE, label: t("common.Site") },
        {
          label: t("common.New Site"),
          helpInfo: helpInfo
        },
      ]} />
      <Paper sx={{ margin: '20px 0', padding: '20px' }}>
        <Grid container spacing={2} component="form">
          <Grid item xs={5} >
            <img width={"100%"} src={'/images/location-site-view-rectangle.png'} />
          </Grid>
          <Grid item xs={7}>
            <ControlledTextFiled required fullWidth label={t("common.Site Name")} name={"name"} hookForm={siteForm} />
            <ControlAutoComplete required label={t("common.Site Time Zone")} name={'timezone'} form={siteForm}
                                 options={timezoneList}
                                 isOptionEqualToValue={(option, value) => option.value === value.value}
            />
            <NGInputLabel label={t("signUp.Choose your country")} required/>
            <Select
              id="country-select"
              fullWidth
              defaultValue={'USA'}
              {...register("country")}
            >
              <MenuItem value={"USA"}>{t("common.America")}</MenuItem>
              <MenuItem value={"JPN"}>{t("common.Japan")}</MenuItem>
              <MenuItem value={"GER"}>{t("common.Germany")}</MenuItem>
              <MenuItem value={"FRA"}>{t("common.France")}</MenuItem>
              <MenuItem value={"SPA"}>{t("common.Spain")}</MenuItem>
              <MenuItem value={"UK"}>{t("common.United Kingdom")}</MenuItem>
              <MenuItem value={"ITA"}>{t("common.Italy")}</MenuItem>
              <MenuItem value={"CAN"}>{t("common.Canada")}</MenuItem>
              <MenuItem value={"MEX"}>{t("common.Mexico")}</MenuItem>
              <MenuItem value={"MAL"}>{t("common.Malaysia")}</MenuItem>
              <MenuItem value={"THA"}>{t("common.Thailand")}</MenuItem>
              <MenuItem value={"PHI"}>{t("common.Philippines")}</MenuItem>
            </Select>
          </Grid>

        </Grid>
      </Paper>

      <Grid style={{ 'padding': '30px' }}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end">
          <CancelButton />
          <SavingButton loading={saving} onClick={handleSubmit(onSave)} />
        </Stack>
      </Grid>
    </>
  )
}

export default AddSite;
