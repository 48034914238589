import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

type SubMenuItemProps = {
  name: string;
  title: string;
  icon: SvgIconComponent;
  path: string;
}

const SubMenuItem: React.FC<SubMenuItemProps> = (props) => {
  const navigate = useNavigate();
  const onItemClick = () => {
    navigate(props.path);
  }
  return (
    <Grid
      key={props.name}
      item={true}
      xs={4}
      md={3}
      sx={{height: "64px"}}
      onClick={onItemClick}
    >
      <Box display={"flex"} alignItems={"center"}>
        <Grid
          display={"flex"} alignItems={"center"} justifyContent={"center"}
          sx={{
            width: "48px",
            height: "48px",
            backgroundColor: "#2BA9DE",
            borderRadius: "50%",
            cursor: "pointer",
          }}
        >
          <props.icon sx={{color: "#ffffff"}}/>
        </Grid>
        <Box sx={{padding: "0 8px", cursor: "pointer"}}>
          <Typography>{props.title}</Typography>
        </Box>
      </Box>
    </Grid>
  )
}
export default SubMenuItem
