import React, {Fragment, useState, forwardRef, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Dialog, DialogTitle, Grid, IconButton, Tooltip} from "@mui/material";
import {Add} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import NewTimesheetQuick from "@/pages/quick/components/timesheet/TimeSheetForm";
import ActionButton from "@/components/actions/ActionButton";
const dialogStyle = {
  '& .MuiPaper-root': { width: '60vw', top: '1vh', overflowX: "hidden"},
  '.MuiDialog-paper': { maxWidth: '100vw' },
  overflowX: "hidden",
};

export function AddAction({setInfo}: any) {
  const {t} = useTranslation()

  return (
    <Tooltip title={t("common.New Department")}>
      <ActionButton icon="icon-ic_add" title={t("common.add")} handleClick={() => {setInfo(true)}}> </ActionButton>
    </Tooltip>
  )
}


const TimeSheetAdd = (props: any, ref: any) => {
  const [info, setInfo] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  useEffect(() => {
    if (refresh) {
      if (props.reloadTable) {
        props.reloadTable()
        setRefresh(false)
      }
    }
  }, [refresh])

  return (
    <Fragment>
      <Grid item={true}>
        <AddAction setInfo={setInfo}/>
      </Grid>
      <AddTimeSheetDialog info={info} setInfo={setInfo} setRefresh={setRefresh}/>
    </Fragment>
  )
}

const AddTimeSheetDialog = ({info, setInfo, setRefresh}: any) => {
  const {t} = useTranslation()
  return (
    <Dialog
      open={info}
      // onClose={()=>setInfo(false)}
      sx={dialogStyle}
    >
      <DialogTitle style={{marginBottom: "-25px"}}>
        {t("common.Add TimeSheet")}
        <IconButton
          aria-label="close"
          onClick={() => setInfo(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <Grid sx={{margin: '20px 0', padding: '20px'}}>
        <Grid
          container
          spacing={2}
        >
          <NewTimesheetQuick info={setInfo} refresh={setRefresh} />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default forwardRef(TimeSheetAdd);
