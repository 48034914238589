import { Grid, Breadcrumbs, Typography, Box } from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";
import BackGo from "@/components/back-go/BackGo";

const ComplianceView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{width: 'calc(100% - 16px)', marginBottom: '24px', boxSizing: 'border-box', height: 'calc(100% - 100px)'}}>
      <CustomBreadcrumbs record={[
        { path: WebURL.AUTH_USER_ABOUT, label: t("common.About") },
        { label: t("common.Compliance") },
      ]} />
      <BackGo title={t("common.Compliance")}></BackGo>
      <Box sx={{ padding: "24px", backgroundColor: '#ffffff', marginTop: '12px', height: 'calc(100% - 100px)' }}>
        <Typography>
          {t("common.Compliance")}
        </Typography>
      </Box>
    </Box>
  )
}

export default ComplianceView;
