import React, { useContext, useRef, useState } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import CustomDataGrid, { DataGridRef } from "@/components/data-grid/CustomDataGrid";
import { BaseEndpoints } from "@/services/base/endpoints";
import { useNavigate } from "react-router-dom";
import WebURL from "@/urls";

import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import Util from "@/utils/Util";

const StatusField = (params: GridRenderCellParams) => {
  if (params.value === 0) {
    return (
      <span>
        {useTranslation().t("common.success")}
      </span>
    )
  } else {
    return (
      <span>
        {useTranslation().t("common.failure")}
      </span>
    )
  }
}

const DatetimeField = (params: GridRenderCellParams) => {
  return (
    <span>
      {Util.datetime2string(new Date(params.value))}
    </span>
  )
}

const LongTextField = (params: GridRenderCellParams) => {
  return (
    <Tooltip title={params.value}>
      <span>
      {params.value}
      </span>
    </Tooltip>

  )
}


const AdminLog: React.FC = () => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    // { field: 'id', headerName: "id", minWidth: 30},
    { field: 'user', headerName: t("common.User"), minWidth: 80, flex: 0.1},
    { field: 'op_time', renderCell: DatetimeField, headerName: t("common.Time"), type: "date", minWidth: 80, flex: 0.1},
    { field: 'action_status', renderCell: StatusField, headerName: t("common.State"), minWidth: 50, flex: 0.05},
    { field: 'action', headerName: t('common.Action'), minWidth: 100, flex: 0.15},
    // { field: 'targets', headerName: "targets", minWidth: 120},
    { field: 'description', renderCell: LongTextField, headerName: t("common.Description"), minWidth: 200, flex: 0.3},
  ]
  return (
    <>
      <CustomBreadcrumbs record={[
        { label: t('common.AdminLog')},
      ]} />
      <CustomDataGrid
        uri={`/base/api/v1.0/admin_log/`}
        ref={dataGridRef}
        columns={columns}
        checkboxSelection={false}
        toolbar={{
          title: "",
          search: true,
          searchTips: t("common.Search by User/Operation/Description")
        }}
      />
    </>
  )
}
export default AdminLog
