import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Grid, Paper, SvgIcon } from "@mui/material";
import { Dispatch, ForwardedRef, forwardRef, ReactNode, SetStateAction, useImperativeHandle, useState } from "react";
import { ReactComponent as IconDevices } from '@/assets/icons/IconDevices.svg';
import { ReactComponent as IconPersons } from '@/assets/icons/IconPersons.svg';
import { useTranslation } from "react-i18next";

interface InfoProps {
  setStep: Dispatch<SetStateAction<string>>,
  setOpen: Dispatch<SetStateAction<boolean>>,
  data: Record<string, any>
}

interface CardProps {
  quantity: string,
  description: string,
  image?: ReactNode
}

const Info = (props: InfoProps) => {
  const {t} = useTranslation();
  const CardInfo = (data: CardProps) => {
    return (
      <Paper elevation={3} sx={{borderRadius: '10px', width: '48%', height: '100px', backgroundColor: '#2BA9DE', display: 'flex', justifyContent: 'space-around', alignItems: 'center', color: 'white'}}>
        <Grid>
          {data.image}
        </Grid>
        <Grid>
          <Grid sx={{fontSize: '30px'}}>
            {data.quantity}
          </Grid>
          <Grid>
            {data.description}
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const ThousandSeparator = (quantity: string | number) => {
    if (typeof quantity === "number") {
      return quantity.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    } else {
      return quantity.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }

  return (
    <>
      <Box>
        <Grid sx={{margin: '16px 0'}}>
          {t("organ.destroy.infoTip01")}
        </Grid>
        <Grid sx={{display: 'flex', justifyContent: 'space-around'}}>
          <CardInfo
            quantity={ThousandSeparator(props.data.person)}
            description={t("organ.destroy.No. of Persons")}
            image={<IconPersons />}
          />
          <CardInfo
            quantity={ThousandSeparator(props.data.device)}
            description={t("organ.destroy.No. of Devices")}
            image={<IconDevices />}
          />
        </Grid>
        <Grid sx={{margin: '8px 0'}}>
          {t("organ.destroy.infoTip02")}
        </Grid>
        <Grid sx={{margin: '8px 0'}}>
          {t("organ.destroy.infoTip03")}
        </Grid>
      </Box>
      <Box sx={{ position: 'absolute', bottom: '20px', width: '500px'}}>
        <Button sx={{ width: '100%', padding: "10px" }} onClick={() => {props.setOpen(false); props.setStep("notice");}} variant="contained">{t("organ.destroy.Go to Organization Management")}</Button>
      </Box>
    </>
  )
}

export default Info;
