import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DepartmentService from "@/services/hr/DepartmentService";
import {useSnackbar} from "notistack";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import SnackbarUtil from "@/utils/SnackbarUtil";
import ActionButton from "@/components/actions/ActionButton";

const dialogStyle = {
  "& .MuiPaper-root": {width: "400px", top: "1vh", overflowX: "hidden", marginBottom: "50px"},
  ".MuiDialog-paper": {maxWidth: "100vw"},
  overflowX: "hidden",
};

export function DeleteAction({setInfo}: any) {
  const {t} = useTranslation();

  return (
    <ActionButton
      title={t("common.delete")}
      icon="icon-ic_delete"
      handleClick={() => {
        setInfo(true);
      }}
    >
    </ActionButton>
  );
}

const DepartmentDelete = (props: any, ref: any) => {
  const [info, setInfo] = useState<boolean>(false);
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [deptIds, setDeptIds] = useState<any>([]);
  useImperativeHandle(ref, () => ({
    showDelete: async (id:string) => {
      setDeptIds({id})
      setInfo(false)
    }
  }));
  const onSubmit = async (event: any) => {
    if (deptIds && deptIds?.id.length == 0) {
      SnackbarUtil.error(t("common.Select department first"), {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      return;
    }
    try {
      deptIds?.id.map((dept: any) => {
        DepartmentService.retrieve(dept).then(res => {
          if (res.data?.data['code'] === 'DEFAULT') {
            enqueueSnackbar(t("common.default dept delete error"), {
              variant: "error",
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            });
            return
          } else {
            DepartmentService.destroy(dept.toString()).then(res => {
              if (res.status === 200) {
                enqueueSnackbar(t("common.success"), {
                  variant: "success",
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                });
              }
            })
          }
        })
      });
      setTimeout(function () {
        if (props.reloadTable) {
          props.reloadTable()
        }
      }, 1500)
    } catch (e) {
      console.log("error===", e);
    } finally {
      setInfo(false);
    }
  };

  return (
    <Fragment>
      <Grid item={true}>
        <DeleteAction setInfo={setInfo}/>
      </Grid>
      <Dialog
        open={info}
        // onClose={()=>setInfo(false)}
        sx={dialogStyle}
      >
        <DialogTitle style={{marginBottom: "-25px"}}>
          {t("hr.department.Delete Department?")}
          <IconButton
            aria-label="close"
            onClick={() => setInfo(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <Typography sx={{p: 3}}>{t("hr.department.Delete Confirm")}</Typography>
        <Grid style={{marginLeft: "20px", marginRight: "20px", marginBottom: "20px"}}>
          <Stack spacing={3} direction={"row"}>
            <CancelButton
              onClick={() => {
                setInfo(false);
              }}
            />
            <SaveButton onClick={onSubmit} children={t("common.confirm")}/>
          </Stack>
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default forwardRef(DepartmentDelete);
