// material-ui
import { alpha } from "@mui/material/styles";

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme:any) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "6px 4px",
          height:'30px'
        },
        notchedOutline: {
          borderColor: theme.palette.grey[300],
        },
        root: {

        },
        inputSizeSmall: {
          padding: "6px 4px",
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
  };
}
