import React from "react";
import {Box, Breadcrumbs, Grid, Typography} from "@mui/material";
import {AccessTime, TextSnippet} from "@mui/icons-material";
import SubMenuItem from "../../../components/menu/SubMenuItem";
import { useTranslation } from "react-i18next";

const ReportHome: React.FC = () => {
  const { t } = useTranslation();
  const reports = [
    {name: "firstAndLast", title: t("common.First and Last"), icon: AccessTime, path: "/att/report/first-and-last"},
    {name: "filo", title: t("common.First In Last Out"), icon: AccessTime, path: "/att/report/first-in-last-out"},
    {name: "monthlyReport", title: t("common.Monthly Report"), icon: TextSnippet, path: "/att/report/monthly"},
  ]
  return (
    <Box>
      <Grid className={"toolbar-container"}>
        <Breadcrumbs sx={{height: "32px", p: '8px'}}>
          <Typography>{t("common.Report")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container={true} rowSpacing={2} sx={{margin: "0"}}>
        {
          reports.map((item) => <SubMenuItem key={item.name} {...item} />)
        }
      </Grid>
    </Box>
  )
};
export default ReportHome
