import React, {forwardRef, Fragment, useImperativeHandle, useState} from "react";
import {useTranslation} from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {Remove} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {useSnackbar} from "notistack";
import {CancelButton, SaveButton} from "@/components/data-grid/CustomButton";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/dms/DeviceService";
import ActionButton from "@/components/actions/ActionButton";

const dialogStyle = {
  "& .MuiPaper-root": {width: "400px", top: "1vh", overflowX: "hidden"},
  ".MuiDialog-paper": {maxWidth: "100vw"},
  overflowX: "hidden",
};

export function RemoveAction({setInfo}: any) {
  const {t} = useTranslation();

  return (
    <>
      <Tooltip title={t("common.Delete Employee from Device")}>
        <Grid>
          <ActionButton
            icon='icon-ic_delete'
            title={t("common.delete")}
            handleClick={() => {
              setInfo(true);
            }}
          ></ActionButton>
        </Grid>
      </Tooltip>
    </>
  );
}

const EmpRemove = (props: any, ref: any) => {
  const [info, setInfo] = useState<boolean>(false);
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [empIds, setEmpIds] = useState<any>([])
  const [deviceId, setDeviceIds] = useState<any>([])
  useImperativeHandle(ref, () => ({
    showDelete: async (object: any) => {
      setEmpIds(object?.ids)
      setDeviceIds(object?.device_id)
      setInfo(false)
    }
  }));
  const onSubmit = async (event: any) => {
    if (empIds.length == 0) {
      SnackbarUtil.error(t("att.schedule.Select person first"), {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      return;
    }
    try {
      const employee_list = empIds.map((emp: any) => {
        DeviceService.deleteDevicePerson(`${deviceId}`, {
          data: {personId: `${emp}`},
        });
      });
      enqueueSnackbar(t("common.success"), {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      setTimeout(function () {
        if (props.reloadTable) {
          props.reloadTable()
        }
      }, 1500)
    } catch (e) {
      console.log("error===", e);
    } finally {
      setInfo(false);
    }
  };

  return (
    <Fragment>
      <Grid item={true}>
        <RemoveAction setInfo={setInfo}/>
      </Grid>
      <Dialog
        open={info}
        // onClose={()=>setInfo(false)}
        sx={dialogStyle}
      >
        <DialogTitle>
          {t("common.Delete Employee from Device")}
          <IconButton
            aria-label="close"
            onClick={() => setInfo(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <Typography sx={{textAlign: "center"}}>
          {t("common.Do you want to delete these person from device?")}
        </Typography>
        <Grid style={{padding: "20px"}}>
          <Stack spacing={3} direction={"row"} justifyContent="flex-end">
            <CancelButton
              onClick={() => {
                setInfo(false);
              }}
            />
            <SaveButton onClick={onSubmit} children={t("common.confirm")}/>
          </Stack>
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default forwardRef(EmpRemove);
