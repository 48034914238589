import {
  Box,
  Grid,
  Breadcrumbs,
  Typography,
  Badge,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import GenericService from "@/services/GenericService";
import Paper from "@mui/material/Paper";
import {styled} from "@mui/material/styles";
import {ATTEndpoints} from "@/services/att/endpoints";
import {Refresh} from "@mui/icons-material";
import {DMSEndpoints} from "@/services/dms/endpoints";
import TableContainer from "@mui/material/TableContainer";
import {ReactComponent as DeviceIcon} from "@/assets/images/Device.svg";
import {useTranslation} from "react-i18next";
import ActionButton from "@/components/actions/ActionButton";
import CircularProgress from "@mui/material/CircularProgress";
import {TitleTypography} from './commonCmp'

type GridData = {
  data: any;
  total: number;
  num_pages: number;
};

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
  borderRadius: "10px",
}));

const DeviceList: React.FC = () => {
  const {t} = useTranslation();
  const [gridData, setGridData] = useState<GridData>({
    data: [],
    total: 0,
    num_pages: 0,
  });
  const [pageSize, setPageSize] = useState<number>(16);
  const [refresh, setRefresh] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);

  const refreshGridData = () => {
    setPage(0);
    setRefresh(refresh + 1);
  };

  const handleScroll = (event: any) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position < 1) {
      appendGridData();
    }
  };

  const fetchGridData = () => {
    setLoading(true);
    GenericService.list(DMSEndpoints.DMS_DEVICE_URI, {
      current: page + 1,
      pageSize: pageSize,
    })
      .then((response) => {
        // response.data =
        setGridData(response.data);
      })
      .catch((error) => {
        if (error?.response?.status < 500) {
          // do something when status is 4xx.
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const appendGridData = () => {
    setLoading(true);
    const newPage = page + 1;
    if (newPage >= gridData.num_pages) {
      return;
    }

    GenericService.list(ATTEndpoints.ATT_EMPLOYEE_PRESENT_STATUS_URI, {
      current: newPage + 1,
      pageSize: pageSize,
    })
      .then((response) => {
        setPage(newPage);
        response.data.data = gridData.data.concat(response?.data?.data);
        setGridData(response?.data);
      })
      .catch((error) => {
        // setPage(page - 1);
        if (error?.response?.status < 500) {
          // do something when status is 4xx.
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGridData();
  }, [refresh]);

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        sx={{
          height: "30px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginLeft: "5px",
        }}
      >
        <TitleTypography>
          {t("common.Device Overview")}
        </TitleTypography>

        <Tooltip title={t("common.refresh")}>
          <div>
            <ActionButton
              icon="icon-ic_refresh"
              handleClick={refreshGridData}
            ></ActionButton>
          </div>
        </Tooltip>
      </Grid>

      <Grid
        sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <CircularProgress
          sx={{
            position: "relative",
            left: "50%",
            top: "50%",
            marginTop: "25px",
            marginLeft: "25px",
            display: isLoading ? "block" : "none",
          }}
        />

        {!isLoading && (
          <Grid>
            {gridData?.data.map((item: any) => (
              <Grid
                key={item.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minHeight: "68px",
                  background: "#F8F8F8",
                  padding: "10px 12px",
                  boxSizing: "border-box",
                }}
              >
                <Grid
                  sx={{
                    width: "48px",
                    height: "48px",
                    borderRadius: "6px",
                    backgroundColor: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Badge
                    color="success"
                    badgeContent=""
                    variant="dot"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    sx={{
                      '.MuiBadge-badge': {
                        backgroundColor: item.status === '1' ? "#20cf61" : "#ef5656",
                        bottom: "7px", right: "7px", border: `3px solid #fff`,
                        height: "15px",
                        width: "15px",
                        minWidth: "15px",
                        borderRadius: "15px",
                      }
                    }}
                  >
                    <DeviceIcon/>
                  </Badge>
                </Grid>

                <Grid
                  sx={{
                    marginLeft: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px !important",
                      color: "#3D3D3D !important",
                      fontWeight: 'bold  !important'
                    }}
                  >
                    {item.alias}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px !important",
                      color: "#3D3D3D !important",
                      marginTop: "6px !important",
                    }}
                  >
                    {item.sn}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            {gridData?.total == 0 &&
              <Box sx={{
                mt: 1,
                color: "#000000",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: 'column',
                paddingTop: "20%",
                // paddingLeft: "5%"
              }}>
                <Box>
                  {t("common.No Rows")}
                </Box>
              </Box>
            }
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DeviceList;
