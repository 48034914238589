import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Grid, Paper, Box, TextField, Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {object, TypeOf, z} from "zod";
import {Controller, useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {zodResolver} from "@hookform/resolvers/zod";
import {SaveButton, SavingButton} from "@/components/data-grid/CustomButton";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import TimecardService from "@/services/att/services/TimecardService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {format} from 'date-fns'

type AutoShareProps = {
  report_type: string;
}

const AutoShare = (props: AutoShareProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const today = new Date();
  today.setHours(0, 0, 0, 0)
  const [enabled, setEnabled] = React.useState(false);
  const [unit, setUnit] = React.useState('3');
  const [isLoading, setLoading] = useState<boolean>(false);

  const exportSchema = object({
    actionDate:
      z.date(
        {
          errorMap: (issue, {defaultError}) =>
            ({message: issue.code === "invalid_date" ? t("common.Invalid date format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError})
        }),
    actionTime:
      z.date(
        {
          errorMap: (issue, {defaultError}) =>
            ({message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError})
        }),
    share_list: z.string().min(1, t("common.This field is required!"))
  })
  type ExportType = TypeOf<typeof exportSchema>;

  const shareSettingForm = useForm<ExportType>({
    resolver: zodResolver(exportSchema),
    defaultValues: {
      actionDate: today,
      actionTime: today,
    }
  });

  const scrollTo = (name: string) => {
    const anchorElement = document.getElementsByName(name)[0]
    if (anchorElement) {
      anchorElement.scrollIntoView({behavior: "smooth"})
    }
  }

  useEffect(() => {
    TimecardService.query_setting(props.report_type).then((res) => {
      if (Object.keys(res.data).length !== 0) {
        setEnabled(true)
        setUnit(res.data.unit)
        shareSettingForm.setValue('actionDate', new Date(res.data.date))
        shareSettingForm.setValue('actionTime', new Date(res.data.date))
        shareSettingForm.setValue('share_list', res.data.share.join(";"))
        // setDate(new Date(res.data.date))
      }
    });
  }, []);

  const onSubmit = async (formData: any) => {
    const {share_list, actionDate, actionTime} = formData
    let isInvalidEmail = false;
    const share_email = share_list.trim()
      .replace(/[\r\n\s]/g, "")
      .split(';')
      .filter((item: string) => {
        const email_pattern = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
        if (item !== "" && email_pattern.test(item)) {
          return true;
        }
        isInvalidEmail = true
        return false;
      });
    if (isInvalidEmail) {
      shareSettingForm.setError("share_list", {type: "custom", message: "invalid email format"}, {shouldFocus: true})
      return
    }
    const date = format(actionDate, 'yyyy-MM-dd')
    const time = format(actionTime, 'HH:mm:ss')
    const data = {
      "unit": unit,
      // "date": actionDate.toLocaleDateString() + " " + actionTime.toLocaleTimeString(),
      "date": date + ' ' + time,
      "share": share_email
    }
    setLoading(true)
    TimecardService.export_setting(data, props.report_type).then((result) => {
      if (result.status < 400) {
        SnackbarUtil.success(t("common.success"))
      } else {
        console.log("Failure===, ", result)
        SnackbarUtil.error(t("common.failure"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}})
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Grid>
      <Paper elevation={0} sx={{padding: '0 24px 24px'}}>
        <Grid container alignItems="center" sx={{padding: '10px 0'}}>
          <Typography variant="h6">{t("export.Automatic send")}</Typography>
          <Switch checked={enabled} onChange={(event) => setEnabled(event.target.checked)}/>
        </Grid>

        {enabled &&
          <Grid>
            <Grid sx={{padding: "8px 10px 0 0"}}>
              <Typography>{t("export.Send frequency")}</Typography>
              <Grid sx={{padding: '5px'}}>
                <RadioGroup row value={unit} onChange={(event) => setUnit(event.target.value)}>
                  <FormControlLabel value="0" control={<Radio/>} label="Daily"/>
                  <FormControlLabel value="1" control={<Radio/>} label="Weekly"/>
                  {/*<FormControlLabel value="2" control={<Radio />} label="Biweekly" />*/}
                  <FormControlLabel value="3" control={<Radio/>} label="Monthly"/>
                </RadioGroup>
              </Grid>
            </Grid>
            <Grid sx={{padding: "8px 10px 0 0"}}>
              <Typography sx={{marginBottom: '8px'}}>{t("export.First run at")}</Typography>
              <Grid container sx={{marginTop: '8px'}}>
                <Grid xs={6} item>
                  <Controller
                    name="actionDate"
                    control={shareSettingForm.control}
                    render={({field, fieldState}) =>
                      <DatePicker
                        value={field.value || null}
                        inputFormat={"dd-MM-yyyy"}
                        onChange={(newValue) => field.onChange(newValue)}
                        renderInput={(params) =>
                          <TextField
                            sx={{width: '420px'}}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message || ""}
                          />}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid container sx={{marginTop: '16px'}}>
                <Grid xs={6} item>
                  <Controller
                    name="actionTime"
                    control={shareSettingForm.control}
                    render={({field, fieldState}) =>
                      <TimePicker
                        value={field.value || null}
                        ampm={false}
                        onChange={(newValue) => field.onChange(newValue)}
                        renderInput={(params) =>
                          <TextField
                            sx={{width: '420px'}}
                            {...params}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message || ""}
                          />}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{marginTop: '8px'}}>
              <Box padding={"8px 0"}>
                <Typography sx={{color: "#999999"}}>{t("export.Auto email list")}</Typography>
              </Box>
              <ControlledTextFiled
                hookForm={shareSettingForm}
                sx={{width: '420px'}}
                name="share_list"
                multiline
                minRows={5}
                maxRows={5}
                type="text"
              />
            </Grid>

            <Grid sx={{display: 'flex', justifyContent: 'start', marginTop: '16px'}}>
              <SavingButton
                disableElevation
                loading={isLoading}
                type="submit"
                onClick={shareSettingForm.handleSubmit(onSubmit)}
                children={t("common.confirm")}
              />
            </Grid>
          </Grid>}
      </Paper>
    </Grid>
  );
}

export default AutoShare;

