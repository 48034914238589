const API_VER = 'v1.0'
export const ATTEndpoints = {
    ATT_PRESENT_SUMMARY_TODAY: `/att/api/${API_VER}/absent_summary/`,
    ATT_EMPLOYEE_PRESENT_STATUS_URI: `/att/api/${API_VER}/records/current_day_list/`,
    ATT_TIMESHEET_URI: `/att/api/${API_VER}/timesheet_ngteco/`,
    ATT_SCHEDULE_URI: `/att/api/${API_VER}/schedules_ngteco/`,
    ATT_PUNCH_RECORD_URI: `/att/api/${API_VER}/records/aggregation_list/`,
    ATT_PUNCH_RECORD_ADD_URI: `/att/api/${API_VER}/records/`,
    ATT_TIMECARD_URI: `/att/api/${API_VER}/timecard/`,
    ATT_REPORT_FIRST_LAST: `/att/report/api/${API_VER}/first_last/`,
    ATT_REPORT_FILO: `/att/report/api/${API_VER}/filo/`,
    ATT_REPORT_MONTHLY_REPORT: `/att/report/api/${API_VER}/monthly/`,
    ATT_REPORT_ATTENDANCE_REPORT: `/att/report/api/${API_VER}/attendance/`,
    ATT_COLLECTION_RULE: `/att/api/${API_VER}/collection_rule/`,
}
