import { Box, Grid, Breadcrumbs, Typography, TextField, Paper, InputAdornment } from "@mui/material";
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from "@hookform/resolvers/zod";
import { useSnackbar } from "notistack";
import { object, TypeOf, z } from "zod";
import React from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Util from "@/utils/Util";
import PunchRecordService from "@/services/att/services/PunchRecordService";
import EmpPicker from '@/components/EmpPicker';
import { SaveButton } from "@/components/data-grid/CustomButton";
import { useTranslation } from "react-i18next";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import {ReactComponent as ImgBanner} from '@/assets/images/ImgBanner.svg';
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";

const PunchAdd: React.FC = () => {
  const { t } = useTranslation();

  const PunchAddValidate = object({
    employee: z.string().nullable().optional(),
    employee_code: z.string().nullable().optional(),
    employeeObj:
      z.record(
        z.string(), z.string().nullable(), { required_error: t("common.This field is required!") }
      ),
    punchDate:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid date format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }),
    punchTime:
      z.date(
        {
          errorMap: (issue, { defaultError }) =>
            ({ message: issue.code === "invalid_date" ? t("common.Invalid time format.") : issue.code === "invalid_type" ? t("common.This field is required!") : defaultError })
        }),
    punch_time: z.string().optional(),
  })

  type PunchAddForm = TypeOf<typeof PunchAddValidate>;

  const punchAddForm = useForm<PunchAddForm>({
    resolver: zodResolver(PunchAddValidate),
    defaultValues: {
      // punchDate: new Date(),
      // punchTime: new Date(),
    }
  })

  const { enqueueSnackbar } = useSnackbar();
  const handleCreate = async () => {
    const data = punchAddForm.getValues()
    data.employee = data.employeeObj?.id
    data.employee_code = data.employeeObj?.employeeCode
    data.punch_time = Util.datetime2string(data.punchDate, 'yyyy-MM-dd') + ' ' + Util.datetime2string(data.punchTime, 'HH:mm:00')

    if (data.punchDate > new Date()) {
      enqueueSnackbar(t("common.Not allow future date"), {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      });
      punchAddForm.resetField('punchDate')
      return
    }

    PunchRecordService.addPunchRecord(data).then(res => {
      punchAddForm.reset()
      enqueueSnackbar(t("common.success"), {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center"
        }
      });
    })
  }

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "24px",
        width: "calc(100% - 24px)",
        backgroundColor: "#ffffff",
        height: "calc(100vh - 90px)",
        overflow: 'auto',
        position: 'relative',
      }}>
      <CustomBreadcrumbs record={[{label: t("common.Mend attendance punch")}]}/>
      <ImgBanner style={{ width: '100%', height: '240px' ,overflow: "initial", overflowClipMargin: 'initial',marginBottom: '20px'}} />
      <Grid>
        <Paper elevation={0}>
          <Grid container >
            <Grid item xs={12} sx={{ marginTop: "4px", width: "350px" }}>
              <Grid container={true} rowSpacing={2} columnSpacing={6}>
                <Grid item={true} xs={6}>
                  <Box sx={{marginBottom: '8px'}}>
                    <NGInputLabel label={t("common.Select user")} />
                  </Box>
                  <Controller
                    name="employeeObj"
                    control={punchAddForm.control}
                    render={({ field, fieldState }) =>
                      <EmpPicker
                        error={!!fieldState.error}
                        value={field.value}
                        onChange={field.onChange}
                        helperText={fieldState.error?.message || ""}
                        single={true}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container={true} rowSpacing={2} columnSpacing={6}>
                <Grid item={true} xs={6}>
                  <Box>
                    <NGInputLabel label={t("common.Set mend att-punch date")} />
                  </Box>
                  <Grid sx={{ paddingTop: '10px' }}>
                    <Controller
                      name="punchDate"
                      control={punchAddForm.control}
                      render={({ field, fieldState }) =>
                        <DatePicker
                          disableFuture
                          value={field.value || null}
                          onChange={(newValue) => field.onChange(newValue)}
                          // inputFormat="yyyy-MM-dd"
                          renderInput={(params) => {
                            return (
                              <TextField
                                sx={{ width: '100%' }}
                                {...params}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message || ""}
                              />
                            )
                          }

                          }
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container={true} rowSpacing={2} columnSpacing={6}>
                <Grid item={true} xs={6}>
                  <Box>
                    <Box>
                      <NGInputLabel label={t("common.Set mend att-punch time")} />
                    </Box>
                    <Grid sx={{ paddingTop: '10px' }}>
                      <Controller
                        control={punchAddForm.control}
                        name="punchTime"
                        render={({ field, fieldState }) =>
                          <TimePicker
                            value={field.value || null}
                            onChange={(newValue) => field.onChange(newValue)}
                            ampm={false}
                            renderInput={(params) =>
                              <TextField
                                sx={{ width: '100%' }}
                                {...params}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message || ""}
                              />
                            }
                          />
                        }
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Grid item sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%'}}>
          <SaveButton
            fullWidth
            onClick={punchAddForm.handleSubmit(handleCreate)}
            disableElevation
            sx={{ textTransform: "capitalize", width: '160px',marginTop: '12px', marginLeft: '24px' }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PunchAdd;
