import  "react-app-polyfill/ie11";
import  "react-app-polyfill/stable";
import * as React from 'react';
import theme from "./theme";
import App from "./containers/App";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import store from "./store";
import i18next from "./config/i18n";
import {ThemeProvider} from "@mui/material";
import { I18nextProvider } from "react-i18next";
import "./styles/font-icon/iconfont.css";
import './styles/main.css'


const container = document.getElementById('root');
// eslint-disable-next-line
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <I18nextProvider i18n={i18next} >
          <App/>
        </I18nextProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
)
