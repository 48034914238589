import React, { useEffect, useState, useRef} from "react";
import {
  Box,
  Grid,
  Paper,
} from "@mui/material";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import { useSelector } from "react-redux";
import { object, TypeOf, z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import UserService from "@/services/auth/UserService";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {useTranslation} from "react-i18next";
import CustomRowButton from "@/components/data-grid/CustomRowButton";
import {ReactComponent as IconEdit} from '@/assets/icons/IconEdit.svg';
import ProfileEdit from './components/ProfileEdit'
const UserProfileView: React.FC = () => {
  const profileEditRef = useRef<any>({})
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.app.user);
  const [profilePhoto, setProfilePhoto] = useState<string>("");
  const userProfileValidate = object({
    firstName: z.string().min(1, t("common.This field is required!")),
    lastName: z.string().min(1, t("common.This field is required!")),
    email: z.string().min(1, t("common.This field is required!"))
  })

  type UserProfileForm = TypeOf<typeof userProfileValidate>;

  const profileForm = useForm<UserProfileForm>({
    resolver: zodResolver(userProfileValidate)
  });
  const {
    setValue,
    formState: { errors },
  } = profileForm;

  useEffect(() => {
    queryData()
  }, []);

  const queryData = () => {
    UserService.userInfo().then((res) => {
      setProfilePhoto(res.data.imagePreSignedURL)
      setValue("firstName", res.data.firstName);
      setValue("lastName", res.data.lastName);
      setValue("email", res.data.email);
    });
  }

  const reloadData = () => {
    queryData()
  }

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0",
        width: "calc(100% - 24px)",
        backgroundColor: "#ffffff",
        minHeight: "calc(100vh - 94px)",
        marginBottom: '24px',
        boxSizing: 'border-box'
      }}>
      <CustomBreadcrumbs record={[{label: t("common.Account Profile")}]}/>
      <Box style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: '100%', borderBottom: '1px solid #DEE0E3', paddingRight: '24px', boxSizing: 'border-box'}}>
        <CustomRowButton insideSvgIcon={IconEdit} actionName={t("common.edit")} buttonProps={{
          key: "edit",
          onClick: () => {
            profileEditRef?.current?.showDetail()
          }
        }}/>
      </Box>
      <Paper elevation={0} sx={{margin: '10px 10px'}}>
        <Grid container sx={{ p: '20px' }}>
          <Grid lg={6} item sx={{ height: '45vh', display: 'flex', justifyContent: 'start', padding: '8px' }}>
            <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '1px solid #DEE0E3', width: '90%', height: '100%', lineHeight: '50px', minWidth: '500px', borderRadius: '6px', marginRight: '36px' }}>
              <Box sx={{ height: '200px' }}>
                <Grid>
                  <Box>
                    <AvatarIcon name={user?.firstName ? user.firstName : "None"} diameter={160} profilePhoto={profilePhoto}/>
                  </Box>
                  {/*<Box sx={{ "textAlign": "center", "width": "100%" }} >*/}
                  {/*  {user?.firstName ? user.firstName : ""} {user?.lastName ? user.lastName : ""}*/}
                  {/*</Box>*/}
                </Grid>
              </Box>
            </Grid >
          </Grid>
          <Grid xs={6} item>
            <Grid container={true} rowSpacing={2}>
              <Grid item xs={12}>
                <ControlledTextFiled
                  hookForm={profileForm}
                  name={'firstName'}
                  label={t("signUp.First Name")}
                  fullWidth
                  disabled={true}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextFiled
                  hookForm={profileForm}
                  name={'lastName'}
                  label={t("signUp.Last Name")}
                  fullWidth
                  disabled={true}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextFiled
                  hookForm={profileForm}
                  name={'email'}
                  label={t("signUp.Enter the email")}
                  fullWidth
                  disabled={true}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <ProfileEdit ref={profileEditRef} reloadData={reloadData}></ProfileEdit>
    </Grid>
  )
}
export default UserProfileView;
