import {Translation} from "react-i18next";

const Translate = (key : string, options ?: any) => {
  return (
    <Translation>
      { t => t(key, options)}
    </Translation>
  )
}
const TranslationUtil = {
  t: Translate
}
export default TranslationUtil