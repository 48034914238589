import React, { useState } from "react";
import NGTextField from "@/components/ngteco-form/NGTextField";
import {IconButton, InputAdornment, TextFieldProps} from "@mui/material";
import {Controller, UseFormReturn} from "react-hook-form";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';


type ControlledPasswordFiledProps = Omit<TextFieldProps, 'name'> & {
  hookForm: UseFormReturn<any>;
  name: string;
  tooltip?: string
}

const ControlledPasswordFiled: React.FC<ControlledPasswordFiledProps> = (props) => {
  const {hookForm, name, ...textFieldProps} = props

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  return (
    <Controller
      control={hookForm.control}
      render={({field, fieldState}) => {
        return (
          <NGTextField
            {...textFieldProps}
            {...field}
            type={showPassword ? 'text' : 'password'}
            error={!!fieldState.error}
            helperText={fieldState.error?.message || ""}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            }}
          />
        )
      }}
      name={name}
    />
  )
}
export default ControlledPasswordFiled
