import React, { useState, useImperativeHandle, forwardRef } from "react";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import DeviceConnGuide from "./DeviceConnGuide";
import DeviceRegister from "./DeviceRegister";
import DeviceBinding from "./DeviceBinding";
import { useTranslation } from "react-i18next";
import WebURL from "@/urls";
import { Drawer, Grid } from "@mui/material";

const DeviceAdd = (props: any, ref: any) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<string>("guide");
  const [open, setOpen] = useState(false);
  const [device, setDevice] = useState<any>({});
  const onClose = () => {
    setOpen(false);
  };
  useImperativeHandle(ref, () => ({
    showAdd: async () => {
      setStep("guide");
      setOpen(true);
    },
  }));

  const setDeviceInfo = (deviceInfo: any) => {
    setDevice(deviceInfo);
  };

  const cloaseDrawer = (isReloadTable: boolean) => {
    setOpen(false);
    if (isReloadTable && props.reloadTable()) {
      props.reloadTable();
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Grid
        sx={{
          minWidth: "400px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "56px",
            flexShrink: "0",
            padding: "0px 24px",
            borderBottom: "1px solid #F2F5F8",
          }}
        >
          <Grid>{t("dms.Add Device")}</Grid>
          <Grid
            sx={{
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            <i className="icon iconfont icon-ic_close"></i>
          </Grid>
        </Grid>
        <Grid
          sx={{
            padding: "0px 24px",
            flexGrow: "1",
            display: "flex",
          }}
        >
          {step === "guide" && <DeviceConnGuide setStep={setStep} />}
          {step === "register" && (
            <DeviceRegister setDeviceInfo={setDeviceInfo} setStep={setStep} />
          )}
          {step === "DeviceBinding" && (
            <DeviceBinding  cloaseDrawer={cloaseDrawer} device={device} />
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default forwardRef(DeviceAdd);
