import DeviceService from "@/services/dms/DeviceService"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


const initialState = {
  devices: [],
}

export const getOrganDevice = createAsyncThunk('device/byOrgan', async () => {
  const {data} = await DeviceService.list({current: 1, pageSize: 50});
  return data;
})

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganDevice.fulfilled, (state, action) => {
      return {
        devices: action.payload.data
      }
    })
  }
});

export default deviceSlice.reducer;
