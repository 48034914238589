import React from "react";
import {Route, Routes} from "react-router-dom";
import UserLogin from "./pages/auth/Login";
import EmployeeDataGrid from "./pages/hr/Employee";
import OrganizationSelection from "./pages/auth/Login/components/OrganizationSelection";
import TimesheetDataGrid from "./pages/att/Timesheet";
import ScheduleDataGrid from "./pages/att/Schedule";
import MainLayout from "./parts/MainLayout";
import UserProfileView from "./pages/user/profile"
import AboutView from "./pages/user/About/AboutView"
import UserAgreementView from "./pages/user/About/components/UserAgreement"
import PrivacyPolicyView from "./pages/user/About/components/PrivacyPolicy"
import InfoCollectionFormView from "./pages/user/About/components/InfoCollectionForm"
import ComplianceView from "./pages/user/About/components/Compliance"
import ResetPasswordView from "./pages/user/reset"
import Dashboard from "./pages/dashboard";
import ReportHome from "./pages/att/Report";
import FirstAndLast from "./pages/att/Report/components/FirstAndLast";
import FirstInLastOut from "./pages/att/Report/components/FirstInLastOut";
import MonthlyReport from "./pages/att/Report/components/MonthlyReport";
import AttendanceReport from "./pages/att/Report/components/AttendanceReport";
import AttendanceReportShare from "./pages/att/Report/components/AttendanceReportShare";
import DeviceGridData from "./pages/dms/Device";
import TimecardHome from "./pages/att/Timecard";
import Timecard from "./pages/att/Timecard/components/Timecard";
import PunchRecordGrid from "./pages/att/Timecard/components/PunchRecord";
import PunchRecordAdd from "./pages/att/Timecard/components/MendPunchRecord";
import AttRuleHome from "./pages/att/RuleSetting/index"
import NewTimesheet from "./pages/att/Timesheet/component/NewTimesheet";
import WebURL from "./urls";
import NewSchedule from "./pages/att/Schedule/components/NewSchedule";
import Zlink from "./pages/auth/Zlink";
import EmployeeView from "./pages/hr/Employee/components/EmployeeView";
import DeviceView from "./pages/dms/Device/components/DeviceView"
import PersonInDevice from "./pages/dms/Device/components/DevicePersonAction/PersonInDevice"
import SignUp from "@/pages/auth/Login/components/SignUp";
import ForgotPassword from "@/pages/auth/Login/components/ForgotPassword"
import SignUpUserAgreement from "@/pages/auth/Login/components/SignUpUserAgreement";
import SignUpPrivacyPolicy from "@/pages/auth/Login/components/SignUpPrivacyPolicy";
import OrganCreation from "@/pages/auth/Login/components/OrganCreation";
import CollectionRuleList from "./pages/att/RuleSetting/components/CollectionRule";
import ViewCollectionRule from "./pages/att/RuleSetting/components/CollectionRule/ViewCollectionRule";
// import DeviceAdd from "./pages/dms/Device/components/DeviceAdd";
import DepartmentDataGrid from "./pages/hr/Department";
import DepartmentView from "./pages/hr/Department/components/DepartmentView";
import SiteDataGrid from "@/pages/location/Site";
import AddSite from "@/pages/location/Site/components/AddSite";
import SiteView from "@/pages/location/Site/components/SiteView";
import OrganView from "./pages/auth/Login/components/OrganView";
import OrganAdd from "./pages/company/OrganAdd";
import OrganSwitch from "./pages/company/OrganSwitch";
import ZoneDataGrid from "@/pages/location/Zone";
import AddZone from "@/pages/location/Zone/components/AddZone";
import ZoneView from "@/pages/location/Zone/components/ZoneView";
import ReportShare from "./pages/att/Timecard/components/Timecard/ReportShare";
import ViewSchedule from "./pages/att/Schedule/components/ViewSchedule";
import DeviceEdit from "./pages/dms/Device/components/DeviceEdit";
import CredentialManagement from "./pages/setting/credential";
import EmployeeNew from "./pages/hr/Employee/components/EmployeeNew";
import ResignationDataGrid from "./pages/hr/Resignation";
import ResignedEmployeeView from "./pages/hr/Resignation/components/ResignedEmployeeView"
import BeatTask from "./pages/log/BeatTask/index"
import AdminLog from "@/pages/base/AdminLog/index"
import QuickStart from "@/pages/quick/index";


const MenuRoutes = () => {
  return (
    <Routes>
      <Route path={WebURL.AUTH_USER_LOGIN} element={<UserLogin/>}/>
      <Route path={WebURL.AUTH_SIGN_UP} element={<SignUp/>}/>
      <Route path={WebURL.AUTH_FORGOT_PASSWORD} element={<ForgotPassword/>}/>
      {/*<Route path={WebURL.AUTH_USER_AGREEMENT} element={<SignUpUserAgreement/>}/>*/}
      {/*<Route path={WebURL.AUTH_PRIVACY_POLICY} element={<SignUpPrivacyPolicy/>}/>*/}
      <Route path={WebURL.AUTH_USER_COMPANY_SELECTION} element={<OrganizationSelection/>}/>
      <Route path={WebURL.AUTH_USER_COMPANY_CREATION} element={<OrganCreation/>}/>
      <Route path={"/"} element={<MainLayout/>}>
        <Route path={WebURL.AUTH_USER_ABOUT} element={<AboutView/>}/>
        <Route path={WebURL.AUTH_USER_ABOUT_USER_AGGREMENT} element={<UserAgreementView/>}/>
        <Route path={WebURL.AUTH_USER_ABOUT_PRIVACY_POLICY} element={<PrivacyPolicyView/>}/>
        <Route path={WebURL.AUTH_USER_ABOUT_INFO_COLLECTION_FORM} element={<InfoCollectionFormView/>}/>
        <Route path={WebURL.AUTH_USER_ABOUT_COMPLIANCE} element={<ComplianceView/>}/>
        <Route path={WebURL.AUTH_USER_PROFILE} element={<UserProfileView/>}/>
        <Route path={WebURL.AUTH_USER_RESET_PASSWORD} element={<ResetPasswordView/>}/>
        <Route path={WebURL.DASHBOARD} element={<Dashboard/>}/>
        <Route path={WebURL.QUICKSTART} element={<QuickStart/>}/>
        <Route path={WebURL.AUTH_USER_COMPANY_ADD} element={<OrganAdd/>}/>
        <Route path={WebURL.AUTH_USER_COMPANY_SWITCH} element={<OrganSwitch/>}/>
        <Route path={WebURL.AUTH_USER_COMPANY_VIEW} element={<OrganView/>}/>
        <Route path={WebURL.HR_EMPLOYEE} element={<EmployeeDataGrid/>}/>
        <Route path={WebURL.HR_EMPLOYEE_NEW} element={<EmployeeNew/>}/>
        <Route path={WebURL.HR_EMPLOYEE_VIEW} element={<EmployeeView/>}/>
        <Route path={WebURL.HR_DEPARTMENT} element={<DepartmentDataGrid/>}/>
        <Route path={WebURL.HR_DEPARTMENT_NEW} element={<DepartmentView/>}/>
        <Route path={WebURL.HR_DEPARTMENT_VIEW} element={<DepartmentView/>}/>
        <Route path={WebURL.HR_RESIGNATION} element={<ResignationDataGrid/>}/>
        <Route path={WebURL.HR_RESIGNATION_VIEW} element={<ResignedEmployeeView/>}/>

        <Route path={WebURL.ATT_RULE_HOME} element={<AttRuleHome/>}/>
        <Route path={WebURL.ATT_TIMESHEET} element={<TimesheetDataGrid/>}/>
        <Route path={WebURL.ATT_TIMESHEET_NEW} element={<NewTimesheet/>}/>
        <Route path={WebURL.ATT_TIMESHEET_VIEW} element={<NewTimesheet/>}/>
        <Route path={WebURL.ATT_CLOCKING_COLLECTION_RULE} element={<CollectionRuleList/>}/>
        <Route path={WebURL.ATT_CLOCKING_COLLECTION_RULE_VIEW} element={<ViewCollectionRule/>}/>

        <Route path={WebURL.ATT_SCHEDULE} element={<ScheduleDataGrid/>}/>
        <Route path={WebURL.ATT_SCHEDULE_NEW} element={<NewSchedule/>}/>
        <Route path={WebURL.ATT_SCHEDULE_VIEW} element={<ViewSchedule/>}/>

        <Route path={WebURL.ATT_TIMECARD_HOME} element={<TimecardHome/>}/>
        <Route path={WebURL.ATT_TIMECARD_PUNCH_RECORD} element={<PunchRecordGrid/>}/>
        <Route path={WebURL.ATT_TIMECARD_PUNCH_RECORD_ADD} element={<PunchRecordAdd/>}/>
        <Route path={WebURL.ATT_TIMECARD_TIMECARD} element={<Timecard/>}/>
        <Route path={WebURL.ATT_TIMECARD_TIMECARD_EXPORT} element={<ReportShare/>}/>

        <Route path={WebURL.ATT_REPORT_HOME} element={<ReportHome/>}/>
        <Route path={WebURL.ATT_REPORT_FIRST_LAST} element={<FirstAndLast/>}/>
        <Route path={WebURL.ATT_REPORT_FILO} element={<FirstInLastOut/>}/>
        <Route path={WebURL.ATT_REPORT_MONTHLY_REPORT} element={<MonthlyReport/>}/>
        <Route path={WebURL.ATT_REPORT_ATTENDANCE_REPORT} element={<AttendanceReport/>}/>
        <Route path={WebURL.ATT_REPORT_ATTENDANCE_REPORT_EXPORT} element={<AttendanceReportShare/>}/>

        <Route path={WebURL.DMS_DEVICE} element={<DeviceGridData/>}/>
        {/* <Route path={WebURL.DMS_DEVICE_ADD} element={<DeviceAdd/>}/> */}
        <Route path={WebURL.DMS_DEVICE_VIEW} element={<DeviceView/>}/>
        <Route path={WebURL.DMS_DEVICE_EDIT} element={<DeviceEdit/>}/>
        <Route path={WebURL.DMS_DEVICE_PERSONS} element={<PersonInDevice/>}/>
        <Route path={WebURL.ZKLINK_FORWARD} element={<Zlink/>}/>

        <Route path={WebURL.LOCATION_SITE} element={<SiteDataGrid/>}/>
        <Route path={WebURL.LOCATION_SITE_ADD} element={<AddSite/>}/>
        <Route path={WebURL.LOCATION_SITE_EDIT} element={<SiteView/>}/>

        <Route path={WebURL.LOCATION_ZONE} element={<ZoneDataGrid/>}/>
        <Route path={WebURL.LOCATION_ZONE_ADD} element={<AddZone/>}/>
        <Route path={WebURL.LOCATION_ZONE_EDIT} element={<ZoneView/>}/>

        <Route path={WebURL.SETTING_CREDENTIAL} element={<CredentialManagement/>}/>

        <Route path={WebURL.BEAT_TASK} element={<BeatTask/>}/>
        <Route path={WebURL.ADMIN_LOG} element={<AdminLog/>}/>

      </Route>
    </Routes>
  )
}
export default MenuRoutes
