import { GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Grid,
  Breadcrumbs,
  Typography,
  Badge,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Height, Refresh } from "@mui/icons-material";
import { ATTEndpoints } from "../../../services/att/endpoints";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import GenericService from "@/services/GenericService";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import CircularProgress from "@mui/material/CircularProgress";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import ActionButton from "@/components/actions/ActionButton";
import { TitleTypography} from './commonCmp'
type GridData = {
  data: any;
  total: number;
  num_pages: number;
};
type FilterType = {
  date_range: [any, any];
};

const customDataGridStyle = {
  height: "100%",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F0F1F4!important",
    color: "#304669!important",
  },
  ".MuiDataGrid-columnHeader:focus-within": { outline: "none" },
  ".MuiDataGrid-cell": { borderBottom: "0px", color: "#304669" },
  ".MuiDataGrid-cell:focus-within": {
    outline: "none!important",
    color: "#2BA9DE!important",
  },
  ".MuiDataGrid-columnSeparator": { display: "none !important" },
  ".MuiSvgIcon-root": { backgroundColor: "transparent" },
  ".MuiDataGrid-root": { padding: "8px", borderRadius: "4px" },
  ".MuiDataGrid-row": {
    borderBottom: "1px solid #E7EBF0",
    maxHeight: "48px!important",
    minHeight: "48px!important",
  },
  ".MuiCheckbox-root": { color: "#D1D1D1" },
  ".MuiDataGrid-cellContent": {
    color: "#304669!important",
    fontFamily: "Roboto",
    letterSpacing: "0.4px",
    fontSize: "14px",
  },
  ".MuiDataGrid-virtualScrollerRenderZone": { backgroundColor: "#FFFF" },
  ".MuiDataGrid-footerContainer": {
    display: "block",
    width: "100%",
    border: "0px",
    height: "100%",
  },
  ".MuiTablePagination-spacer": { display: "none" },
  ".MuiTablePagination-actions": {
    position: "absolute",
    right: "0px",
    backgroundColor: "#FFF",
    '& .MuiIconButton-root': {
      padding:'4px',
      margin: '0px 4px',
      border: '1px solid #DEE0E3',
      'border-radius': '6px',
    }
  },
  ".MuiTablePagination-displayedRows": { position: "absolute", right: "110px" },
  // '.MuiDataGrid-virtualScroller': {overflow: 'hidden'},
  ".MuiTablePagination-select": {
    backgroundColor: "#F0F1F4",
    borderRadius: "4px!important",
  },
  ".MuiTablePagination-toolbar": { paddingLeft: "0px" },
  ".MuiIconButton-root.Mui-disabled": {
    backgroundColor: "#F0F1F4",
    borderRadius: "0px",
  },
  // '.MuiIconButton-root': {borderRadius: '0px'},
  ".Mui-checked": { color: "rgb(54, 201, 109)!important" },
};

const AttendanceRecord: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [gridEmpData, setEmpGridData] = useState<GridData>({
    data: [],
    total: 0,
    num_pages: 0,
  });
  const [params, setParams] = useState<FilterType>({
    date_range: [new Date(), new Date()],
  });

  const { pageSize, setPageSize, page, setPage, refresh, setRefresh } = props;

  const fetchEmpGridData = () => {
    setLoading(true);
    const { date_range } = params;
    date_range.forEach((element, index) => {
      params.date_range[index] = new Date(element).toISOString().slice(0, 10);
    });
    GenericService.list(ATTEndpoints.ATT_PUNCH_RECORD_URI, {
      current: page + 1,
      pageSize: pageSize,
      ...params,
    })
      .then((response) => {
        setEmpGridData(response?.data);
      })
      .catch((error) => {
        if (error.response?.status < 500) {
          // do something when status is 4xx.
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEmpGridData();
  }, [page, pageSize, refresh]);

  const refreshGridData = () => {
    setRefresh(refresh + 1);
  };

  const columns: GridColDef[] = [
    {
      field: "employee_name",
      headerName: t("common.Person Name"),
      flex: 0.1,
      renderCell: (params) => {
        return (
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ padding: "0 8px" }}>
              {params.row?.first_name} {params.row?.last_name}
            </Box>
          </Grid>
        );
      },
    },
    {
      field: "punch_record",
      headerName: t("common.Punch Record"),
      flex: 0.3,
      renderCell: (params) => {
        const res: any[] = [];
        params.row.attendance_status.forEach((element: any, index: number) => {
          const val = element.punch_time.split(":");
          const hour = Number(val[0]);
          const fillHour = (hour: number) =>
            hour < 10 ? `0${hour}` : hour.toString();
          // val[0] = hour <= 12 ? 'AM ' + fillHour(hour) : 'PM ' + fillHour(hour - 12);
          if (element.status === "warning") {
            res.push(
              <Grid key={index} component={"a"} color={"red"}>{`${val.join(
                ":"
              )};`}</Grid>
            );
          } else {
            res.push(
              <Grid key={index} component={"a"}>
                {`${val.join(":")};`}{" "}
              </Grid>
            );
          }
        });
        return <Grid>{res}</Grid>;
      },
    },
  ];

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "50px",
          marginLeft: "5px",
        }}
      >
        <TitleTypography>
          {t("common.Daily Attendance Record")}
        </TitleTypography>

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{
            display: "inline-block", width: "6px", height: "6px", borderRadius: "50%",
            backgroundColor: "#F65555"
          }}></Box>
          <Typography
            sx={{
              fontWeight: "400 !important",
              fontSize: "12px !important",
              color: "#999999  !important",
              marginLeft: "10px !important",
            }}
          >
            {t("common.The red attendance record is mended data")}
          </Typography>
          <Tooltip title={t("common.refresh")}>
            <div>
              <ActionButton
                icon="icon-ic_refresh"
                handleClick={refreshGridData}
              ></ActionButton>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        sx={{
          flexGrow:1
        }}
      >
        <DataGrid
          columns={columns}
          loading={isLoading}
          pagination={true}
          paginationMode={"server"}
          pageSize={pageSize}
          headerHeight={40}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage);
          }}
          rows={gridEmpData?.data}
          rowCount={gridEmpData?.total ? gridEmpData?.total : 0}
          hideFooterSelectedRowCount={true}
          disableColumnMenu={true}
          style={{ border: "none" }}
          components={{}}
          componentsProps={{
            pagination: {
              labelRowsPerPage: t("common.datagrid.paginate"),
            },
          }}
          sx={customDataGridStyle}
        />
      </Grid>
    </Grid>
  );
};
export default AttendanceRecord;
