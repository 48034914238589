import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import theme from "../../theme";
import { I18nextProvider } from "react-i18next";
import i18n from "../../config/i18n";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import CustomTooltip from "@/components/breadcrumbs/CustomToolTip";

export type DialogFuncProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  helpInfo?: string;
  description?: React.ReactNode;
  content?: React.ReactNode;
  width?: string;
  isOK?: boolean;
  onConfirm?: () => any;
};

type ConfirmDialogProps = DialogFuncProps & {
  mode?: string;
  close: () => void;
  open: boolean;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ close, ...props }) => {
  const { t } = useTranslation();
  const {
    icon,
    title,
    helpInfo,
    description,
    content,
    width,
    isOK,
    onConfirm,
  } = props;
  const currentTheme = useTheme();
  const [open, setOpen] = React.useState(props.open);
  const handleClose = () => {
    setOpen(false);
  };
  const fullScreen = useMediaQuery(currentTheme.breakpoints.down("md"));
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              ".MuiDialog-container": {
                ".MuiPaper-root": {
                  borderRadius: "6px",
                  boxShadow: "0 0 black",
                  padding: "0px",
                  width: width,
                },
              },
            }}
          >
            <Grid
              sx={{
                display: "flex",
                padding: "10px",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "inline-block",
                    fontSize: "16px",
                    fontWeight: "normal",
                    color: "#999999",
                  }}
                >
                  {(description || content) && (title)}
                </Box>
                {helpInfo && (
                  <CustomTooltip helpInfo={helpInfo} anchor={"right"} />
                )}
              </Grid>
              <Grid onClick={close}>
                <i
                  style={{
                    cursor: "pointer",
                    fontSize: "12px",
                  }}
                  className="icon iconfont icon-ic_close"
                ></i>
              </Grid>
            </Grid>
            {description && content && <Divider></Divider>}

            {(description || content) && (
              <DialogContent sx={{ borderTop: "0px", color: "#000000", textAlign: "center", }}>
                <Typography>{description}</Typography>
                <Typography>{content}</Typography>
              </DialogContent>
            )}

            {!(description || content) && (
              <DialogContent sx={{ borderTop: "0px", color: "#939EB0" }}>
                <Typography>{title}</Typography>
              </DialogContent>
            )}
            <DialogActions sx={{ padding: "0px" }}>
              {isOK ? (
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={0}
                  sx={{ width: "100%" }}
                >
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <Button
                      sx={{
                        width: "100%",
                        height: "56px",
                        textTransform: "none",
                      }}
                      variant="contained"
                      onClick={close}
                    >
                      OK
                    </Button>
                  </Box>
                </Stack>
              ) : (
                <Grid spacing={0}
                      sx={{
                        padding: '10px',
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end"
                      }}
                >
                  <Button
                    sx={{
                      color: "#304669",
                      textTransform: "none",
                      marginRight:"20px"
                    }}
                    variant="outlined"
                    onClick={close}
                  >
                    {t("common.cancel")}
                  </Button>

                  <Button
                    sx={{
                      textTransform: "none",
                    }}
                    variant="contained"
                    onClick={() => {
                      const hold = onConfirm?.();
                      close();
                    }}
                  >
                    {t("common.confirm")}
                  </Button>
                </Grid>
              )}
            </DialogActions>
          </Dialog>
        </LocalizationProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
};
export default ConfirmDialog;
