import React from "react";
import {SnackbarContent, CustomContentProps} from "notistack"
import {emphasize, useTheme} from "@mui/material";

const CustomSuccess = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const theme = useTheme()
  const mode = theme.palette.mode
  const backgroundColor = emphasize(theme.palette.background.default, mode === 'light' ? 0.8 : 0.98);
  const {id, message, iconVariant, ...other} = props
  const styles = {
    contentRoot: {
      ...theme.typography.body2,
      alignItems: 'center',
      padding: '6px 16px',
      borderRadius: '4px',
      boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
      color: theme.palette.getContrastText(backgroundColor)
    },
    success: {
      backgroundColor: theme.palette.primary.main,
    },
    message: {
      display: "flex",
      alignItems: "center",
      padding: '8px 0'
    }
  }
  return (
    <SnackbarContent ref={ref} role={"alert"} {...other} className={'notistack-MuiContent'} style={{
      ...styles.contentRoot,
      ...styles.success
    }} >
      <div id={'notistack-snackbar'} style={styles.message}>
        {iconVariant.success}
        {message}
      </div>
    </SnackbarContent>
  )
})
export default CustomSuccess
