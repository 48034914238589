import React, {useEffect, useState} from "react";
import {useParams, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {grey} from "@mui/material/colors";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import {Avatar, Grid, Paper, Typography, Box, Switch, Badge} from "@mui/material";
import DeviceService from '@/services/dms/DeviceService';
import GenericService from "@/services/GenericService";
import {DMSEndpoints} from "@/services/dms/endpoints";
import Dialog from "@/components/dialog"
import CustomTooltip from "@/components/breadcrumbs/CustomToolTip"
import SnackbarUtil from '@/utils/SnackbarUtil';

type DeviceProps = {
  alias?: string,
  site_name: string,
  zone_name: string,
  status: string,
  sn?:string,
  registration_device: boolean
}

const DeviceInfoHeader: React.FC = () => {
  const location = useLocation()
  const state = location.state as { id: string }
  const { t } = useTranslation();
  const [device, setDevice] = useState<DeviceProps>({alias:"", site_name:"",sn:'', zone_name:"", status:"", registration_device: false});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const checked_msg = checked==true? t('dms.enable'):t('dms.disable')
    Dialog.confirm({
      title: t("common.Are you sure?"),
      content: t("dms.Do you want to registration?", {msg: checked_msg}),
      onConfirm: () => {
        DeviceService.update(state.id || '', {"registrationDevice": Number(checked)}).then(
          (result) => {
            console.log('DeviceService.changeRegistration: ', result)
            setDevice({...device, registration_device: checked});
            SnackbarUtil.success(checked_msg+t('common.success'))
          }
        );
      },
    });
  };

  useEffect(() => {
    GenericService.retrieve(DMSEndpoints.DMS_DEVICE_URI, state.id || "").then((res) => {
      setDevice(res.data)
    })
  }, [])
  return (
    <>
      <Grid style={{ minHeight: '80px', backgroundColor: '#fff', marginTop: '10px' }}>
        <Grid container direction='row'  justifyContent='flex-start' alignItems='center' spacing={1}>
          <Grid item style={{display: "flex", alignItems: "center"}}  md={4}>
            <Badge
              color="success"
              variant="dot"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              sx={{
                '.MuiBadge-badge': {
                  backgroundColor: device.status === '1' ? "#20cf61" : "#ef5656",
                  bottom: "7px", right: "7px", border: `3px solid #fff`,
                  height: "15px",
                  width: "15px",
                  minWidth: "15px",
                  borderRadius: "15px",
                }
              }}
            >
            <Avatar  variant="rounded" src={"/images/device/deviceIcon.png"} sx={{ width: 48, height: 48, bgcolor: grey[100], marginLeft: 1}}>
              <AdUnitsIcon sx={{ fontSize: 50 }} color="action"/>
            </Avatar>
            </Badge>
            <Box style={{ paddingLeft: "20px" }}>
              <Typography sx={{
                fontSize:"16px  !important"
              }}>{device?.sn||device?.alias}</Typography>
              <Typography  sx={{
                fontSize: "12px  !important",
                color:'#919DA1'
              }}>{`${t("common.Site")}: ${device?.site_name || ""}`}</Typography>
              <Typography  sx={{
                fontSize: "12px  !important",
                color:'#919DA1'
              }}>{`${t("common.Zone")}: ${device?.zone_name || ""}`}</Typography>
            </Box>
          </Grid>
          {/* <Grid item md={4}>
            <Typography>
               {t("dms.Set as registration device")}
              <CustomTooltip helpInfo={t("dms.Regisration device tip")}/>
              <Switch
                color="primary"
                checked={device.registration_device}
                onChange={handleChange}
                />
            </Typography>
          </Grid> */}
        </Grid>
      </Grid>
    </>
  )
}
export default DeviceInfoHeader
