import React from "react";
import {Link, LinkProps, Typography} from "@mui/material";

type CustomLinkProps = LinkProps & {
  label: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({label, ...props}) => {
  return (
    <Link
      underline={"none"}
      {...props}
      sx={{
        cursor: "pointer",
        padding: "0 8px",
        ...props.sx || {},
      }}
    >
      <Typography sx={{fontSize: "14px"}}>{label}</Typography>
    </Link>
  )
}
export default CustomLink
