import confirm, {withConfirm, withInfo, withWarn, withOK} from "./confirm";
import {DialogFuncProps} from "./ConfirmDialog";

const Dialog = {
  confirm: (props: DialogFuncProps) => {
    return confirm(withConfirm(props));
  },
  info: (props: DialogFuncProps) => {
    return confirm(withInfo(props));
  },
  ok: (props: DialogFuncProps) => {
    return confirm(withOK(props));
  },
  warn: (props: DialogFuncProps) => {
    return confirm(withWarn(props));
  }
}
export default Dialog
