import React, {useState, useEffect} from "react";
import {
  Box,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
  Menu,
  MenuItem,
  ListItemText,
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import WebURL from "../../urls";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import {useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, {AccordionProps} from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import jwtDecode from "jwt-decode";
import {ReactComponent as MenuOrgan} from "@/assets/icons/MenuOrgan.svg";
import {ReactComponent as MenuAtt} from "@/assets/icons/MenuAtt.svg";
import {ReactComponent as MenuDashboard} from "@/assets/icons/MenuDashboard.svg";
import {ReactComponent as QuickStart} from "@/assets/icons/QuickStartIcon.svg";
import {ReactComponent as LogIcon} from "@/assets/icons/LogIcon.svg";
import {ReactComponent as MenuDevice} from "@/assets/icons/MenuDevice.svg";
import {ReactComponent as MenuSetting} from "@/assets/icons/MenuSetting.svg";
import {ReactComponent as MenuOpen} from "@/assets/icons/MenuOpen.svg";
import {ReactComponent as MenuClose} from "@/assets/icons/MenuClose.svg";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import PopupState, {bindHover, bindMenu} from "material-ui-popup-state";
import {usePopupState} from "material-ui-popup-state/hooks";
import {useTranslation} from "react-i18next";
import OrganSwitch from "./OrganSwitch";
import {AuthEndpoints} from "@/services/auth/endpoints";
import GenericService from "@/services/GenericService";
import CompanyService from "@/services/auth/CompanyService";

type GridData = {
  data: any;
  total: number;
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{fontSize: "14px !important", color: "#cccccc"}}
      />
    }
    {...props}
  />
))(({theme}) => ({
  backgroundColor: "#fff",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    display: "flex",
    Height: "56px",
    alignItems: "center",
    "&:hover": {
      background: "#e5f2fa",
    },
  },
  minHeight: "40px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  backgroundColor: "#fff",
  padding: "0px",
  display: "flex",
  flexDirection: "column",
}));

type MenuProps = {
  key: string;
  title: string;
  path: string;
  childrens?: any;
  icon?: any;
};

type TokenProps = {
  roleName: string;
};

type MenuItemProps = {
  hidePopup?: () => void;
};

const SwitchCompanyItem: React.FC<MenuItemProps> = (props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const SwitchOrganization = () => {
    navigate("/user/organ-switch");
  };
  return (
    <MenuItem
      onClick={async () => {
        await props.hidePopup?.();
        setOpen(true);
        SwitchOrganization();
      }}
    >
      <ListItemText>{t("common.Switch organization")}</ListItemText>
    </MenuItem>
  );
};

const SideBar: React.FC = () => {
  const {t} = useTranslation();
  const user = useSelector((state: any) => state.app.user);
  const company = useSelector((state: any) => state.app.company);
  const location = useLocation();
  const navigate = useNavigate();
  const [hideMenu, setHideMenu] = React.useState(false);
  const [hideOrgan, setHideOrgan] = useState<boolean>(false)

  let role = "";
  const jwtToken = localStorage.getItem("accessToken");
  if (jwtToken) {
    const decodedToken = jwtDecode<TokenProps>(jwtToken);
    role = decodedToken.roleName;
  }
  const childrens = [
    {
      key: "organProfile",
      title: t("common.Organization Profile"),
      path: WebURL.AUTH_USER_COMPANY_VIEW,
    },
    {key: "person", title: t("common.Person"), path: WebURL.HR_EMPLOYEE},
    {
      key: "department",
      title: t("common.Department"),
      path: WebURL.HR_DEPARTMENT,
    },
    {key: "site", title: t("common.Site"), path: "/location/site"},
    // { key: "zone", title: t("common.Zone"), path: "/location/zone" },
    {
      key: "resignation",
      title: t("hr.resignation.Resignation"),
      path: WebURL.HR_RESIGNATION,
    },
  ]
  const children_hide = [
    {key: "person", title: t("common.Person"), path: WebURL.HR_EMPLOYEE},
    {
      key: "department",
      title: t("common.Department"),
      path: WebURL.HR_DEPARTMENT,
    },
    {key: "site", title: t("common.Site"), path: "/location/site"},
    // { key: "zone", title: t("common.Zone"), path: "/location/zone" },
    {
      key: "resignation",
      title: t("hr.resignation.Resignation"),
      path: WebURL.HR_RESIGNATION,
    },
  ]

  const menus: MenuProps[] = [
    {
      key: "dashboard",
      title: t("common.Dashboard"),
      path: WebURL.DASHBOARD,
      icon: "icon-ic_menu_dashboard",
    },
    {
      key: "quickSetting",
      title: t("common.Quickly Setting"),
      path: WebURL.QUICKSTART,
      icon: "icon-ic_menu_quicksettings",
    },
    {
      key: "organizationManagenment",
      title: t("common.Organization Management"),
      path: "",
      icon: "icon-ic_menu_organization",
      childrens: hideOrgan ? childrens : children_hide
    },
    {
      key: "device",
      title: t("common.Device Management"),
      path: "/dms/device",
      icon: "icon-ic_menu_device",
    },
    {
      key: "attendance",
      title: t("common.Attendance"),
      path: "",
      icon: "icon-ic_menu_attendance",
      childrens: [
        {
          key: "viewAttendancePunch",
          title: t("common.View Attendance Punch"),
          path: "/att/timecard/punch-record",
        },
        {
          key: "mendAttendancePunch",
          title: t("common.Mend Attendance Punch"),
          path: "/att/timecard/mend-punch-record",
        },
        {
          key: "timesheet",
          title: t("common.Timesheet"),
          path: "/att/rule/timesheet",
        },
        {
          key: "schedule",
          title: t("common.Shift Schedule"),
          path: "/att/schedule",
        },
        {
          key: "timecard",
          title: t("common.Timecard Management"),
          path: "/att/timecard/timecard",
        },
        {
          key: "attendance",
          title: t("common.Attendance Report"),
          path: "/att/report/attendance",
        },
      ],
    },
    {
      key: "setting",
      title: t("common.Setting"),
      path: "",
      icon: "icon-ic_menu_setting",
      childrens: [
        {
          key: "credentialsManagement",
          title: t("common.Credentials Management"),
          path: "/settings/credentials_management",
        },
      ],
    },
    {
      key: "operationLog",
      title: t("common.Operation Log"),
      path: WebURL.ADMIN_LOG,
      icon: "icon-LogIcon",
    },
  ];

  type SmallMenuItemProps = {
    menuItemProps: MenuProps;
  };

  const SmallMenuItem: React.FC<SmallMenuItemProps> = (props) => {
    const menuItem = props.menuItemProps;
    let selectChild = false;
    if (menuItem.childrens) {
      menuItem.childrens.map((item: any, index: any) => {
        if (location.pathname.startsWith(item.path)) {
          selectChild = true;
        }
      });
    } else {
      if (location.pathname.startsWith(menuItem.path)) {
        selectChild = true;
      }
    }

    const popupState = usePopupState({
      variant: "popover",
      popupId: "sideMenu",
    });
    return (
      <>
        <Box
          sx={{
            padding: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          {...bindHover(popupState)}
        >
          <i
            style={{fontSize: "25px", cursor: "pointer"}}
            className={`icon iconfont ${menuItem.icon}`}
          ></i>
        </Box>
        <HoverMenu
          {...bindMenu(popupState)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {menuItem.childrens ? (
            menuItem.childrens.map((item: any, index: any) => {
              return (
                <MenuItem
                  sx={{
                    margin: "0px 5px",
                    borderRadius: "5px",
                    background: location.pathname.startsWith(item.path)
                      ? "#e5f2fa"
                      : "#fff",
                    color: location.pathname.startsWith(item.path)
                      ? "#059ad9"
                      : "#304669",
                  }}
                  key={index}
                  onClick={() => {
                    navigate(item.path);
                  }}
                >
                  {item.title}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem
              sx={{
                margin: "0px 5px",
                borderRadius: "5px",
                background: location.pathname.startsWith(menuItem.path)
                  ? "#e5f2fa"
                  : "#fff",
                color: location.pathname.startsWith(menuItem.path)
                  ? "#059ad9"
                  : "#304669",
              }}
              onClick={() => {
                navigate(menuItem.path);
              }}
            >
              {menuItem.title}
            </MenuItem>
          )}
        </HoverMenu>
      </>
    );
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [gridData, setGridData] = useState<GridData>({data: [], total: 0});

  const fetchGridData = () => {
    GenericService.list(AuthEndpoints.AUTH_USER_COMPANY, {
      current: 1,
      pageSize: 100,
    })
      .then((response) => {
        setGridData(response?.data);
      })
      .catch((error) => {
        if (error?.response?.status < 500) {
          // do something when status is 4xx.
        }
      });
  };

  const fetchCompanyCount = async () => {
    const def_data = await CompanyService.defaultCompany({});
    if (def_data.status < 400) {
      if (def_data.company_len > 1) {
        setHideOrgan(true)
      } else {
        setHideOrgan(false)
      }
    }
  }

  const hideCmpMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchGridData();
    fetchCompanyCount();
  }, []);

  return (
    <Box
      style={{
        height: "100vh",
        display: "flex",
        background: "#fff",
        boxSizing: "border-box",
        width: hideMenu ? "70px" : "290px",
        position: "relative",
      }}
    >
      <Grid
        style={{
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          width: hideMenu ? "70px" : "290px",
          height: "100%",
          paddingBottom: "60px",
          // minWidth:
          // maxWidth: hideMenu ? "70px" : "290px",
        }}
      >
        {hideMenu ? (
          <>
            <Box sx={{marginTop: "40px", padding: "10px"}}>
              <AvatarIcon
                name={user?.firstName ? user.firstName : "None"}
                diameter={50}
                profilePhoto={
                  user?.profilePhoto
                    ? user.profilePhoto
                    : user.imagePreSignedURL
                }
              />

              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {hideOrgan && <Grid
                  sx={{
                    cursor: "pointer",
                    textAlign: 'center'
                  }}
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  ...
                </Grid>}
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  sx={{
                    p: 3,
                    boxShadow:
                      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                    ".MuiMenu-paper": {
                      top: "100px !important",
                    },
                  }}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <OrganSwitch
                    hideCmpMenu={hideCmpMenu}
                    gridData={gridData}
                  ></OrganSwitch>
                </Menu>
              </Grid>
            </Box>
            <Box sx={{marginTop: "40px"}}>
              {menus.map((item: any, index) => {
                return (
                  <SmallMenuItem
                    key={index}
                    menuItemProps={item}
                  ></SmallMenuItem>
                );
              })}
            </Box>
          </>
        ) : (
          <Grid>
            <Box
              sx={{
                width: "100%",
                height: "70px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{height: "40px"}}
                src={"/images/logo-zkbiobiotime-ngteco.png"}
              />
            </Box>

            <Grid
              sx={{
                height: "1px",
                backgroundColor: "#F2F5F8",
                margin: "0px 16px",
              }}
            ></Grid>

            <Grid
              sx={{
                height: "88px",
                display: "flex",
                alignItems: "stretch",
                paddingLeft: "16px",
                justifyContent: "space-between",
              }}
            >
              <Grid
                sx={{
                  height: "88px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  sx={{
                    padding: "1px",
                    width: "44px",
                    height: "44px",
                    borderRadius: "50%",
                    border: "1px solid #2BA9DE",
                  }}
                >
                  <AvatarIcon
                    name={user?.firstName ? user.firstName : "None"}
                    diameter={40}
                    profilePhoto={
                      user?.imagePreSignedURL ? user.imagePreSignedURL : "None"
                    }
                  />
                </Grid>
                <Grid
                  sx={{
                    marginLeft: "12px",
                  }}
                >
                  <Grid
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#3D3D3D",
                    }}
                  >
                    {user?.firstName ? user.firstName : "None"}
                  </Grid>

                  <Grid
                    sx={{
                      fontSize: "14px",
                      color: "#999999",
                      marginTop: "6px",
                    }}
                  >
                    {company?.name ? company.name : "None"}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                sx={{
                  paddingRight: "20px",
                }}
              >
                {hideOrgan && <Grid
                  sx={{
                    cursor: "pointer",
                  }}
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  ...
                </Grid>}
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  sx={{
                    p: 3,
                    boxShadow:
                      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                    ".MuiMenu-paper": {
                      top: "100px !important",
                    },
                  }}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <OrganSwitch
                    hideCmpMenu={hideCmpMenu}
                    gridData={gridData}
                  ></OrganSwitch>
                </Menu>
              </Grid>
            </Grid>

            <Grid
              sx={{
                height: "1px",
                backgroundColor: "#F2F5F8",
                margin: "0px 16px",
              }}
            ></Grid>

            <Box>
              {menus.map((item, index) => {
                if (item.childrens) {
                  return (
                    <Accordion
                      expanded={expanded === item.title}
                      onChange={handleChange(item.title)}
                      key={index}
                      sx={{border: "0px"}}
                    >
                      <AccordionSummary
                        sx={{
                          height: "56px",
                          display: "flex",
                          padding: "0px 16px",
                          alignItems: "center",
                          "&:hover": {
                            background: "#e5f2fa",
                          },
                        }}
                      >
                        <i
                          style={{
                            marginRight: "6px",
                          }}
                          className={`icon iconfont ${item.icon}`}
                        >
                          {" "}
                        </i>
                        <Typography
                          sx={{
                            color: "#3d3d3d",
                            fontSize: "14px !important",
                            flexGrow: 1,
                          }}
                        >
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {item.childrens.map((item: any, index: any) => {
                          return (
                            <Typography
                              key={index}
                              sx={{
                                position: "relative",
                                height: "48px",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                paddingLeft: "46px",
                                fontSize: "14px !important",
                                margin: "1px 0px !important",
                                background: location.pathname.startsWith(
                                  item.path
                                )
                                  ? "#e5f2fa"
                                  : "#fff",
                                "&:hover": {
                                  backgroundColor: "#e5f2fa",
                                },
                              }}
                              onClick={() => navigate(item.path)}
                              color={
                                location.pathname.startsWith(item.path)
                                  ? "#059ad9"
                                  : "#aeafb0"
                              }
                            >
                              {location.pathname.startsWith(item.path) && (
                                <span
                                  style={{
                                    position: "absolute",
                                    left: "0px",
                                    width: "3px",
                                    height: "30px",
                                    backgroundColor: "#2BA9DE",
                                  }}
                                ></span>
                              )}

                              {item.title}
                            </Typography>
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                  );
                } else {
                  return (
                    <Grid
                      key={index}
                      onClick={() => navigate(item.path)}
                      sx={{
                        display: "inline-flex",
                        padding: "0px 16px",
                        cursor: "pointer",
                        height: "56px",
                        width: "100%",
                        margin: "1px 0px",
                        boxSizing: "border-box",
                        alignItems: "center",
                        "&:hover": {
                          background: "#e5f2fa",
                        },
                      }}
                    >
                      <i
                        style={{
                          marginRight: "6px",
                          color: location.pathname.startsWith(item.path)
                            ? "#059ad9"
                            : "#3d3d3d"
                        }}
                        className={`icon iconfont ${item.icon}`}
                      >
                        {" "}
                      </i>

                      <Typography
                        color={
                          location.pathname.startsWith(item.path)
                            ? "#059ad9"
                            : "#3d3d3d"
                        }
                        sx={{
                          fontSize: "14px !important",
                        }}
                        key={index}
                      >
                        {item.title}
                      </Typography>
                    </Grid>
                  );
                }
              })}
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid
        sx={{
          position: "absolute",
          width: "100%",
          left: "0px",
          bottom: "0px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <IconButton onClick={() => setHideMenu(!hideMenu)}>
          {hideMenu ? (
            <i
              style={{
                color: "#D8D8D8",
              }}
              className="icon iconfont icon-ic_open"
            ></i>
          ) : (
            <i
              style={{
                color: "#D8D8D8",
              }}
              className="icon iconfont icon-ic_collect"
            ></i>
          )}
        </IconButton>
      </Grid>
    </Box>
  );
};
export default SideBar;
