import React, {useRef} from "react";
import {GridColDef} from "@mui/x-data-grid";
import {Box, Grid, Stack} from "@mui/material";
import CustomDataGrid, {DataGridRef} from "../../../components/data-grid/CustomDataGrid";
import {HREndpoints} from "../../../services/hr/endpoints";
import {DeleteForever} from "@mui/icons-material";
import UndoIcon from '@mui/icons-material/Undo';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WebURL from "../../../urls";
import CustomRowButton from "../../../components/data-grid/CustomRowButton";
import AvatarIcon from "@/components/avatar/AvatarIcon";
import Dialog from "@/components/dialog";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom"
import ExtraToolBar from './components/HelpInformation'
import EmployeeService from "@/services/hr/EmployeeService";
import ResignationService from "@/services/hr/ResignationService"
import {ReactComponent as IcView} from '@/assets/icons/IcView.svg';
import {ReactComponent as IconDeletes} from '@/assets/icons/IconDeletes.svg';
import ResignedEmployeeView from "./components/ResignedEmployeeView";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";

const ResignationDataGrid: React.FC = () => {
  const {t} = useTranslation();
  const dataGridRef = useRef<DataGridRef>();
  const resignedEmployeeViewRef = useRef<any>({})
  const extraToolBarRef = useRef<any>({})
  const columns: GridColDef[] = [
    {
      field: 'firstName', headerName: t("common.Person Name"), flex: 0.2, renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <AvatarIcon name={params.row.firstName}/>
            <Box style={{padding: '0 8px'}}>{params.row.firstName} {params.row.lastName}</Box>
          </Grid>
        )
      }
    },
    {field: 'employeeCode', headerName: t("common.Person ID"), type: "string", flex: 0.2},
    {field: 'department_name', headerName: t("common.Department"), type: "string", flex: 0.3},
    {
      field: 'actions',
      hideSortIcons: true,
      headerName: t("common.action"),
      headerAlign: 'left',
      align: 'left',
      flex: 0.32,
      renderCell: (e) => {
        return (
          <Stack direction={"row"} sx={{marginLeft: "-5px"}}>
            <CustomRowButton
              insideIcon={UndoIcon} actionName={t("common.Resume")}
              buttonProps={{
                key: "Resume",
                onClick: async () => {
                  Dialog.confirm({
                    // width: "650px",
                    title: t("common.Resume"),
                    content: t("hr.resignation.You sure to resume?", {value: e.row.firstName + " " + e.row.lastName}),
                    helpInfo: t("hr.resignation.Resume Tip"),
                    onConfirm: () => {
                      ResignationService.resume(e.row.id).then((response) => {
                        SnackbarUtil.success(t('common.success'))
                        dataGridRef.current?.refresh();
                      });
                    }
                  });
                }
              }}/>
            <CustomRowButton
              insideSvgIcon={IcView}
              actionName={t("common.view")}
              buttonProps={{
                key: "view",
                onClick: () => {
                  resignedEmployeeViewRef?.current?.showDetail(e.row.id)
                }
              }}/>
            <CustomRowButton
              insideSvgIcon={IconDeletes} actionName={t("common.delete")}
              buttonProps={{
                key: "delete",
                onClick: async () => {
                  Dialog.confirm({
                    title: t("common.Delete Employee"),
                    content: t("common.You sure to delete?", {value: e.row.firstName + " " + e.row.lastName}),
                    onConfirm: () => {
                      EmployeeService.destroy(e.row.id).then((response) => {
                        SnackbarUtil.success(t('common.success'))
                        dataGridRef.current?.refresh()
                      })
                    }
                  });
                }
              }}/>
          </Stack>
        )
      }
    }
  ]
  const reloadTable = () => {
    dataGridRef.current?.refresh();
  }
  return (
    <>
      <CustomBreadcrumbs record={[{label: t("hr.resignation.Resignation")}]}/>
      <CustomDataGrid
        uri={HREndpoints.HR_RESIGNATNION_URI}
        columns={columns}
        ref={dataGridRef}
        checkboxSelection={false}
        toolbar={{
          title: t("hr.resignation.Resignation"),
          searchTips: t("common.Search by Person First Name"),
          breadcrumbs: [
            {breadcrumbName: t("hr.resignation.Resignation"), path: WebURL.HR_EMPLOYEE}
          ],
          actions: [{
            key: 'about', iconFont: 'icon-ic_about', helpTxt: t("common.Usage Tips"), callback: async () => {
              extraToolBarRef?.current?.showInfomation()
            }
          }],
        }}
      />
      <ResignedEmployeeView ref={resignedEmployeeViewRef} reloadTable={reloadTable}></ResignedEmployeeView>
      <ExtraToolBar ref={extraToolBarRef}/>
    </>
  )
}
export default ResignationDataGrid
