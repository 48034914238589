import React, {useEffect, useState, useMemo} from "react";
import Box from "@mui/material/Box";
import GenericService from "../../../src/services/GenericService";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useTranslation} from "react-i18next";

function TimeSheetSelect(props) {
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState([]);
  const [numPages, setnumPages] = useState(1);
  const [data_length, setDataLength] = useState([])
  const [newlyAddedTimeSheet, setNewlyAddedTimeSheet] = useState("")
  const {t} = useTranslation();

  useEffect(() => {
    GenericService.list(props.datafrom, {current: 1, pageSize: 100}).then(({data}) => {
      setOptions(data.data);
      setnumPages(data.num_pages)
      setDataLength(data.data.length + 100)
      for (let obj of data.data) {
        if (obj.name === props.newTimeSheet) {
          setNewlyAddedTimeSheet(obj.id)
          break;
        }
      }
    });
    setPage(1);
  }, [props.newTimeSheet]);

  return (
    <Box sx={{minWidth: 120}}>
      <FormControl fullWidth>
        {/*<InputLabel id="demo-simple-select-label">{t("att.timesheet.Timesheet")}</InputLabel>*/}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.newTimeSheet ? newlyAddedTimeSheet : props.timeSheet}
          // label={t("att.timesheet.Timesheet")}
          onChange={props.handleTimeSheet}>
          {options.length <= 0 ? (
              <MenuItem disabled key="notimesheet" value="notimesheet">No Timesheet found</MenuItem>
            ) :
            (options && options.map((obj) => {
                return (
                  <MenuItem key={obj.id} value={obj.id}>{obj.name}</MenuItem>
                );
              })
            )}
        </Select>
      </FormControl>
    </Box>
  );
}

export default TimeSheetSelect;
