import React, {forwardRef, useImperativeHandle, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate, useLocation} from "react-router-dom"
import {Box, Grid, TextField, Stack, Button, Container, Paper, Select, MenuItem, Drawer} from "@mui/material";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {LocationEndpoints} from "@/services/location/endpoints"
import GenericService from "@/services/GenericService";
import {object, string, TypeOf, z} from "zod";
import {zodResolver} from "@hookform/resolvers/zod";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {ngteco_timezones} from "@/components/resource/timezone";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import WebURL from "@/urls";
import {useTranslation} from "react-i18next";
import NGInputLabel from "@/components/ngteco-form/NGInputLabel";
import DepartmentService from "@/services/hr/DepartmentService";


const SiteView = (props: any, ref: any) => {
  const {t} = useTranslation();
  const siteValidate = object({
    name: z.string({required_error: t("common.This field is required!")}).min(1, t("common.This field is required!")),
    timezone: z.object({
      value: z.string(),
      name: string()
    }, {required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")}),
    country: z.string().min(1, t("common.This field is required!")),
  })
  type siteInput = TypeOf<typeof siteValidate>;

  // const location = useLocation()
  // const state = location.state as { id: string }
  const navigate = useNavigate();
  const [state, setState] = useState({id: ""}) // 编辑地点id值
  const [saving, setSaving] = React.useState<boolean>(false)
  const [country, setCountry] = React.useState<string>('')
  const [oldData, setOldData] = React.useState<any>()
  const [title, setTitle] = useState("")
  const siteForm = useForm<siteInput>({
    resolver: zodResolver(siteValidate),
  })
  const timezoneList = ngteco_timezones();
  const {register, handleSubmit} = siteForm
  const [open, setOpen] = useState(false)

  const onClose = () => {
    siteForm.reset()
    setOpen(false)
    setCountry('')
  }

  useImperativeHandle(ref, () => ({
    showDetail: async (id: string) => {
      if (id) {
        // setState({id})
        setTitle(t("common.Edit Site"))
        await GenericService.retrieve(LocationEndpoints.SITE_URI, id || "").then((res) => {
          const site = res.data
          const timezone = timezoneList.find(option => option.value === site.address?.timeZone)
          siteForm.reset({
            name: site.name,
            timezone,
          })
          setCountry(site.address?.country)
          setOldData({
            name: site.name,
            timezone,
            country: site.country
          })
        })
        setState({id})
      } else {
        setTitle(t("common.New Site"))
        siteForm.reset({name: '', timezone: {}, country: ''})
        setCountry('')
        setState({id: ''})
      }
      setOpen(true)
    }
  }));

  const onSave = async () => {
    setSaving(true)
    try {
      let result;
      if (state?.id) {
        const values = siteForm.getValues()
        const newSite = {
          name: values.name,
          address: {
            timeZone: values.timezone.value,
            city: "default",
            addressLine1: "default",
            country: values.country,
          },
          country: values.country,
          timezone: timezoneList.find(option => option.value === values.timezone.value)
        }
        const data = {
          ...newSite,
          oldData: oldData
        }
        result = await GenericService.update(LocationEndpoints.SITE_URI, state.id || '', data)
        if (result?.status < 400)
          SnackbarUtil.success(t('common.Site updated successfully.'))
        else
          SnackbarUtil.error(result?.message)
        if (props.reloadTable) {
          props.reloadTable()
        }
        onClose()
      } else {
        const values = siteForm.getValues()
        const newSite = {
          name: values.name,
          address: {
            timeZone: values.timezone.value,
            city: "default",
            addressLine1: "default",
            country: values.country,
          }
        }
        result = await GenericService.create(LocationEndpoints.SITE_URI, newSite)
        if (result?.status < 400)
          SnackbarUtil.success(t('common.Site added successfully.'))
        else
          SnackbarUtil.error(result?.message)
      }
      if (props.reloadTable) {
        props.reloadTable()
      }
      onClose()
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Grid sx={{
        minWidth: '300px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
      }}>
        <Grid sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          padding: '0px 24px',
          borderBottom: '1px solid #F2F5F8',
        }}>
          <Grid>{title}</Grid>
          <Grid>
            <i style={{cursor: "pointer"}} className="icon iconfont icon-ic_close" onClick={onClose}></i>
          </Grid>
        </Grid>
        <Paper sx={{padding: '20px', width: '400px', boxShadow: 'none'}}>
          <Grid container>
            <Grid lg={12} item sx={{marginBottom: "8px"}}>
              <ControlledTextFiled
                hookForm={siteForm}
                label={t("common.Site Name")}
                name="name"
                fullWidth
                required
                placeholder={t("common.Site Name")}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid lg={12} item sx={{marginBottom: "8px"}}>
              <Stack sx={{lineHeight: "5vh"}}>
                <ControlAutoComplete
                  label={t("dms.Site Timezone")}
                  name={"timezone"}
                  form={siteForm}
                  options={timezoneList}
                  required={true}
                  groupBy={(option) => option.group}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid lg={12} item sx={{marginBottom: "8px"}}>
              <NGInputLabel label={t("signUp.Choose your country")} required={true}/>
              <Select
                id="country-select"
                fullWidth
                required={true}
                value={country}
                {...register("country")}
                onChange={(event) => {
                  setCountry(event.target.value)
                }}
              >
                <MenuItem value={"USA"}>{t("common.America")}</MenuItem>
                <MenuItem value={"JPN"}>{t("common.Japan")}</MenuItem>
                <MenuItem value={"GER"}>{t("common.Germany")}</MenuItem>
                <MenuItem value={"FRA"}>{t("common.France")}</MenuItem>
                <MenuItem value={"SPA"}>{t("common.Spain")}</MenuItem>
                <MenuItem value={"UK"}>{t("common.United Kingdom")}</MenuItem>
                <MenuItem value={"ITA"}>{t("common.Italy")}</MenuItem>
                <MenuItem value={"CAN"}>{t("common.Canada")}</MenuItem>
                <MenuItem value={"MEX"}>{t("common.Mexico")}</MenuItem>
                <MenuItem value={"MAL"}>{t("common.Malaysia")}</MenuItem>
                <MenuItem value={"THA"}>{t("common.Thailand")}</MenuItem>
                <MenuItem value={"PHI"}>{t("common.Philippines")}</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Paper>
        <Grid sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%'}}>
          <Grid style={{paddingTop: '12px', paddingLeft: '24px'}}>
            <Stack spacing={3} direction={"row"} justifyContent="start">
              <CancelButton onClick={onClose}/>
              <SavingButton children={t("common.confirm")} loading={saving} onClick={async () => {
                if (!siteForm.getValues('country')){
                  SnackbarUtil.error(t("organ.The country is required"), {
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    }
                  });
                }
                const isValid = await siteForm.trigger()
                if (isValid) {
                  onSave()
                }
              }}/>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default forwardRef(SiteView);

