import React from "react";
import ConfirmDialog, {DialogFuncProps} from "./ConfirmDialog";
import {InfoOutlined} from "@mui/icons-material";
import {createRoot} from "react-dom/client";


const confirm = (config: DialogFuncProps) => {
  const container = document.createDocumentFragment();
  const root = createRoot(container);
  const destroy = () => {
    root.unmount();
  }
  const currentConfig: any = {...config, open: true, close: destroy}
  const render = (props: any) => {
    root.render(<ConfirmDialog {...props} />);
  }
  render(currentConfig);
  return {
    destroy
  }
};
export default confirm
export const withConfirm = (props: DialogFuncProps) => {
  return {
    icon: <InfoOutlined color={"warning"} sx={{p: "8px"}}/>,
    width: "400px",
    ...props
  }
}
export const withOK = (props: DialogFuncProps) => {
  return {
    icon: <InfoOutlined color={"primary"} sx={{ p: "8px" }} />,
    width: "700px",
    isOK: true,
    ...props
  }
}
export const withInfo = (props: DialogFuncProps) => {
  return {
    icon: <InfoOutlined color={"primary"} sx={{p: "8px"}}/>,
    width: "400px",
    ...props
  }
}
export const withWarn = (props: DialogFuncProps) => {
  return {
    icon: <InfoOutlined color={"warning"} sx={{p: "8px"}}/>,
    width: "400px",
    ...props
  }
}
