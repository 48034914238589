import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { Box, Paper, Grid, TextField, Button, IconButton, Stack, Drawer } from "@mui/material";
import {object, TypeOf, z} from "zod";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import {ReactComponent as DepartmentGraph} from "@/assets/images/DepartmentGraph.svg"
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import DepartmentService from "@/services/hr/DepartmentService";
import { useSnackbar } from "notistack";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import { useTranslation } from "react-i18next";

const DepartmentView = (props: any, ref: any) => {
  const { t } = useTranslation();
  const departmentValidate = object({
    code: z.string().min(1, t("common.This field is required!"))
          .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    name: z.string().min(1,  t("common.This field is required!"))
          .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
  })
  type DepartmentForm = TypeOf<typeof departmentValidate>;
  const {enqueueSnackbar} = useSnackbar();
  const [saving, setSaving] = React.useState<boolean>(false)
  const [oldData, setOldData] = React.useState<any>()
  const [state, setState] = useState({ id: ""}) // 编辑部门id值
  const [title, setTitle] = useState("")
  const departmentForm = useForm<DepartmentForm>({
    resolver: zodResolver(departmentValidate),
    defaultValues: {
      code: '',
      name: '',
    }
  });
  const [open, setOpen] = useState(false)

  const onClose = () => {
    departmentForm.reset()
    setOpen(false)
  }
  useImperativeHandle(ref, () => ({
    showDetail: async (id:string) => {
      setState({id})
      if (id) {
        setTitle(t("common.Edit Department"))
      } else {
        setTitle(t("common.New Department"))
      }
      setOpen(true)
    }
  }));

  useEffect(() => {
    if (state?.id) {
      DepartmentService.retrieve(state.id || "").then((res) => {
        departmentForm.setValue("code", res.data.data?.code);
        departmentForm.setValue("name", res.data.data?.name);
        setOldData({
          code: res.data.data?.code,
          name: res.data.data?.name
        })
      });
    }
  }, [state]);

  const onSubmit = async (event: any) => {
    setSaving(true)
    try {
      let result;
      if (state?.id) {
        // update department
        const value = departmentForm.getValues()
        const data = {
          ...value,
          oldData: oldData
        }
        result = await DepartmentService.update(state.id, data);
        if (result.status < 400) {
          enqueueSnackbar(t("common.Department updated successfully."), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }});
          if (props.reloadTable) {
            props.reloadTable()
          }
          onClose()
        }
      } else {
        result = await DepartmentService.add(departmentForm.getValues());
        if (result.status < 400) {
          enqueueSnackbar(t("common.Department added successfully."), {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            }});
          if (props.reloadTable) {
            props.reloadTable()
          }
          onClose()
        }
      }

    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Grid sx={{
        minWidth: '300px',
        minHeight: '100vh',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
      }}>
        <Grid sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          padding: '0px 24px',
          borderBottom: '1px solid #F2F5F8',
        }}>
          <Grid>{ title }</Grid>
          <Grid>
            <i style={{ cursor: "pointer" }} className="icon iconfont icon-ic_close" onClick={onClose}></i>
          </Grid>
        </Grid>
        <Paper sx={{ padding: '20px', width: '400px', boxShadow: 'none'}}>
          <Grid
            container
          >
            <Grid lg={12} item sx={{marginBottom: "8px"}}>
              <ControlledTextFiled
                hookForm={departmentForm}
                label={t("hr.department.Department name")}
                name="name"
                fullWidth
                required
                placeholder={t("hr.department.Department name")}
              />
            </Grid>
          </Grid>
          <Grid
            container
          >
            <Grid lg={12} item sx={{marginBottom: "8px"}}>
              <ControlledTextFiled
                hookForm={departmentForm}
                label={t("hr.department.Department code")}
                name="code"
                fullWidth
                required
                placeholder={t("hr.department.Department code")}
              />
            </Grid>
          </Grid>
        </Paper>
        <Grid sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%'}}>
          <Grid style={{paddingTop: '12px', paddingLeft: '24px'}}>
            <Stack spacing={3} direction={"row"} justifyContent="start" >
              <CancelButton onClick={onClose} />
              <SavingButton loading={saving} onClick={departmentForm.handleSubmit(onSubmit)}/>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  )
}

export default forwardRef(DepartmentView);
