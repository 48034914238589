import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import WebURL from "@/urls";
import {Grid, Breadcrumbs, Typography, styled} from "@mui/material";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import NGUserAgreement from "@/components/ngteco-form/NGUserAgreement"


const UserAgreementView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.AUTH_USER_ABOUT, label: t("common.About") },
        { label: t("common.User Agreement") },
      ]} />
      <NGUserAgreement/>
    </>
  )
}

export default UserAgreementView;
