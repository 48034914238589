// material-ui
import { alpha } from "@mui/material/styles";

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme:any) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: "6px 4px",
          height:'20px'
        },
        root: {
          '& .MuiOutlinedInput-root': {
            padding:'4px'
          }
        },
      },
    },
  };
}
