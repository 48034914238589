import React, { useEffect, useRef, useState } from "react";
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import CustomDataGrid, {
  DataGridRef,
} from "../../../components/data-grid/CustomDataGrid";
import WebURL from "../../../urls";
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@/components/dialog";
import DeviceService from "@/services/dms/DeviceService";
import SnackbarUtil from "@/utils/SnackbarUtil";
import { DMSEndpoints } from "@/services/dms/endpoints";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { ReactComponent as DeviceIcon } from "@/assets/images/Device.svg";
import { ReactComponent as IconEditorList } from "@/assets/icons/IconEditorList.svg";
import { ReactComponent as IconControlPanel } from "@/assets/icons/IconControlPanel.svg";
import { ReactComponent as IconViewDetails } from "@/assets/icons/IconViewDetails.svg";
import { ReactComponent as IconDevicePerson } from "@/assets/icons/IconDevicePeson.svg";
import { ReactComponent as IconDelete } from "@/assets/icons/IconDelete.svg";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";
import TranslationUtil from "@/utils/TranslationUtil";
import DaylightSavingTime from "@/pages/dms/Device/components/DeviceAction/DaylightSavingTime";
import DeleteDaylightSavingTime from "@/pages/dms/Device/components/DeviceAction/DeleteDaylightSavingTime";
import AddDevice from "./components/AddDevice";
import ActionButton from "@/components/actions/ActionButton";
import { CustomBreadcrumbs } from "@/components/breadcrumbs/CustomBreadcrumbs";
const deleteDevice = (row: any, refresh: () => void) => {
  Dialog.confirm({
    title: TranslationUtil.t("common.Are you sure?"),
    content: TranslationUtil.t("common.Do you want to {} this device {}", {
      name: row.alias,
      action: "delete",
    }),
    onConfirm: () => {
      DeviceService.destroy(row.id).then((result) => {
        console.log("DeviceService.syncDateTimeDevice: ", result);
        SnackbarUtil.success("Success.");
        refresh();
      });
    },
  });
};

const cleanDeviceAdmin = (row: any) => {
  Dialog.confirm({
    title: TranslationUtil.t("common.Are you sure?"),
    content: TranslationUtil.t("common.Do you want to {} this device {}", {
      name: row.alias,
      action: "Clean device admin",
    }),
    onConfirm: () => {
      console.log("You want to clean this device.");
    },
  });
};

const rebootDevice = (row: any) => {
  Dialog.confirm({
    title: TranslationUtil.t("common.Are you sure?"),
    content: TranslationUtil.t("common.Do you want to {} this device {}", {
      name: row.alias,
      action: "Reboot device",
    }),
    onConfirm: () => {
      DeviceService.rebootDevice(row.id).then((result) => {
        console.log("DeviceService.rebootDevice: ", result);
        SnackbarUtil.success("Success.");
      });
    },
  });
};

const syncDeviceDatetime = (row: any) => {
  Dialog.confirm({
    title: TranslationUtil.t("common.Are you sure?"),
    content: TranslationUtil.t("common.Do you want to {} this device {}", {
      name: row.alias,
      action: "Sync date and time according to site",
    }),
    onConfirm: () => {
      DeviceService.syncTimezoneBySite({ siteId: row.site, sn: row.sn }).then(
        (result) => {
          console.log("DeviceService.syncDateTimeDevice: ", result);
          SnackbarUtil.success("Success.");
        }
      );
    },
  });
};

const DeviceActionButtons =
  (refresh: () => void) => (e: GridRenderCellParams) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const popupState = usePopupState({
      variant: "popover",
      popupId: "popupMenu",
    });
    const devicePersonPopupState = usePopupState({
      variant: "popover",
      popupId: "devicePerson",
    });
    const devicePersonPopupStateTrigger = bindTrigger(devicePersonPopupState);
    const syncDevicePopupState = usePopupState({
      variant: "popover",
      popupId: "syncDevice",
    });
    const syncDevicePopupStateTrigger = bindTrigger(syncDevicePopupState);

    return (
      <Stack direction={"row"} spacing={3} sx={{marginLeft: "-5px"}}>
        <ActionButton
          title={t("common.detail")}
          icon="icon-detail"
          handleClick={() =>
            navigate(WebURL.DMS_DEVICE_VIEW, { state: { id: e.row.id } })
          }
        ></ActionButton>

        <ActionButton
          title={t("common.edit")}
          icon="icon-ic_edit"
          handleClick={() =>
            navigate(WebURL.DMS_DEVICE_EDIT, { state: { id: e.row.id } })
          }
        ></ActionButton>

        <ActionButton
          title={t("common.delete")}
          icon="icon-ic_delete"
          handleClick={() => {
            deleteDevice(e.row, refresh);
          }}
        ></ActionButton>

        <ActionButton
          title={t("common.add")}
          icon="icon-add_use"
          handleClick={() =>
            navigate(WebURL.DMS_DEVICE_PERSONS, { state: { id: e.row.id } })
          }
        ></ActionButton>

        {/* <Tooltip title={t("common.Device detail")}>
          <IconButton
            key="view"
            onClick={() =>
              navigate(WebURL.DMS_DEVICE_VIEW, { state: { id: e.row.id } })
            }
          >
            <SvgIcon component={IconViewDetails} />
          </IconButton>
        </Tooltip> */}

        {/* <Tooltip title={t("common.Edit device")}>
          <IconButton
            key="edit"
            onClick={() =>
              navigate(WebURL.DMS_DEVICE_EDIT, { state: { id: e.row.id } })
            }
          >
            <SvgIcon component={IconEditorList} />
          </IconButton>
        </Tooltip> */}
        {/*
        <Tooltip title={t("common.delete")}>
          <IconButton
            key="delete"
            onClick={() => {
              deleteDevice(e.row, refresh);
            }}
          >
            <SvgIcon component={IconDelete} />
          </IconButton>
        </Tooltip> */}

        {/* <Tooltip title={t("common.Person in this device")}>
          <IconButton
            key="add_person"
            onClick={() =>
              navigate(WebURL.DMS_DEVICE_PERSONS, { state: { id: e.row.id } })
            }
          >
            <SvgIcon component={IconDevicePerson} />
          </IconButton>
        </Tooltip> */}

        {/* <ActionButton
          title={t("common.action")}
          icon="icon-ic_add_use"
          handleClick={() =>
            navigate(WebURL.DMS_DEVICE_PERSONS, { state: { id: e.row.id } })
          }
        ></ActionButton> */}

        <Grid {...bindTrigger(popupState)}>
          <ActionButton
            title={t("common.action")}
            icon="icon-operation"
          ></ActionButton>
        </Grid>

        {/* <Tooltip title={t("common.action")}>
          <IconButton {...bindTrigger(popupState)}>
            <SvgIcon component={IconControlPanel} />
          </IconButton>
        </Tooltip> */}

        <Menu {...bindMenu(popupState)}>
          {/* <MenuItem onClick={() => {
          popupState.close()
          cleanDeviceAdmin(e.row)
        }}>
          {t("common.Clean device admin")}
        </MenuItem> */}
          <MenuItem
            onClick={() => {
              popupState.close();
              rebootDevice(e.row);
            }}
          >
            {t("common.Reboot device")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              popupState.close();
              syncDeviceDatetime(e.row);
            }}
          >
            {t("common.Sync date and time according to site")}
          </MenuItem>
          <MenuItem
            {...devicePersonPopupStateTrigger}
            onClick={(event) => {
              popupState.close();
              navigate(WebURL.DMS_DEVICE_PERSONS, { state: { id: e.row.id } });
            }}
          >
            {t("common.Person in this device")}
          </MenuItem>
          {/* <MenuItem
          {...syncDevicePopupStateTrigger}
          onClick={(e) => {
            popupState.close()
            syncDevicePopupStateTrigger.onClick(e)
          }}
        >
          {t("common.Sync all data to device")}
        </MenuItem> */}
        </Menu>

        {/* <SyncDeviceDrawer
        {...bindMenu(syncDevicePopupState)}
        deviceId={e.row.id}
      /> */}

        {/* <DevicePerson
        {...bindMenu(devicePersonPopupState)}
        deviceId={e.row.id}
      /> */}
      </Stack>
    );
  };

const DeviceGridData: React.FC = () => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef>({} as DataGridRef);
  const addDeviceRef = useRef<any>({});
  const [refresh, setRefresh] = useState<boolean>(true);
  const navigate = useNavigate();
  const [selectionDeviceModel, setSelectionDeviceModel] =
    useState<GridSelectionModel>([]);

  const StatusField = (params: GridRenderCellParams) => {
    if (params.value === "1") {
      return <strong style={{ color: "#006644" }}>{t("dms.Online")}</strong>;
    } else {
      return <strong style={{ color: "#BF2600" }}>{t("dms.Offline")}</strong>;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "alias",
      headerName: t("common.Device name"),
      minWidth: 200,
      flex: 0.2,
      renderCell: (params) => {
        return (
          <Grid style={{ display: "flex", alignItems: "center" }}>
            <Badge
              color="success"
              badgeContent=""
              variant="dot"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              sx={{
                ".MuiBadge-badge": {
                  backgroundColor:
                    params.row.status === "1" ? "#20cf61" : "#ef5656",
                  bottom: "7px",
                  right: "7px",
                  border: `3px solid #fff`,
                  height: "15px",
                  width: "15px",
                  minWidth: "15px",
                  borderRadius: "15px",
                },
              }}
            >
              <DeviceIcon />
            </Badge>
            <Box style={{ padding: "0 20px" }}>{params.row.alias}</Box>
          </Grid>
        );
      },
    },
    { field: "sn", headerName: t("common.SN"), flex: 0.2 },
    { field: "site_name", headerName: t("common.Site name"), flex: 0.2 },
    // { field: "zone_name", headerName: t("common.Zone name"), flex: 0.2 },
    {
      field: "status",
      renderCell: StatusField,
      headerName: t("dms.Status"),
      minWidth: 50,
      flex: 0.1,
    },
    {
      field: "actions",
      hideSortIcons: true,
      flex: 0.2,
      minWidth: 500,
      headerName: t("common.action"),
      headerAlign: "left",
      align: "left",
      renderCell: DeviceActionButtons(() => {
        setRefresh(!refresh);
      }),
    },
  ];

  useEffect(() => {
    dataGridRef.current?.refresh();
  }, [refresh]);

  const reloadTable = () => {
    dataGridRef.current?.refresh();
  };

  return (
    <>
      <CustomBreadcrumbs record={[{label: t("common.Device")}]}/>
      <CustomDataGrid
        uri={DMSEndpoints.DMS_DEVICE_URI}
        columns={columns}
        ref={dataGridRef}
        checkboxSelection={true}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionDeviceModel(newSelectionModel);
        }}
        selectionModel={selectionDeviceModel}
        toolbar={{
          title: t("common.Device"),
          searchTips: t("dms.Search by Device SN"),
          // breadcrumbs: [
          //   {breadcrumbName: t("common.Device"), path: WebURL.DMS_DEVICE}
          // ],
          actions: [
            {
              key: "add",
              iconFont: "icon-ic_add",
              helpTxt: t("common.add"),
              callback: async () => {
                addDeviceRef?.current?.showAdd();
                // navigate(WebURL.DMS_DEVICE_ADD);
              },
            },
          ],
          extraComponentOnRight: (
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <DaylightSavingTime deviceIds={selectionDeviceModel} />
              <Grid
                sx={{
                  marginLeft: "8px",
                }}
              >
                <DeleteDaylightSavingTime deviceIds={selectionDeviceModel} />
              </Grid>
            </Grid>
          ),
        }}
      />
      <AddDevice reloadTable={reloadTable} ref={addDeviceRef}></AddDevice>
    </>
  );
};
export default DeviceGridData;
