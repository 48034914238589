import request from "../../../utils/Request";
import {ATTEndpoints} from "../endpoints";

const presentSummary = async (category: string) => {
  const {data} = await request.authorizedAxios().get(`${ATTEndpoints.ATT_PRESENT_SUMMARY_TODAY}${category}/`);
  // console.log("presentSummaryToday >>>", data)
  return data;
}

const DashboardService = {
  presentSummary
}
export default DashboardService
