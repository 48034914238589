import { Refresh } from "@mui/icons-material"
import { IconButton,Grid, Tooltip } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { DataGridDispatchContext } from "../data-grid/DataGridContext"
import ActionButton from "./ActionButton"
interface RefreshProps {
  handleRefresh?: () => void
}

const RefreshAction = (props: RefreshProps) => {
  const {t} = useTranslation()
  const dispatch = useContext(DataGridDispatchContext)
  const handleRefresh = () => {
    props.handleRefresh && props?.handleRefresh()
    dispatch({type: "refreshed"})
  }
  return <ActionButton handleClick={handleRefresh} title={t("common.refresh") } icon="icon-ic_refresh" > </ActionButton>
}

export default RefreshAction
