import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom"
import {Grid, TextField, Stack, Button, InputLabel, Paper} from "@mui/material";
import GenericService from "@/services/GenericService";
import {LocationEndpoints} from "@/services/location/endpoints"
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import SnackbarUtil from "@/utils/SnackbarUtil";
import {object, TypeOf, z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod/dist/zod";
import ControlledTextFiled from "@/components/ngteco-hook-form/ControlledTextFiled";
import ControlAutoComplete from "@/components/hook-form/ControlAutoComplete";
import {useDispatch, useSelector} from "react-redux";
import {getOrganSites} from "@/store/slices/Site";
import {CancelButton, SavingButton} from "@/components/data-grid/CustomButton";
import WebURL from "@/urls";
import { useTranslation } from "react-i18next";

const AddZone: React.FC = () => {
  const { t } = useTranslation();
  const zoneValidate = object({
    name: z.string({ required_error: t("common.This field is required!") }).min(1, t("common.This field is required!"))
          .max(30, t("common.The length of field cannot exceed digits", {number: 30})),
    site: z.object({
      id: z.string(),
      name: z.string(),
    }, { required_error: t("common.This field is required!"), invalid_type_error: t("common.This field is required!")} ),
  })
  type zoneInput = TypeOf<typeof zoneValidate>;

  const navigate = useNavigate();
  const helpInfo = t("location.zone.Add Zone Tip")
  const [saving, setSaving] = React.useState<boolean>(false)

  const sites: {id: string, name: string, address: Record<string, any>}[] = useSelector((state: any) =>  state.site.sites);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(getOrganSites());
  }, [])

  const zoneForm = useForm<zoneInput>({
    resolver: zodResolver(zoneValidate),
  });

  const onSave = async () => {
    setSaving(true)
    try {
      const values = zoneForm.getValues()
      const newZone = {
        name: values.name,
        siteId: values.site.id,
      }
      const response = await GenericService.create(LocationEndpoints.ZONE_URI, newZone)
      if (response?.status < 400)
        SnackbarUtil.success(t('common.Zone added successfully.'))
      else
        SnackbarUtil.error(response?.message)
      navigate(-1)
    } catch (e) {
      console.log("error===", e)
    } finally {
      setSaving(false)
    }
  }

  return (
    <>
      <CustomBreadcrumbs record={[
        { path: WebURL.LOCATION_ZONE, label: t("common.Zone") },
        {
          label: t("common.New Zone"),
          helpInfo: helpInfo
        },
      ]} />

      <Paper sx={{margin: '20px 0', padding: '20px'}}>
        <Grid container spacing={2} component="form">
          <Grid item xs={5} >
            <img width={"100%"} src={'/images/location-zone-view-rectangle.png'}/>
          </Grid>
          <Grid item xs={7}>
            <ControlledTextFiled required fullWidth label={t("common.Zone Name")} name={"name"} hookForm={zoneForm} />
            <ControlAutoComplete required label= {t("common.Zone belongs to a Site")} name={"site"} form={zoneForm} options={sites}
            />
          </Grid>

        </Grid>
      </Paper>
      <Grid style={{'padding':'30px'}}>
        <Stack spacing={3} direction={"row"} justifyContent="flex-end" >
          <CancelButton/>
          <SavingButton loading={saving} onClick={zoneForm.handleSubmit(onSave)}/>
        </Stack>
      </Grid>
    </>
  )
}

export default AddZone;
