import DepartmentService from "@/services/hr/DepartmentService"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
  depts: [],
}

export const getOrganDepts = createAsyncThunk('dept/byOrgan', async () => {
  const {data} = await DepartmentService.list({current: 1, pageSize: 50});
  return data;
})

const deptSlice = createSlice({
  name: 'dept',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganDepts.fulfilled, (state, action) => {
      return {
        depts: action.payload.data
      }
    })
  }
});

export default deptSlice.reducer
