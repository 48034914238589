import React, {Dispatch, SetStateAction, useState, forwardRef, useImperativeHandle} from "react";
import MainLayout from "@/pages/auth/Login/components/MainLayout";
import {ReactComponent as LayoutImage} from "@/assets/images/UserLogin.svg"
import {
  Button,
  Grid, Paper, Drawer, Stack,
  Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {object, TypeOf, z} from "zod";
import WebURL from "@/urls";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {useSnackbar} from "notistack";
import UserService from "@/services/auth/UserService";
import { SavingButton} from "@/components/data-grid/CustomButton";

const signUpSchema = object({
})
type signUpInput = TypeOf<typeof signUpSchema>;

type PolicyProps = {
  setPolicy: Dispatch<SetStateAction<string>>,
  setPrivacyPolicy: Dispatch<SetStateAction<boolean>>,
}

const KnowAboutThisPolicy = (props: any, ref: any) => {
  const native = useNavigate();
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const {register, handleSubmit, formState: {errors}} = useForm<signUpInput>({
    resolver: zodResolver(signUpSchema)
  })

  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }
  useImperativeHandle(ref, () => ({
    showTips: async (id:string) => {
      setOpen(true)
    }
  }));

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
    >
      <Grid sx={{
        width: '600px',
        display:'flex',
        minHeight: '100vh',
        flexDirection:'column',
        justifyContent: 'flex-start',
        position: 'relative',
      }}>
        <Grid sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '56px',
          padding: '0px 24px',
          borderBottom: '1px solid #F2F5F8',
        }}>
          <Grid>{ t("organ.know more about this?") }</Grid>
          <Grid>
            <i style={{ cursor: "pointer" }} className="icon iconfont icon-ic_close" onClick={onClose}></i>
          </Grid>
        </Grid>
        <Paper elevation={0} sx={{margin: '10px 20px 0', height: 'calc(100% - 60px)', backgroundColor: '#ffffff', overflow: 'hidden'}}>
          <Grid container={true} rowSpacing={3} component={"form"} noValidate sx={{p: "0 16px"}}>
            <Grid item sx={{display: "inline-flex", flexDirection: "column", marginLeft: -1}}  rowSpacing={3}>
              <Typography sx={{'p': '10px 0'}}>{t("att.timeSheet.Know about tip1")}</Typography>
              <Typography sx={{'p': '10px 0'}}>{t("att.timeSheet.Know about tip2")}</Typography>
              <Typography sx={{'p': '10px 0'}}>{t("att.timeSheet.Know about tip3")}</Typography>
              <Typography sx={{ marginTop: "-10px", color: "#999999"}}>{t("att.timeSheet.Know about tipExample")}</Typography>
              <Typography sx={{'p': '10px 0'}}>{t("att.timeSheet.Know about tip4")}</Typography>
              {/* <Typography sx={{'p': '10px 0'}}>{t("att.timeSheet.Know about tip5")}</Typography> */}
            </Grid>
          </Grid>
          <Grid sx={{borderTop: '1px solid #F2F5F8', position: 'absolute', bottom: '12px', left: '0', width: '100%', paddingLeft: '24px'}}>
            <Grid style={{paddingTop: '12px'}}>
              <Stack spacing={3} direction={"row"} justifyContent="start" >
                <SavingButton
                  disableElevation
                  onClick={onClose}
                  children= {t("common.confirm")}
                  />
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Drawer>
  )
}
export default forwardRef(KnowAboutThisPolicy)
