
const WebURL = {
  APP_ROOT: "/",
  ZKLINK_FORWARD: "/att/zlink",
  AUTH_USER_LOGIN: "/user/login",
  AUTH_SIGN_UP: "/user/sign-up",
  AUTH_FORGOT_PASSWORD: "/user/forgot_password",
  AUTH_USER_AGREEMENT: "/user/user-agreement",
  AUTH_PRIVACY_POLICY: "/user/privacy-policy",
  AUTH_USER_COMPANY_SELECTION: "/user/organ-selection",
  AUTH_USER_COMPANY_CREATION: "/user/organ-creation",
  AUTH_USER_PROFILE: "/user/profile",
  AUTH_USER_ABOUT: "/user/about",
  AUTH_USER_ABOUT_USER_AGGREMENT: "/user/about/user-agreement/",
  AUTH_USER_ABOUT_PRIVACY_POLICY: "/user/about/privacy-policy/",
  AUTH_USER_ABOUT_INFO_COLLECTION_FORM: "/user/about/info-collection-form/",
  AUTH_USER_ABOUT_COMPLIANCE: "/user/about/compliance/",
  AUTH_USER_RESET_PASSWORD: "/user/reset_password",
  AUTH_USER_COMPANY_VIEW: "/user/organ-view",
  AUTH_USER_COMPANY_ADD: "/user/organ-add",
  AUTH_USER_COMPANY_SWITCH: "/user/organ-switch",

  DASHBOARD: "/dashboard",
  HR_EMPLOYEE: "/hr/employee",
  HR_EMPLOYEE_VIEW: "/hr/employee/view",
  HR_EMPLOYEE_NEW: "/hr/employee/new",
  HR_DEPARTMENT: "/hr/department",
  HR_DEPARTMENT_VIEW: "/hr/department/view",
  HR_DEPARTMENT_NEW: "/hr/department/new",
  HR_RESIGNATION: "/hr/resignation",
  HR_RESIGNATION_VIEW: "/hr/resignation/view",

  ATT_RULE_HOME: "/att/rule",
  ATT_CLOCKING_COLLECTION_RULE: "/att/rule/collection",
  ATT_CLOCKING_COLLECTION_RULE_VIEW: "/att/rule/collection/:id",
  ATT_TIMESHEET: "/att/rule/timesheet",
  ATT_TIMESHEET_VIEW: "/att/rule/timesheet/view",
  ATT_TIMESHEET_NEW: "/att/rule/timesheet/new",
  ATT_SCHEDULE: "/att/schedule",
  ATT_SCHEDULE_NEW: "/att/schedule/new",
  ATT_SCHEDULE_VIEW: "/att/schedule/view",
  ATT_TIMECARD_HOME: "/att/timecard",
  ATT_TIMECARD_PUNCH_RECORD: "/att/timecard/punch-record",
  ATT_TIMECARD_PUNCH_RECORD_ADD: "/att/timecard/mend-punch-record",
  ATT_TIMECARD_TIMECARD: "/att/timecard/timecard",
  ATT_TIMECARD_TIMECARD_EXPORT: "/att/timecard/timecard/export",
  ATT_REPORT_HOME: "/att/report",
  ATT_REPORT_FIRST_LAST: "/att/report/first-and-last",
  ATT_REPORT_FILO: "/att/report/first-in-last-out",
  ATT_REPORT_MONTHLY_REPORT: "/att/report/monthly",
  ATT_REPORT_ATTENDANCE_REPORT: "/att/report/attendance",
  ATT_REPORT_ATTENDANCE_REPORT_EXPORT: "/att/report/attendance/export",

  DMS_DEVICE: "/dms/device",
  DMS_DEVICE_ADD: "/dms/device/add",
  DMS_DEVICE_VIEW: "/dms/device/view",
  DMS_DEVICE_EDIT: "/dms/device/edit",
  DMS_DEVICE_PERSONS: "/dms/device/persons/view",
  DMS_PERSON_IN_DEVICE: `/dms/device/persons`,

  LOCATION_SITE: `/location/site`,
  LOCATION_SITE_ADD: `/location/site/add`,
  LOCATION_SITE_EDIT: `/location/site/view`,
  LOCATION_ZONE: `/location/zone`,
  LOCATION_ZONE_ADD: `/location/zone/add`,
  LOCATION_ZONE_EDIT: `/location/zone/view`,
  SETTING_CREDENTIAL: "/settings/credentials_management",
  BEAT_TASK: "/beat_task",
  ADMIN_LOG: "/admin_log",
  QUICKSTART: "/quick_start",
}
export default WebURL
