import * as React from "react";
import {SnackbarProvider} from "notistack";
import {BrowserRouter} from "react-router-dom";
import MenuRoutes from "../routes";
import '../assets/layout.sass'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {SnackbarUtilsConfigurator} from "@/utils/SnackbarUtil";
import CustomSuccess from "@/components/snackbars/CustomSuccess";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles"
import * as locales from "@mui/material/locale"
import { useTranslation } from "react-i18next";
import { Locales, LanguageCode, LanguageLocale } from "@/utils/Languages";

const App = () => {
  const theme = useTheme()
  const {i18n} = useTranslation()

  const themeWithLocale = React.useMemo(
    () => {
      const locale = Locales[i18n.language as LanguageCode] as string;
      return createTheme(theme, locales[locale as LanguageLocale])
    },
    [theme, i18n.language]
  )

  return (
    <ThemeProvider theme={themeWithLocale}>
      <BrowserRouter basename={"/"}>
        <SnackbarProvider
          Components={{
            success: CustomSuccess
          }}
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <SnackbarUtilsConfigurator />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MenuRoutes/>
          </LocalizationProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
};
export default App;
