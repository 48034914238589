import {
  Box,
  Breadcrumbs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, Dispatch, SetStateAction } from "react";

import CustomTooltip from "./CustomToolTip"
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "@/store/slices/Breadcrumbs";

export type BreadcrumbsProps = {
  record: Record<string, any>;
  helpInfo?: any;
}

export const CustomBreadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch<any>(
      setBreadcrumbs<any>(props.record)
    );
  }, [props.record]);

  return (
    <Box sx={{
      display:"none"
    }}></Box>
  )
}

export default CustomBreadcrumbs
