import React from "react";
import { Box, Container,Typography, Grid, Menu, MenuItem, Tooltip } from "@mui/material";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconHelpInfo } from "@/assets/icons/MenuHelpInfo.svg";
import Link from "@mui/material/Link";
import step1video from "@/assets/helpVideo/Step1PowerConnection.mp4";
import step2video from "@/assets/helpVideo/Step2 Configuring the Network.mp4";
import step3video from "@/assets/helpVideo/Step3 Access the website.mp4";
import step4video from "@/assets/helpVideo/Step4 add information.mp4";
import step5video from "@/assets/helpVideo/Step5 add devices.mp4";
import step6video from "@/assets/helpVideo/Step6 Synchronize Persons to Device.mp4";
import step7video from "@/assets/helpVideo/Step7 Timecard Management.mp4";
import Dialog from "@/components/dialog";
import { styled } from "@mui/material/styles";


export const VideoPlayer1 = () => {
  return (
    <Container sx={{ p: 0 }}>
      <video
        controls={true}
        width="500px"
        height="300px"
        crossOrigin={"anonymous"}
        disableRemotePlayback={true}
      >
        <source src={step1video} type="video/mp4" />
      </video>
    </Container>
  );
};

export const VideoPlayer2 = () => {
  return (
    <Container sx={{ p: 0 }}>
      <video
        controls={true}
        width="500px"
        height="300px"
        crossOrigin={"anonymous"}
        disableRemotePlayback={true}
      >
        <source src={step2video} type="video/mp4" />
      </video>
    </Container>
  );
};

export const VideoPlayer3 = () => {
  return (
    <Container sx={{ p: 0 }}>
      <video
        controls={true}
        width="500px"
        height="300px"
        crossOrigin={"anonymous"}
        disableRemotePlayback={true}
      >
        <source src={step3video} type="video/mp4" />
      </video>
    </Container>
  );
};

export const VideoPlayer4 = () => {
  return (
    <Container sx={{ p: 0 }}>
      <video
        controls={true}
        width="500px"
        height="300px"
        crossOrigin={"anonymous"}
        disableRemotePlayback={true}
      >
        <source src={step4video} type="video/mp4" />
      </video>
    </Container>
  );
};

export const VideoPlayer5 = () => {
  return (
    <Container sx={{ p: 0 }}>
      <video
        controls={true}
        width="500px"
        height="300px"
        crossOrigin={"anonymous"}
        disableRemotePlayback={true}
      >
        <source src={step5video} type="video/mp4" />
      </video>
    </Container>
  );
};

export const VideoPlayer6 = () => {
  return (
    <Container sx={{ p: 0 }}>
      <video
        controls={true}
        width="500px"
        height="300px"
        crossOrigin={"anonymous"}
        disableRemotePlayback={true}
      >
        <source src={step6video} type="video/mp4" />
      </video>
    </Container>
  );
};

export const VideoPlayer7 = () => {
  return (
    <Container sx={{ p: 0 }}>
      <video
        controls={true}
        width="500px"
        height="300px"
        crossOrigin={"anonymous"}
        disableRemotePlayback={true}
      >
        <source src={step7video} type="video/mp4" />
      </video>
    </Container>
  );
};



export const CustomGrid = styled(Grid)(({ theme }) => ({
  height: '30px',
  lineHeight: "30px",
  color: '#666666',
  fontSize:'12px'
}));


const HelpInfo: React.FC = () => {
  const { t } = useTranslation();
  const popupState = usePopupState({ variant: "popover", popupId: "helpInfo" });
  return (
    <Box>
      <Tooltip title={t("common.Help Info")}>
        <Grid
          sx={{
            width: "32px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:"#ffffff",
            borderRadius: "50%",
            cursor: 'pointer',
            margin:'0px 20px'
          }}
          {...bindTrigger(popupState)}
        >
          <i className="icon iconfont icon-ic_help"></i>
        </Grid>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted={true}
        {...bindMenu(popupState)}
      >
        <Grid sx={{
          padding:'10px 16px 16px 16px'
         }}>{t("common.help video")}</Grid>
        <MenuItem>
          <CustomGrid
            sx={{ color: "#0c0c0c" }}
            onClick={() => {
              Dialog.confirm({
                width: "100vw",
                content: <VideoPlayer1 />,
              });
            }}
          >
            {t("common.step1,Power Connection")}
          </CustomGrid>
        </MenuItem>
        <MenuItem>
          <CustomGrid
            sx={{ color: "#0c0c0c" }}
            onClick={() => {
              Dialog.confirm({
                width: "100vw",
                content: <VideoPlayer2 />,
              });
            }}
          >
            {t("common.step2,Configuring the Network")}
          </CustomGrid>
        </MenuItem>
        <MenuItem>
          <CustomGrid
            sx={{ color: "#0c0c0c" }}
            onClick={() => {
              Dialog.confirm({
                width: "100vw",
                content: <VideoPlayer3 />,
              });
            }}
          >
            {t("common.step3,Access the website")}
          </CustomGrid>
        </MenuItem>
        <MenuItem>
          <CustomGrid
            sx={{ color: "#0c0c0c" }}
            onClick={() => {
              Dialog.confirm({
                width: "100vw",
                content: <VideoPlayer4 />,
              });
            }}
          >
            {t("common.step4,Add information")}
          </CustomGrid>
        </MenuItem>
        <MenuItem>
          <CustomGrid
            sx={{ color: "#0c0c0c" }}
            onClick={() => {
              Dialog.confirm({
                width: "100vw",
                content: <VideoPlayer5 />,
              });
            }}
          >
            {t("common.step5,Add devices")}
          </CustomGrid>
        </MenuItem>
        <MenuItem>
          <CustomGrid
            sx={{ color: "#0c0c0c" }}
            onClick={() => {
              Dialog.confirm({
                width: "100vw",
                content: <VideoPlayer6 />,
              });
            }}
          >
            {t("common.step6,Synchronize Persons to device")}
          </CustomGrid>
        </MenuItem>
        <MenuItem>
          <CustomGrid
            sx={{ color: "#0c0c0c" }}
            onClick={() => {
              Dialog.confirm({
                width: "100vw",
                content: <VideoPlayer7 />,
              });
            }}
          >
            {t("common.step7,Timecard Management")}
          </CustomGrid>
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default HelpInfo;
