import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import DashboardService from "../../../services/att/services/DashboardService";
import { useTranslation } from "react-i18next";
import * as echarts from "echarts";
const PieChart: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const chartInstance = useRef<any>(null);

  const [data, setData] = useState([
    {
      id: 0,
      value: 0,
      name: t("common.Normal"),
      itemStyle: {
        color: "#264fec",
      },
    },
    {
      id: 1,
      value: 0,
      name: t("common.Abnormal"),
      itemStyle: {
        color: "#F65555",
      },
    },
  ]);

  useEffect(() => {
    setData([]);
    DashboardService.presentSummary(props.category)
      .then((res) => {
        const { data } = res;
        switch (props.category) {
          case "day":
            if (Number(data.present_this_day) == 0 && Number(data.absent_this_day) == 0) {
              setData([])
            } else if (Number(data.present_this_day) > 0 && Number(data.absent_this_day) == 0) {
              setData([
                {
                  id: 0,
                  value: data.present_this_day,
                  name: t("common.Normal"),
                  itemStyle: {
                    color: "#2BA9DE",
                  },
                },
              ])
            } else if (Number(data.present_this_day) == 0 && Number(data.absent_this_day) > 0) {
              setData([
                {
                  id: 1,
                  value: data.absent_this_day,
                  name: t("common.Abnormal"),
                  itemStyle: {
                    color: "#F65555",
                  },
                },
              ])
            } else {
              setData([
                {
                  id: 0,
                  value: data.present_this_day,
                  name: t("common.Normal"),
                  itemStyle: {
                    color: "#2BA9DE",
                  },
                },
                {
                  id: 1,
                  value: data.absent_this_day,
                  name: t("common.Abnormal"),
                  itemStyle: {
                    color: "#F65555",
                  },
                },
              ]);
            }
            break;
          case "week":
            if (Number(data.present_this_week) == 0 && Number(data.absent_this_week) == 0) {
              setData([])
            } else if (Number(data.present_this_week) > 0 && Number(data.absent_this_week) == 0) {
              setData([{
                id: 0,
                value: data.present_this_week,
                name: t("common.Normal"),
                itemStyle: {
                  color: "#2BA9DE",
                },
              }])
            } else if (Number(data.present_this_week) == 0 && Number(data.absent_this_week) > 0) {
              setData([{
                id: 1,
                value: data.absent_this_week,
                name: t("common.Abnormal"),
                itemStyle: {
                  color: "#F65555",
                },
              }])
            } else {
              setData([
                {
                  id: 0,
                  value: data.present_this_week,
                  name: t("common.Normal"),
                  itemStyle: {
                    color: "#2BA9DE",
                  },
                },
                {
                  id: 1,
                  value: data.absent_this_week,
                  name: t("common.Abnormal"),
                  itemStyle: {
                    color: "#F65555",
                  },
                },
              ]);
            }
            break;
          case "month":
            if (Number(data.present_this_month) == 0 && Number(data.absent_this_month) == 0) {
              setData([])
            } else if (Number(data.present_this_month) > 0 && Number(data.absent_this_month) == 0) {
              setData([{
                id: 0,
                value: data.present_this_month,
                name: t("common.Normal"),
                itemStyle: {
                  color: "#2BA9DE",
                },
              }])
            } else if (Number(data.present_this_month) == 0 && Number(data.absent_this_month) > 0) {
              setData([{
                id: 1,
                value: data.absent_this_month,
                name: t("common.Abnormal"),
                itemStyle: {
                  color: "#F65555",
                },
              }])
            } else {
              setData([
                {
                  id: 0,
                  value: data.present_this_month,
                  name: t("common.Normal"),
                  itemStyle: {
                    color: "#2BA9DE",
                  },
                },
                {
                  id: 1,
                  value: data.absent_this_month,
                  name: t("common.Abnormal"),
                  itemStyle: {
                    color: "#F65555",
                  },
                },
              ]);
            }
            break;
        }
      })
      .finally(() => {
        if (chartInstance.current) {
          chartInstance.current?.resize();
        }
      });
  }, [props.refreshChart]);

  useEffect(() => {
    if (chartInstance.current == null) {
      chartInstance.current = echarts.init(chartRef.current);
    }

    const option = {
      tooltip: {
        trigger: "item",
        formatter: "{b0}: {c0}",
        // show: false,
      },
      legend: {
        orient: "vertical",
        left: "left",
        show: false,
      },
      series: [
        {
          name: props.title,
          type: "pie",
          radius: ["50%", "70%"],
          center: ["50%", "50%"],
          startAngle: 200, //起始角度
          avoidLabelOverlap: false,
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },

          itemStyle: {
            normal: {
              label: {
                show: true,
                formatter: "{d}%",
              },
            },
            labelLine: {
              show: true,
            },
          },

          label: {
            normal: {
              show: true,
              position: "outside",
            },
          },
          labelLine: {
            show: true,
            position: 'top',
            length: 10, // 引导线长度
            length2: 10, // 引导线接头长度
            smooth: 0.2, // 引导线平滑度
          },
        },
      ],
    };
    chartInstance.current.group = "echart";
    chartInstance.current.setOption(option);
    echarts.connect("echart");
  }, [data]);

  const resizeChart = () => {
    if (chartInstance.current) {
      chartInstance.current?.resize();
    }
  };

  useEffect(() => {
    // 监听窗口大小变化
    window.addEventListener("resize", resizeChart);
    // 组件卸载时移除事件监听
    return () => {
      window.removeEventListener("resize", resizeChart);
    };
  }, []);

  return (
    <Grid
      sx={{
        flexGrow: 1,
        height: "100%",
        width: "33%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        {props.title}
      </Grid>
      <div
        ref={chartRef}
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      ></div>
    </Grid>
  );
};

export default PieChart;
