import React, { ReactNode, useContext } from "react";
import {
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  SvgIconComponent,
  IosShare,
  Refresh,
  Download,
} from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";
import { useTranslation } from "react-i18next";
import { DataGridContext, DataGridDispatchContext } from "./DataGridContext";
import RefreshAction from "../actions/Refresh";
import FilterAction from "../actions/Filter";
import ActionButton from "../actions/ActionButton";

type ActionProps = {
  key: string;
  icon?: SvgIconComponent;
  iconFont?: string;
  helpTxt?: string;
  callback: () => Promise<void>;
};

type Breadcrumb = {
  helpInfo?: string;
  breadcrumbName: string;
  path: string;
};
type Info = {
  setDisplay?: any;
};

const placeholderStyle = {
  "input::-webkit-input-placeholder": { fontSize: "12px" },
};

export type ToolBarProps = {
  title: string;
  total?: number;

  info?: Info;
  refresh?: boolean;
  onRefresh?: () => () => void;

  search?: boolean;
  searchTips?: string;
  // searchPlaceholder?: string;
  // searchKeyword?: string;
  // onsearchKeywordChange?: (keyword: string,) => void;

  filter?: boolean;
  actions?: ActionProps[];
  breadcrumbs?: Breadcrumb[];
  export?: boolean;
  exportCallback?: () => void;
  extraComponent?: any;
  extraComponentOnLeft?: any;
  extraComponentOnRight?: any;
  children?: ReactNode;
};

const CustomToolBar: React.FC<ToolBarProps> = (props) => {
  let showTotal = false;
  const { t } = useTranslation();
  if (props.total) {
    showTotal = true;
  }
  const navigate = useNavigate();
  const dispatch = useContext(DataGridDispatchContext);
  const { searchKeyword } = useContext(DataGridContext);
  const handleRefresh = () => {
    dispatch({ type: "refreshed" });
  };

  const handleSearch = (searchKeyword: string) => {
    dispatch({ type: "searched", searchKeyword });
  };
  return (
    <>
      {props.breadcrumbs?.map((item, index) => {
        const last = index + 1 === props.breadcrumbs?.length;
        return (
          last && (
            <CustomBreadcrumbs
              key={index}
              record={[
                { label: item.breadcrumbName, helpInfo: item?.helpInfo },
              ]}
            />
          )
        );
      })}
      <Grid
        sx={{
          marginBottom: "5px",
          display: "flex",
          justifyContent: " space-between",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {props.search && (
            <Grid item={true}>
              <Grid
                container={true}
                // columnSpacing={1}
                sx={{
                  alignItems: "flex",
                }}
              >
                <Box sx={{ minWidth: "300px" }}>
                  <TextField
                    size={"small"}
                    fullWidth
                    placeholder={props.searchTips ? props.searchTips : ""}
                    type="search"
                    sx={placeholderStyle}
                    value={searchKeyword}
                    onChange={(event) => {
                      handleSearch(event.target.value);
                      handleRefresh();
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            sx={{ cursor: "pointer" }}
                            onClick={(e) => handleRefresh()}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {props.extraComponent}
              </Grid>
            </Grid>
          )}

          {props.extraComponentOnLeft && (
            <Grid sx={{ marginRight: "8px" }} item={true}>
              {props.extraComponentOnLeft}
            </Grid>
          )}
          {props.children}
        </Grid>

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {props.actions?.map((action, index) => {
            return (
              <Grid sx={{ marginRight: "8px" }} key={action.key} item={true}>
                {action?.iconFont ? (
                  <ActionButton
                    handleClick={action.callback}
                    icon={action?.iconFont}
                    title={action?.helpTxt}
                  ></ActionButton>
                ) : (
                  <Tooltip title={action.helpTxt || ""}>
                    <IconButton
                      onClick={action.callback}
                      sx={{
                        borderRadius: "6px",
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      }}
                    >
                      {action?.icon && <action.icon />}
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            );
          })}
          {props.info && (
            <Grid sx={{ marginRight: "8px" }} item={true}>
              <Tooltip title={t("common.Usage Tips")}>
                <IconButton
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  }}
                  onClick={() => {
                    props.info?.setDisplay(true);
                  }}
                >
                  <QuestionMarkIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {props.filter && (
            <Grid item={true}>
              {typeof props.filter === "boolean" ? (
                <FilterAction />
              ) : (
                props.filter
              )}
            </Grid>
          )}
          {props.extraComponentOnRight && (
            <Grid sx={{ marginRight: "8px" }} item={true}>
              {props.extraComponentOnRight}
            </Grid>
          )}

          {props.refresh && (
            <Grid sx={{ marginRight: "8px" }} item={true}>
              {typeof props.refresh === "boolean" ? (
                <RefreshAction />
              ) : (
                props.refresh
              )}
            </Grid>
          )}
          {props.export && (
            <Grid sx={{ marginRight: "8px" }} item={true}>
              <Tooltip title={t("export.Export")}>
                <IconButton
                  onClick={props.exportCallback}
                  sx={{
                    borderRadius: "6px",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  }}
                >
                  <IosShare />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};
CustomToolBar.defaultProps = {
  search: true,
  refresh: true,
  filter: false,
};
export default CustomToolBar;
