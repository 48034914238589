import SiteService from "@/services/location/SiteService"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


const initialState = {
  sites: [],
}

export const getOrganSites = createAsyncThunk('site/byOrgan', async () => {
  const {data} = await SiteService.list({current: 1, pageSize: 100});
  return data;
})

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganSites.fulfilled, (state, action) => {
      return {
        sites: action.payload.data
      }
    })
  }
});

export default siteSlice.reducer;
