import React from "react";
import {Avatar, Box, Grid} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import CustomDataGrid from "../../../../components/data-grid/CustomDataGrid";
import {ATTEndpoints} from "../../../../services/att/endpoints";
import WebURL from "../../../../urls";
import { useTranslation } from "react-i18next";


const FirstAndLast: React.FC = () => {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    {
      field: 'employee_name', headerName: t("common.Person Name"), minWidth: 220, flex: 0.3, renderCell: (params) => {
        return (
          <Grid style={{display: "flex", alignItems: "center"}}>
            <Avatar src={"https://joeschmoe.io/api/v1/random"} style={{width: '32px', height: '32px'}}/>
            <Box style={{padding: '0 8px'}}>{params.row.employee_name}</Box>
          </Grid>
        )
      }
    },
    {field: 'employee_code', headerName: t("common.Person Id"), type: "string", minWidth: 120, flex: 0.2},
    {field: 'att_date', headerName: t("common.Date"), type: "string", minWidth: 200, flex: 0.2},
    {field: 'first_punch', headerName: t("common.Clock In"), type: "string", minWidth: 180, flex: 0.2},
    {field: 'last_punch', headerName: t("common.Clock Out"), type: "string", minWidth: 180, flex: 0.2},
    {field: 'total', headerName: t("common.Total"), type: "string", minWidth: 180, flex: 0.2},
  ]
  return (
    <CustomDataGrid
      uri={ATTEndpoints.ATT_REPORT_FIRST_LAST}
      columns={columns}
      checkboxSelection={false}
      getRowId={(row) => {
        return Math.random()
      }}
      toolbar={{
        title: t("common.First And Last"),
        breadcrumbs: [
          {breadcrumbName: t("common.Report"), path: WebURL.ATT_REPORT_HOME},
          {breadcrumbName: t("common.First And Last"), path: WebURL.ATT_REPORT_FIRST_LAST},
        ]
      }}
    />
  )
}
export default FirstAndLast;
