// third-party
import { merge } from 'lodash';

// project import
import OutlinedInput from './OutlinedInput';
import MuiAutocomplete from './MuiAutocomplete';
import MuiSelect from './MuiSelect';

export default function componentsOverride(theme:any) {
  return merge(
    {
      MuiAppBar: {
        styleOverrides: {
          root: {
            height: '60px',
            background: '#f7f6fd',
            color: '#304669',
            boxShadow: '0px 0px 0px 0px'
          }
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            '&:hover': {
              backgroundColor: "white",
            },
            '&.Mui-focused': {
              backgroundColor: "white",
            }
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            padding: 7,
            height: 36,
            "&.Mui-checked": {
              "& $thumb": {
                "backgroundColor": "#fff"
              },
              "& + $track": {
                "opacity": "1 !important"
              }
            },
          },
          thumb: {
            width: 20,
            height: 20,
            backgroundColor: '#fff',
            boxShadow: '0 0 12px 0 rgba(0,0,0,0.08), 0 0 8px 0 rgba(0,0,0,0.12), 0 0 4px 0 rgba(0,0,0,0.38)'
          },
          switchBase: {
            color: 'rgba(0,0,0,0.38)',
            paddingTop: 8,
            paddingBottom: 8
          },
          track: {
            borderRadius: 20,
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            marginLeft: '4px',
            marginRight: '4px',
          }
        }
      }
    },
    OutlinedInput(theme),
    MuiAutocomplete(theme),
    MuiSelect(theme)
  );
}
