import React from "react";
import {Box, Breadcrumbs, Grid, Typography} from "@mui/material";
import {AccessTimeFilled} from "@mui/icons-material";
import SubMenuItem from "../../../components/menu/SubMenuItem";
import WebURL from "../../../urls";
import { useTranslation } from "react-i18next";

const AttRuleHome: React.FC = () => {
  const { t } = useTranslation();
  const items = [
    {name: "clockingrule", title: t("common.Clocking Collection Rule"), icon: AccessTimeFilled, path: WebURL.ATT_CLOCKING_COLLECTION_RULE},
    {name: "timesheet", title: t("common.TimeSheet"), icon: AccessTimeFilled, path: WebURL.ATT_TIMESHEET},
  ]
  return (
    <Box>
      <Grid  className={"toolbar-container"}>
        <Breadcrumbs sx={{height: "32px", p: '8px'}}>
          <Typography>{t("common.Attendance Rule Setting")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid>
        <Grid container={true} rowSpacing={2} sx={{margin: "0"}}>
          {
            items.map((item) => <SubMenuItem  key={item.name} {...item} />)
          }
        </Grid>
      </Grid>
    </Box>
  )
}
export default AttRuleHome
