import { Box, Button, Grid, Breadcrumbs, Typography, TextField, Paper } from "@mui/material";
import React from "react";
import { ReactComponent as DefaultOrganization } from '@/assets/images/DefaultOrganization.svg';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import CustomBreadcrumbs from "@/components/breadcrumbs/CustomBreadcrumbs";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
  borderRadius: '5px',
  boxShadow: 'none',
}));


const AboutView: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{backgroundColor: '#ffffff', width: 'calc(100% - 24px)', height: 'calc(100% - 90px)', paddingTop: '78px', boxSizing: 'border-box', position: 'relative', borderRadius: '6px'}}>
      <Box sx={{ display: 'flex' }}>
        <CustomBreadcrumbs record={[{label: t("common.About")}]}/>
      </Box>
      <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', }}>
        <Box>
          <img src={"/images/logo-zkbiobiotime-ngteco.png"} />
        </Box>
        <Box>
          <Typography sx={{ color: '#aeafb0' }}>Ver 2.0.0 (build 32)</Typography>
        </Box>
      </Grid>
      <Grid>
        <Box
          sx={{
            p: 2,
            display: 'grid',
            gridTemplateColumns: { md: '1fr' },
            padding: "8px 0",
            marginTop: '70px'
          }}
        >
          <Item
            sx={{
              display: 'flex',
              alignItems: "center",
              position: "relative",
              borderTop: '1px solid #e9e9e9',
              borderRadius: 0
            }}
            onClick={async () => {
              navigate("/user/about/user-agreement/");
            }}
          >
            <Grid>
              <Typography sx={{ paddingLeft: '20px' }}>{t("common.User Agreement")}</Typography>
            </Grid>
            <Grid sx={{ position: "absolute", right: "10px" }}>
              <Typography>
                <ArrowForwardIosIcon />
              </Typography>
            </Grid>
          </Item>
          <Item
            sx={{
              display: 'flex',
              alignItems: "center",
              position: "relative",
              borderTop: '1px solid #e9e9e9',
              borderRadius: 0
            }}
            onClick={async () => {
              navigate("/user/about/privacy-policy/");
            }}
          >
            <Grid>
              <Typography sx={{ paddingLeft: '20px' }}>{t("common.Privacy Policy")}</Typography>
            </Grid>
            <Grid sx={{ position: "absolute", right: "10px" }}>
              <Typography>
                <ArrowForwardIosIcon />
              </Typography>
            </Grid>
          </Item>
          <Item
            sx={{
              display: 'flex',
              alignItems: "center",
              position: "relative",
              borderTop: '1px solid #e9e9e9',
              borderRadius: 0
            }}
            onClick={async () => {
              navigate("/user/about/info-collection-form/");
            }}
          >
            <Grid>
              <Typography sx={{ paddingLeft: '20px' }}>{t("common.Info Collection Form")}</Typography>
            </Grid>
            <Grid sx={{ position: "absolute", right: "10px" }}>
              <Typography>
                <ArrowForwardIosIcon />
              </Typography>
            </Grid>
          </Item>
          <Item
            sx={{
              display: 'flex',
              alignItems: "center",
              position: "relative",
              borderTop: '1px solid #e9e9e9',
              borderBottom: '1px solid #e9e9e9',
              borderRadius: 0
            }}
            onClick={async () => {
              navigate("/user/about/compliance/");
            }}
          >
            <Grid>
              <Typography sx={{ paddingLeft: '20px' }}>{t("common.Compliance")}</Typography>
            </Grid>
            <Grid sx={{ position: "absolute", right: "10px" }}>
              <Typography>
                <ArrowForwardIosIcon />
              </Typography>
            </Grid>
          </Item>
        </Box>
      </Grid>
      <Grid sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'absolute', bottom: '24px', boxSizing: 'border-box' }}>
        <Box>
          <Typography sx={{ color: '#aeafb0' }}>Copyright © 2022 NGTECO CO., LTD. All rights reserved</Typography>
        </Box>
      </Grid>
    </Box>
  )
}

export default AboutView;
