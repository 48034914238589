import * as React from "react";
import { Grid } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
type BackGoProps = {
  backUrl?: string;
  title?: string;
};

const BackGo: React.FC<BackGoProps> = (props: any) => {
  const navigate = useNavigate();
  const backCall = () => {
    if (props.backUrl) {
      navigate(props.backUrl);
    } else {
      navigate(-1);
    }
  };

  return (
    <Grid
      sx={{
        height: "52px",
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-start',
        borderRadius:'6px'
      }}
    >
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          cursor:'pointer'
        }}
        onClick={backCall}>
        <ChevronLeftIcon
          sx={{
            fontSize: "14px",
            color: "#666666",
          }}
        ></ChevronLeftIcon>
        <Grid
          sx={{
            fontSize: "14px",
            color: "#666666",
            marginLeft: "3px",
          }}
        >
          {props.title ? props.title : "Back"}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BackGo;
