import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import UserService from "../../services/auth/UserService";
import CompanyService from "../../services/auth/CompanyService";

const getUserInfo = async () => {
  try {
    const {data} = await UserService.userInfo();
    return data
  } catch (error) {
    return undefined
  }
}

const getCompanyInfo = async () => {
  try {
    const {data} = await CompanyService.companyInfo();
    return data
  } catch (error) {
    return undefined
  }
}

export const obtainUserInfo = createAsyncThunk("app/obtainUserInfo", getUserInfo)

export const obtainCompanyInfo = createAsyncThunk("app/obtainCompanyInfo", getCompanyInfo)

/**
 * Initial App Global State
 */
export const getInitialState = createAsyncThunk("app/getInitialState", async () => {
  const user = await getUserInfo();
  const company = await getCompanyInfo();
  return {
    user,
    company
  }
})


export const appSlice = createSlice({
  name: "app",
  initialState: {
    user: undefined,
    company: undefined
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(obtainUserInfo.fulfilled, (state, action) => {
      return {
        ...state,
        user: action.payload
      }
    })
    builder.addCase(obtainCompanyInfo.fulfilled, (state, action) => {
      return {
        ...state,
        company: action.payload
      }
    })
    builder.addCase(getInitialState.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    })
  }
})
export default appSlice.reducer
