import { createTheme } from "@mui/material";

import 'typeface-roboto';
import componentsOverride from './theme/index'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    grey: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    grey?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    grey: true;
  }
}


const mainColor = "#2BA9DE";
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: mainColor,
      light: mainColor,
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    grey: {
      color: '#aeafb0',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    }
  },
});

theme.components = componentsOverride(theme);

export default theme;
