import React, { useRef } from "react";
import {GridColDef, GridColTypeDef} from "@mui/x-data-grid";
import {Box, Grid, Stack} from "@mui/material";
import {LocationEndpoints} from "@/services/location/endpoints";
import {Add} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import WebURL from '@/urls';
import {useNavigate} from "react-router-dom";
import CustomRowButton from '@/components/data-grid/CustomRowButton';
import CustomDataGrid, { DataGridRef } from '@/components/data-grid/CustomDataGrid';
import Dialog from "@/components/dialog";
import GenericService from "@/services/GenericService";
import SnackbarUtil from '@/utils/SnackbarUtil';
import {ngteco_timezones} from "@/components/resource/timezone";
import DeviceService from "@/services/dms/DeviceService";
import { useTranslation } from "react-i18next";

const ZoneDataGrid: React.FC = () => {
  const {t} = useTranslation();
  const timezoneList = ngteco_timezones();
  const navigate = useNavigate();
  const dataGridRef = useRef<DataGridRef>();
  const columns: GridColDef[] = [
    {field: 'name', headerName: t("common.Zone Name"), type: "string", minWidth: 120, flex: 0.2},
    {field: 'site_name', headerName: t("common.Site Name"), type: "string", minWidth: 120, flex: 0.2},
    {field: 'timezone', headerName: t("common.Site Time Zone"), type: "string", minWidth: 280, flex: 0.5, valueGetter:
      (params) => timezoneList.find(option => option.value === params.row.timezone)?.name||`-`},
    {
      field: 'actions', headerName: t("common.Action"), headerAlign: "center", align: "center", flex: 0.4,
        renderCell: (cell) => {
          return (
            <Stack direction={"row"}>
              <CustomRowButton insideIcon={EditIcon} buttonProps={{
                key: "edit",
                onClick: () => { navigate(WebURL.LOCATION_ZONE_EDIT, {state: {id: cell.row.id}}) }
              }}/>
              <CustomRowButton insideIcon={DeleteForeverIcon} buttonProps={{
                key: "delete",
                onClick: async () => {
                  Dialog.confirm({
                    title: t("location.zone.Delete Zone?"),
                    content: t("location.zone.Delete Confirm"),
                    onConfirm: () => {
                      DeviceService.list({current: 1, pageSize: 1, zoneId: cell.id}).then(({data}) => {
                        if (data.total === 0) {
                          GenericService.destroy(LocationEndpoints.ZONE_URI, `${cell.id}`).then(
                            (result) => {
                              console.log("delete====", result)
                              SnackbarUtil.success(t('common.success'))
                              // navigate("/location/zone")
                              dataGridRef.current?.refresh();
                          });
                        } else {
                          SnackbarUtil.error(t("location.zone.alreadyBinding"), {anchorOrigin: {vertical: 'top', horizontal: 'center'}});
                        }
                      });
                    }
                  })
                }
              }}/>
            </Stack>
          )
        }
    },
  ]
  return (
    <CustomDataGrid
      uri={LocationEndpoints.ZONE_URI}
      columns={columns}
      ref={dataGridRef}
      checkboxSelection={false}
      toolbar={{
        title: t("common.Zone View"),
        searchTips: t("location.zone.Search by Zone Name"),
        filter: false,
        breadcrumbs: [
          {breadcrumbName: t("common.Zone"), path: WebURL.HR_EMPLOYEE}
        ],
        actions: [{
          key: 'add', icon: Add, helpTxt: t("common.add"), callback: async () => {
            navigate(WebURL.LOCATION_ZONE_ADD);
          }
        }]
      }}

    />
  )
}
export default ZoneDataGrid
