import request from "../../utils/Request";
import {AuthEndpoints} from "./endpoints";
import jwtDecode from "jwt-decode";

const companyList = async (params: Record<string, any>) => {
  const response = await request.authorizedAxios().get(AuthEndpoints.AUTH_USER_COMPANY, {params});
  return response?.data;
}

const selectCompany = async (data: Record<string, any>) => {
  const response = await request.authorizedAxios().put(AuthEndpoints.AUTH_USER_SELECT_COMPANY, data);
  return response?.data;
}

const defaultCompany = async (params: Record<string, any>) => {
  const response = await request.authorizedAxios().get(AuthEndpoints.AUTH_USER_DEFAULT_COMPANY, {params});
  return response?.data;
}


const companyInfo = async () => {
  const jwtToken = localStorage.getItem("accessToken");
  if(jwtToken){
    const decodedToken = jwtDecode<Record<string, any>>(jwtToken);
    if (!decodedToken.companyId) {
      return
    }
  }else{
    return
  }

  const response = await request.authorizedAxios().get(AuthEndpoints.AUTH_USER_COMPANY_INFO_URI);
  return response?.data;
}

const companyCreation = async (data: Record<string, any>) => {
  const response = await request.authorizedAxiosForm().post(AuthEndpoints.AUTH_USER_COMPANY_CREATION, data);
  return response?.data;
}

const update = async ( data: Record<string, any>) => {
  const response = await request.authorizedAxiosForm().put(AuthEndpoints.AUTH_USER_COMPANY_UPDATE, data);
  return response?.data;
}

const destroy = async (id: string) => {
  const response = await request.authorizedAxios().delete(`${AuthEndpoints.AUTH_USER_COMPANY}${id}/`);
  return response?.data;
}

const CompanyService = {
  companyList,
  selectCompany,
  companyInfo,
  companyCreation,
  update,
  destroy,
  defaultCompany,
}
export default CompanyService;
