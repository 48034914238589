import axios from "axios";
import WebURL from "../urls";
import moment from 'moment-timezone'

import responseCodeAlert from './codes'


const onAxiosResponseSuccess = (response: any) => {
  return response
}
const onAxiosResponseFailed = (error: any) => {
  if(error.response?.status === 401){
    responseCodeAlert(error.response?.data);
    window.location.href = WebURL.AUTH_USER_LOGIN;
  } else if (400 <= error.response?.status) {
    console.log("reuquest error:", error);
    responseCodeAlert(error.response?.data);
    return Promise.reject(error);
  }
}
const onAxiosRequest = (config: any) => {
  if (process.env.REACT_APP_API_HOST) {
    config.url = `${process.env.REACT_APP_API_HOST}${config.url}`;
  } else {
    config.url = `/web${config.url}`;
  }

  return config;
}
const axiosWrapper = (headers: Record<string, any>, onSuccess?: (error: any) => any, onFailed?: (error: any) => any) => {
  const req = axios.create({
    headers
  })
  req.interceptors.request.use(onAxiosRequest)
  req.interceptors.response?.use(onSuccess, onFailed)
  return req;
}

const authorizedAxiosForm = (onFailed = onAxiosResponseFailed, onSuccess = onAxiosResponseSuccess) => {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (timezone === undefined) {
    timezone = moment.tz.guess()
  }
  return axiosWrapper({
    'Content-type': 'multipart/form-data',
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    Timezone: timezone,
    Accessor: "Web"
  },
  onSuccess,
  onFailed,
  )
}

const authorizedAxios = (onFailed = onAxiosResponseFailed, onSuccess = onAxiosResponseSuccess) => {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (timezone === undefined) {
    timezone = moment.tz.guess()
  }
  return axiosWrapper({
    'Content-type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    Timezone: timezone,
    Accessor: "Web"
  },
  onSuccess,
  onFailed,
  )
}

const unauthorizedAxios = (onFailed = onAxiosResponseFailed, onSuccess = onAxiosResponseSuccess) => {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if (timezone === undefined) {
    timezone = moment.tz.guess()
  }
  return axiosWrapper({
    'Content-type': 'application/json',
    Timezone: timezone,
    Accessor: "Web"
  },
  onSuccess,
  onFailed,
  )
}

const request = {
  axiosWrapper,
  authorizedAxios,
  authorizedAxiosForm,
  unauthorizedAxios,
}
export default request
