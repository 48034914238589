import React, {forwardRef, Fragment, useImperativeHandle, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Sync, Remove } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import EmployeeService from "@/services/hr/EmployeeService";
import { useSnackbar } from "notistack";
import { CancelButton, SaveButton } from "@/components/data-grid/CustomButton";
import SnackbarUtil from "@/utils/SnackbarUtil";
import DeviceService from "@/services/dms/DeviceService";
import ActionButton from "@/components/actions/ActionButton";
const dialogStyle = {
  "& .MuiPaper-root": { width: "400px", top: "1vh", overflowX: "hidden" },
  ".MuiDialog-paper": { maxWidth: "100vw" },
  overflowX: "hidden",
};

export function SyncAction({ setInfo }: any) {
  const { t } = useTranslation();

  return (
    <ActionButton
      title={t("common.Sync Employee")}
      iconC={<Sync sx={{
        fontSize: '18px !important',
        color:'#75828A !important '
      }} />}
      handleClick={() => {
        setInfo(true);
      }}
    ></ActionButton>
  );
}

const EmpSync = (props: any, ref: any) => {
  const [info, setInfo] = useState<boolean>(false);
  const {t} = useTranslation();
  const [empIds, setEmpIds] = useState<any>([])
  const [deviceId, setDeviceIds] = useState<any>([])
  useImperativeHandle(ref, () => ({
    showDelete: async (object: any) => {
      setEmpIds(object?.ids)
      setDeviceIds(object?.device_id)
      setInfo(false)
    }
  }));
  const onSubmit = async (event: any) => {
    if (empIds.length == 0) {
      SnackbarUtil.error(t("att.schedule.Select person first"), {
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      return;
    }
    try {
      DeviceService.addPerson(deviceId.toString() || "", empIds).then(
        (result) => {
          SnackbarUtil.success(
            t("dms.People synced to the device successfully", {
              No: empIds.length,
            })
          );
        }
      );
      setTimeout(function () {
        if (props.reloadTable) {
          props.reloadTable()
        }
      }, 1500)
    } catch (e) {
      console.log("error===", e);
    } finally {
      setInfo(false);
    }
  };

  return (
    <Fragment>
      <Grid item={true}>
        <SyncAction setInfo={setInfo}/>
      </Grid>
      <Dialog
        open={info}
        // onClose={()=>setInfo(false)}
        sx={dialogStyle}
      >
        <DialogTitle>
          {t("common.Sync Employee to Device")}
          <IconButton
            aria-label="close"
            onClick={() => setInfo(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <Typography sx={{textAlign: "center"}}>
          {t("common.Are you sure to Sync Employee to Device")}
        </Typography>
        <Grid style={{padding: "20px"}}>
          <Stack spacing={3} direction={"row"} justifyContent="flex-end">
            <CancelButton
              onClick={() => {
                setInfo(false);
              }}
            />
            <SaveButton onClick={onSubmit} children={t("common.confirm")}/>
          </Stack>
        </Grid>
      </Dialog>
    </Fragment>
  );
};
export default forwardRef(EmpSync);
