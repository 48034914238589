import React from "react";
import {Box} from "@mui/material";


const LogoView: React.FC = () => {
  return (
    <Box style={{margin: '40px 48px 15px 48px'}}>
      <img src={'/images/logo-zkbiobiotime-ngteco.png'} alt={""} style={{width: '180px', height: '85px'}}/>
    </Box>
  )
}
export default LogoView
